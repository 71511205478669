import { SxProps } from '@mui/material';

export const handleScrollButtonClick = (
  element: HTMLElement | null,
  scrollType: 'left' | 'right',
  tabType?: 'vertical' | 'horizontal',
) => {
  if (element) {
    const scrollerContainer =
      element.getElementsByClassName('MuiTabs-scroller')[0];

    const tabWrapper = scrollerContainer.getElementsByClassName(
      'MuiTabs-flexContainer',
    )[0];

    if (tabWrapper) {
      scrollerContainer.classList.add('mainSnapScrollClass');
      tabWrapper.classList.add('snapScrollClass');
      const tab = tabWrapper.getElementsByClassName('MuiTab-root')[0];
      let tabWidth = tab.getBoundingClientRect().width + 5; //* +5 gap between two tab
      if (tabType === 'vertical') {
        tabWidth = tab.getBoundingClientRect().height + 5;
      }
      if (scrollType === 'right') {
        scrollerContainer.scrollTo({
          left: scrollerContainer.scrollLeft + tabWidth,
          behavior: 'smooth',
        });
      } else {
        scrollerContainer.scrollTo({
          left: scrollerContainer.scrollLeft - tabWidth,
          behavior: 'smooth',
        });
      }
    }
  } else {
    throw new Error('Element not found.');
  }
};

export const commonTabsSX = {
  '&.MuiTabs-root': {
    minHeight: 'auto',
    height: '24px',
    padding: '3px',
    paddingTop: 0,
    marginRight: '0px',
    display: 'inline-flex',
  },
  '& .MuiTabs-flexContainer': {
    gap: 5,
  },
  '& .MuiTabs-indicator': {
    display: 'none',
  },
  '& .MuiTabScrollButton-root': {
    opacity: 1,
    width: '18px',
    height: 21,
  },
  '& .MuiTabs-scrollButtons.Mui-disabled': {
    opacity: 0.25,
  },
};
