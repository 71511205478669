import Masterlayout from '../../layout/masterlayout/Masterlayout';
import { Outlet } from 'react-router';
import userRouterHandler from '../../router/userRouterHandler';
import { ProtectedRouter } from '../../router/components';

const Users = () => {
  return (
    <ProtectedRouter>
      <Masterlayout navigatorList={userRouterHandler}>
        <Outlet />
      </Masterlayout>
    </ProtectedRouter>
  );
};

export default Users;
