import { User } from '../../../../assets/svgs';
import images from '../../../../assets/images';
import {
  cleanAllLocalStorage,
  getLocalStorageItem,
  removeLocalStorageItem,
} from '../../../../libs/utils';
import { useNavigate } from 'react-router-dom';
import { Box, Typography, alpha, styled } from '@mui/material';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import clsx from 'clsx';

import CosSwitch from '../../../../components/formikForms/CosSwitch';
import { useEffect, useRef, useState } from 'react';
import AdminProfileLanguageBox from './AdminProfileLanguageBox';
import { localStorageKey } from '../../../../libs/constant';
import settings from '../../../../assets/svgs/staticColorSvg/normalSettingImg.svg';
import logout from '../../../../assets/svgs/staticColorSvg/normalLogoutImg.svg';
import useAutoClose from '../../../../libs/hooks/useAutoClose';
import { CosPopUpState } from '../../../../components/popover';
import { bindMenu, bindTrigger } from 'material-ui-popup-state';

const profileWidth = 176;
const profilePopUpHeight = 115;

const StyledMenuItem = styled(MenuItem)({
  padding: 0,
  color: '#001F38',
  fontSize: 12,
  lineHeight: '17px',
  eight: '17px',
  width: '100%',
  gap: '5px',
  alignItems: 'center',
  minHeight: 'unset',
  '&:hover, &.Mui-focusVisible': {
    backgroundColor: 'transparent',
    color: '#07B9EC',
    fill: {
      color: '#07B9EC',
    },
  },
  '&.MuiButton-root.Mui-disabled': {},
});

const styleMenuSwitchLabelSx = {
  '&:hover, &.Mui-focusVisible': {
    color: alpha('#001F38', 0.75),
    '& .Mui-checked': {
      '.MuiSwitch-thumb': {
        backgroundColor: '#ffffff',
        opacity: 0.75,
      },
    },
    '& .MuiSwitch-switchBase:not(.Mui-checked)': {
      '.MuiSwitch-thumb': {
        backgroundColor: '#001F38',
      },
    },
  },
};

const styleMenuSx = {
  '& .MuiPaper-root': {
    borderRadius: '6px',
    borderTopLeftRadius: 0,
    borderTopRightRadius: 0,
    width: profileWidth,
    maxWidth: profileWidth,
    height: profilePopUpHeight,
    boxShadow: '1px 2px 7px #001F3840',
    background: '#EDEEEF',
    marginLeft: '8.4px',
    marginTop: '-1px',

    '& .MuiMenu-list': {
      padding: '5px 10px 4px 10px',
      display: 'flex',
      flexDirection: 'column',
      gap: '5px',
    },
    '& .MuiMenuItem-root': {
      '& .MuiSvgIcon-root': {},
      '&:active': {},
    },
  },
};

const AdminProfile = () => {
  const navigate = useNavigate();
  const [isLanguageOpen, setIsLanguageOpen] = useState(false);
  const [languageValue, setLanguageValue] = useState('English');

  const timerId: any = useRef<any>(null);
  const { startTimer, stopTimer } = useAutoClose(timerId);

  // TODO: Manage userData type
  const userData: any = getLocalStorageItem(localStorageKey.USER_DATA);
  const userName = !!userData
    ? userData.firstName + ' ' + userData.lastName
    : '%Full Name%';
  const handleLanguage = (event: React.SyntheticEvent, language: string) => {
    event.stopPropagation();
    setIsLanguageOpen(preValue => !preValue);
    setLanguageValue(language);
  };

  const handleLogout = (callback: Function) => {
    callback();
    removeLocalStorageItem(localStorageKey.USER_DATA);
    cleanAllLocalStorage();
    setTimeout(() => {
      navigate('/login');
    }, 500);
  };

  const handleClose = (callback: Function) => {
    callback();
    setIsLanguageOpen(false);
  };

  return (
    <div>
      <div>
        <CosPopUpState variant="popover" popupId="admin-profile-popup">
          {popupState => {
            const { onClose, ...restBindMenu } = bindMenu(popupState);
            const { onClick, ...restBindTrigger } = bindTrigger(popupState);
            return (
              <>
                <Box
                  component="section"
                  sx={{
                    width: profileWidth,
                  }}
                  className={clsx(
                    'flex h-[47px] cursor-pointer items-center rounded-[5px] bg-white-whale-500 px-[5px] py-[3px]',
                    { 'rounded-b-none': popupState.isOpen },
                  )}
                  {...restBindTrigger}
                  onClick={event => {
                    onClick(event);
                    startTimer(onClose);
                  }}
                >
                  <User className="min-h-[31px] min-w-[31px]" />
                  <div className="flex flex-grow items-center justify-between">
                    <div className="flex-grow pl-[5px]">
                      <Typography className="-mt-[3px] mb-[2px] text-12/17 text-dark-blue-900">
                        {userName}
                      </Typography>
                      <Typography className="text-10/10 text-artist-blue-900">
                        {userData?.companyName || '%Company Name%'}
                      </Typography>
                    </div>
                    <div className="mt-1 flex flex-col items-center justify-center">
                      <div className="flex size-6 items-center justify-center rounded-full border border-solid border-error-900">
                        <span className="text-10/22 text-error-900">0%</span>
                      </div>
                      <img
                        src={images.downArrow}
                        width={'10px'}
                        className={clsx(
                          'base-animation zoom-icon cursor-pointer transition-transform',
                          {
                            'rotate-180': popupState.isOpen,
                          },
                        )}
                      />
                    </div>
                  </div>
                </Box>
                <Menu
                  {...restBindMenu}
                  onClose={() => {
                    handleClose(onClose);
                  }}
                  sx={styleMenuSx}
                  slotProps={{
                    paper: {
                      onMouseLeave: (event: any) => {
                        startTimer(onClose);
                      },
                      onMouseEnter: () => stopTimer(),
                    },
                  }}
                  disablePortal
                  disableAutoFocusItem
                >
                  <StyledMenuItem disabled sx={styleMenuSwitchLabelSx}>
                    <span className="flex-grow">Admin Mode</span>
                    <CosSwitch switchType="popup" defaultChecked />
                  </StyledMenuItem>
                  <StyledMenuItem sx={styleMenuSwitchLabelSx}>
                    <span className="flex-grow">Tool Tip Hovers</span>
                    <CosSwitch switchType="popup" defaultChecked />
                  </StyledMenuItem>
                  <StyledMenuItem
                    onClick={() => setIsLanguageOpen(preValue => !preValue)}
                    style={
                      isLanguageOpen
                        ? {
                            color: '#07B9EC',
                          }
                        : {}
                    }
                    className={clsx('language_icon__hover_withing', {
                      language_icon__active: isLanguageOpen,
                    })}
                  >
                    <AdminProfileLanguageBox
                      onChange={handleLanguage}
                      open={isLanguageOpen}
                      value={languageValue}
                    />
                  </StyledMenuItem>
                  <StyledMenuItem
                    onClick={popupState.close}
                    className="setting_icon__hover_withing"
                  >
                    <img src={settings} className="setting_icon size-3" />
                    <span>Settings</span>
                  </StyledMenuItem>
                  <StyledMenuItem
                    onClick={() => handleLogout(popupState.close)}
                    className="logout_icon__hover_withing hover:text-error-900"
                    sx={{
                      '&.Mui-focusVisible': {
                        color: '#EB5050 !important',
                      },
                    }}
                  >
                    <img src={logout} className="logout_icon size-3" />
                    <span>Logout</span>
                  </StyledMenuItem>
                </Menu>
              </>
            );
          }}
        </CosPopUpState>
      </div>
    </div>
  );
};
export default AdminProfile;
