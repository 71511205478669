import { debounce } from 'lodash';
import { MutableRefObject, useEffect, useMemo, useRef } from 'react';

const useAutoClose = (
  timerRef: MutableRefObject<any>,
  timer: number = 3000,
) => {
  const startTimer = (callback: Function) => {
    timerRef.current = setTimeout(() => {
      callback();
    }, timer);
  };

  const stopTimer = () => {
    clearTimeout(timerRef.current);
  };

  useEffect(() => {
    return () => {
      if (timerRef) {
        clearTimeout(timerRef.current);
      }
    };
  }, [timerRef]);

  return {
    startTimer,
    stopTimer,
  };
};

export default useAutoClose;
