import { RouteObject } from 'react-router';
import {
  Authentication,
  ContactPage,
  EmailVerificationPage,
  LoginPage,
  SignUpPage,
} from '../pages';
import { isQaEnv, isStandardEnv } from '../libs/utils';
import AuthRouter from './components/AuthRouter';

const authRouterHandler: RouteObject = {
  children: [
    !isStandardEnv()
      ? {
          path: '/login',
          element: <LoginPage />,
        }
      : {},
    ...(!isQaEnv() && !isStandardEnv()
      ? [
          {
            path: '/register',
            element: (
              <AuthRouter>
                <Authentication />
              </AuthRouter>
            ),
          },
          {
            path: '/signup',
            element: (
              <AuthRouter>
                <SignUpPage />
              </AuthRouter>
            ),
          },
          {
            path: '/confirmation',
            element: (
              <AuthRouter>
                <EmailVerificationPage />
              </AuthRouter>
            ),
          },
          {
            path: '/contactus',
            element: (
              <AuthRouter>
                <ContactPage />
              </AuthRouter>
            ),
          },
        ]
      : [{}]),
  ],
};

export default authRouterHandler;
