import React, { useState, useEffect } from 'react';
import { alpha, styled } from '@mui/material/styles';
import Checkbox, { CheckboxProps } from '@mui/material/Checkbox';
import CosLabel, { CosLabelProps } from './CosLabel';
import clsx from 'clsx';
import { CosTheme } from '../../../libs/interface';

const size = 13;
type HEX = `#${string}`;

const BpIcon = styled('span', {
  shouldForwardProp: prop =>
    prop !== 'isChecked' &&
    prop !== 'isFocused' &&
    prop !== 'isValid' &&
    prop !== 'version' &&
    prop !== 'withGrayBg',
})<{
  isFocused?: boolean;
  isValid?: boolean; // Add isFocused prop here
  version?: CosTheme;
  withGrayBg?: boolean;
}>(({ isFocused, version, withGrayBg }) => {
  const color = version === 'light' ? '#07B9EC' : '#001F38';
  return {
    borderRadius: 3,
    width: size,
    height: size,
    border: '0.5px solid',
    borderColor:
      version === 'light'
        ? withGrayBg
          ? alpha('#001F38', 0.5)
          : alpha('#07B9EC', 0.5)
        : alpha('#ffffff', 0.5),
    'input:disabled ~ &': {
      boxShadow: 'none',
      opacity: '0.25',
    },
    '@media (min-width: 768px)': {
      'input:hover ~ &': {
        backgroundColor: alpha(color, 1),
        borderColor: alpha(color, 0.5),
      },
    },
    ...(isFocused && {
      backgroundColor: alpha(color, 1),
      borderColor: alpha(color, 0.5),
    }),
  };
});

const BpCheckedIcon = styled(BpIcon, {
  shouldForwardProp: prop =>
    prop !== 'isFocused' && prop !== 'version' && prop !== 'checkedColor',
})<{
  isChecked?: boolean;
  version?: CosTheme;
  checkedColor?: string;
}>(({ isFocused, version, checkedColor }) => {
  const color = version === 'light' ? '#07B9EC' : '#001F38';
  return {
    backgroundColor: color,
    border: `0px solid ${color}`,

    '&::before': {
      display: 'block',
      width: size,
      height: size,
      backgroundImage: `url(\"data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' width='11.556' height='8.667' viewBox='0 0 11.556 8.667'%3E%3Cpath id='checkedtick' d='M0,69.145,3.957,73.11l7.6-7.591-1.091-1.076-6.508,6.5L1.076,68.062Z' transform='translate(0 -64.443)' fill='${checkedColor}'/%3E%3C/svg%3E\")`,
      backgroundPosition: 'center',
      backgroundRepeat: 'no-Repeat',
      content: '""',
    },
    'input:hover ~ &': {
      backgroundColor: alpha(color, 0.75),
    },
    '@media (min-width: 768px)': {
      'input:hover ~ &': {
        backgroundColor: alpha(color, 0.75),
      },
    },
    ...(isFocused && {
      backgroundColor: alpha(color, 0.75),
    }),
  };
});

export interface CosCheckboxProps extends Omit<CheckboxProps, 'size'> {
  isValid?: boolean;
  label?: React.ReactNode | string;
  isRequired?: boolean;
  labelProps?: Omit<CosLabelProps, 'children'>;
  error?: boolean;
  version?: CosTheme;
  withGrayBg?: boolean;
  checkedColor?: HEX;
}

const CosCheckbox = (props: CosCheckboxProps) => {
  const {
    isValid,
    label,
    error,
    className,
    isRequired,
    sx,
    labelProps,
    version = 'light',
    withGrayBg,
    checkedColor = '#ffffff',
    ...checkboxProps
  } = props;

  const handleKeyDown: React.KeyboardEventHandler<
    HTMLButtonElement
  > = event => {
    if (event.key === ' ') {
      // * Stop all space-bar keyboard interaction
      event.preventDefault();
      event.stopPropagation();
    }
    if (event.key === 'Enter') {
      event.preventDefault();
      event.stopPropagation();
      const target = event.target as HTMLInputElement;
      target.click();
    }
  };

  const [isFocused, setIsFocused] = useState(false);

  // TODO:  change data type
  const handleKeyCapture = (event: any) => {
    if (event.key === 'Tab') {
      setIsFocused(true);
    }
  };

  // TODO:  change data type
  const handleDownCapture = (event: any) => {
    setIsFocused(false);
  };

  return (
    <div className="flex items-center">
      <Checkbox
        sx={{
          '&:hover': { bgcolor: 'transparent' },
          ...sx,
        }}
        disableRipple
        className={clsx('p-0', className)}
        checkedIcon={
          <BpCheckedIcon
            isFocused={isFocused}
            version={version}
            className="COS-checked-box"
            checkedColor={checkedColor.replace('#', '%23')}
          />
        }
        onKeyUpCapture={event => handleKeyCapture(event)}
        onBlurCapture={event => handleDownCapture(event)}
        // onMouseEnter={event => setIsFocused(true)}
        // onMouseLeave={event => setIsFocused(false)}
        icon={
          <BpIcon
            isFocused={isFocused}
            isValid={isValid}
            version={version}
            withGrayBg={withGrayBg}
            className="COS-Unchecked-box"
          />
        }
        onKeyDown={handleKeyDown} // Handle Enter key press
        {...checkboxProps}
      />
      {label && (
        <CosLabel
          isRequired={isRequired}
          {...labelProps}
          className={clsx('ml-[5px]', labelProps?.className)}
          htmlFor={checkboxProps?.id}
        >
          {label}
        </CosLabel>
      )}
    </div>
  );
};

export default CosCheckbox;
