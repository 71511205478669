import React, { useEffect, useRef, useState } from 'react';
import { TImeFieldWrapper } from './TImeFieldWrapper';
import ScheduleInput from './ScheduleInput';
import {
  convert12hrTo24hr,
  moveTabToNextField,
  moveTabToPrevField,
} from '../../libs/helper';
import MeridiemInput, { MeridiemType } from './MeridiemInput';
import { KEYBOARD_KEY } from '../../libs/constant';
import { Clock } from '../../assets/svgs';
import CosClockPicker, { TimeRecord } from './picker/CosClockPicker';
import { cosPadStart, time12hRegex, time24hRegex } from '../../libs/utils';
import { set12hrFormate } from './helper';

interface TimeInputChangeRecord extends TimeRecord {
  is24HrFormate: boolean;
  hr_24: string;
  min_24: string;
  value_24: string;
}

interface Props {
  onChange?: ({ value, hr, min, meridiem }: TimeInputChangeRecord) => void;
  defaultValue?: string;
  is24HrFormate?: boolean;
  className?: string;
}

const CosTimeInput: React.FC<Props> = ({
  onChange,
  is24HrFormate,
  defaultValue,
  className,
}) => {
  if (defaultValue && !is24HrFormate && !time12hRegex(defaultValue)) {
    throw new Error(
      'Invalid 12h time format. Time should be in HH:MM AM/PM format.',
    );
  }

  const [hours, setHours] = useState('');
  const [mins, setMins] = useState('');
  const [showClockPicker, setClockPicker] = useState(false);
  const [meridiem, setMeridiem] = useState<MeridiemType>('');
  const ref = useRef<HTMLDivElement>(null);

  const handleMinKeyDown = (event: any) => {
    const key = event.key;
    const isFirst = event.target.getAttribute('id') === 'mins-0';

    switch (key) {
      case KEYBOARD_KEY.backspace:
        !event.target.value &&
          isFirst &&
          setTimeout(() => {
            moveTabToPrevField();
          });
        break;

      default:
        break;
    }
  };

  const handelChange = (data: string, type: 'hr' | 'min') => {
    if (type === 'hr') {
      const setHour = [1, 2, 3, 4, 5];

      if (meridiem == '' && parseInt(data) != 0) {
        if (setHour.includes(parseInt(data))) {
          setMeridiem('PM');
        } else {
          setMeridiem('AM');
        }
      }
      onChange?.({
        value: `${cosPadStart(data)}:${cosPadStart(mins)} ${meridiem}`,
        hr: data,
        min: mins,
        meridiem: meridiem,
        is24HrFormate: !!is24HrFormate,
        hr_24: convert12hrTo24hr(set12hrFormate(hours, mins, meridiem)).hours,
        min_24: convert12hrTo24hr(set12hrFormate(hours, mins, meridiem)).min,
        value_24: `${convert12hrTo24hr(set12hrFormate(hours, mins, meridiem)).hours}:${convert12hrTo24hr(set12hrFormate(hours, mins, meridiem)).min}`,
      });
    } else {
      onChange?.({
        value: `${cosPadStart(hours)}:${cosPadStart(data)} ${meridiem}`,
        hr: data,
        min: mins,
        meridiem: meridiem,
        is24HrFormate: !!is24HrFormate,
        hr_24: convert12hrTo24hr(set12hrFormate(hours, mins, meridiem)).hours,
        min_24: convert12hrTo24hr(set12hrFormate(hours, mins, meridiem)).min,
        value_24: `${convert12hrTo24hr(set12hrFormate(hours, mins, meridiem)).hours}:${convert12hrTo24hr(set12hrFormate(hours, mins, meridiem)).min}`,
      });
    }
  };

  const toggleClockPicker = () => setClockPicker(() => !showClockPicker);
  const closeClockPicker = () => {
    setClockPicker(false);
  };

  //* Split default into hours, min and meridiem
  useEffect(() => {
    const splitter: string[] | undefined = defaultValue?.split(' ');
    if (splitter?.length) {
      const [time, meridiem]: string[] = splitter;
      const [hours, mins] = time.split(':');
      setMeridiem(meridiem as MeridiemType);
      setHours(hours);
      setMins(mins);
    }
  }, [defaultValue]);

  const handleHourChange = (hour: string) => {
    setHours(hour);
    handelChange(hour, 'hr');
  };

  const handleMinChange = (min: string) => {
    setMins(min);
    handelChange(min, 'min');
  };

  return (
    <>
      <TImeFieldWrapper ref={ref} className={className}>
        <ScheduleInput
          max={is24HrFormate ? 23 : 12}
          min={is24HrFormate ? 0 : 1}
          id="hours"
          value={
            is24HrFormate && hours.length === 2
              ? convert12hrTo24hr(set12hrFormate(hours, mins, meridiem)).hours
              : hours
          }
          onChange={handleHourChange}
          onComplete={() => moveTabToNextField(ref.current as HTMLElement)}
        />
        <span className="w-3 text-center font-semibold">:</span>
        <ScheduleInput
          max={59}
          id="mins"
          value={mins}
          onChange={handleMinChange}
          onComplete={() =>
            !is24HrFormate && moveTabToNextField(ref.current as HTMLElement)
          }
          onEmpty={() => moveTabToPrevField(ref.current as HTMLElement)}
          onKeyDown={handleMinKeyDown}
        />

        {!is24HrFormate && (
          <MeridiemInput
            meridiem={meridiem}
            setMeridiem={setMeridiem}
            onFocus={() => {
              meridiem === '' && hours.length === 2 && setMeridiem('AM');
            }}
            onChange={meridiem => {
              setMeridiem(meridiem);
              onChange?.({
                value: `${cosPadStart(hours)}:${cosPadStart(mins)} ${meridiem}`,
                hr: hours,
                min: mins,
                meridiem: meridiem,
                is24HrFormate: !!is24HrFormate,
                hr_24: convert12hrTo24hr(set12hrFormate(hours, mins, meridiem))
                  .hours,
                min_24: convert12hrTo24hr(set12hrFormate(hours, mins, meridiem))
                  .min,
                value_24: `${convert12hrTo24hr(set12hrFormate(hours, mins, meridiem)).hours}:${convert12hrTo24hr(set12hrFormate(hours, mins, meridiem)).min}`,
              });
            }}
          />
        )}

        <span className="ml-[13px] inline-flex" onClick={toggleClockPicker}>
          <Clock className="cursor-pointer hover:text-buddha-love-800" />
        </span>

        {showClockPicker && (
          <div
            className={
              className
                ? 'absolute left-0 top-0 z-10'
                : 'absolute left-0 top-0 z-10 mt-[36px]'
            }
          >
            <CosClockPicker
              onClose={closeClockPicker}
              defaultValue={set12hrFormate(
                hours || '12',
                mins || '00',
                meridiem || 'AM',
              )}
              onApply={({ value, hr, min, meridiem }) => {
                setHours(hr);
                setMins(min);
                setMeridiem(meridiem);
                onChange?.({
                  value,
                  hr,
                  min,
                  meridiem,
                  is24HrFormate: !!is24HrFormate,
                  hr_24: convert12hrTo24hr(
                    set12hrFormate(hours, mins, meridiem),
                  ).hours,
                  min_24: convert12hrTo24hr(
                    set12hrFormate(hours, mins, meridiem),
                  ).min,
                  value_24: `${convert12hrTo24hr(set12hrFormate(hours, mins, meridiem)).hours}:${convert12hrTo24hr(set12hrFormate(hours, mins, meridiem)).min}`,
                });
              }}
            />
          </div>
        )}
      </TImeFieldWrapper>
    </>
  );
};

export default CosTimeInput;
