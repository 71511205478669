import React, { memo, useRef, useState } from 'react';
import AddButton from '../../../components/buttons/AddButton';
import { Typography } from '@mui/material';
import { CosCountBadge } from '../../../components';
import { FieldArray, useFormikContext } from 'formik';
import FieldWithLabel from '../components/FieldWithLabel';
import { FormInput } from '../../../components/formikForms';
import AddNewEmail from './AddNewEmail';

const commonSx = {
  minWidth: 'auto',
};

interface Props {
  name: string;
  isViewMode: boolean;
  isActive: boolean;
}

const EmailSection: React.FC<Props> = ({ name, isViewMode, isActive }) => {
  const [addNewEmail, setAddNewEmail] = useState(false);
  const wrapperRef = useRef<HTMLDivElement>(null);

  const valueClass = 'text-14/15 ';
  const { dirty, values, errors }: any = useFormikContext();
  const value = values[name];
  const isValid = !(dirty && !errors[name]);

  return (
    <>
      <FieldArray name={name}>
        {({ remove, push }) => (
          <>
            <Typography
              component="h6"
              className="mb-profile-8px flex items-center gap-[5px] text-16/22 font-semibold"
            >
              {value.length !== 1 ? 'Emails' : 'Email'}
              {isActive && <CosCountBadge count={value.length} />}
            </Typography>
            <section
              className="max-h-[53px] min-h-[53px] overflow-auto px-[1px]"
              ref={wrapperRef}
            >
              {isViewMode ? (
                <section className="mr-[4px] flex max-h-[53px] min-h-[53px] flex-wrap gap-x-[30px] gap-y-[14px] ">
                  {value.length > 0 &&
                    value.map((email: any, index: number) => (
                      <div
                        className="flex w-fit gap-[5px] pb-[1px]"
                        key={`${index}-email`}
                      >
                        <FieldWithLabel
                          label={email.emailType + ' Email'}
                          required={index > 0 ? false : true}
                        >
                          <Typography className={`${valueClass}`}>
                            {email.email}
                          </Typography>
                        </FieldWithLabel>
                      </div>
                    ))}
                </section>
              ) : (
                <section className="mr-[4px] flex flex-wrap gap-x-[15px] gap-y-[14px]">
                  {value.length > 0 &&
                    value.map((email: any, index: number) => (
                      <div
                        className="flex w-fit gap-[5px] pb-[1px]"
                        key={`${index}-email`}
                      >
                        <FieldWithLabel
                          label={email['emailType'] + ' Email'}
                          required={index > 0 ? false : true}
                          onRemove={index > 0 ? () => remove(index) : false}
                        >
                          <FormInput
                            name={`${name}.${index}.email`}
                            size="small"
                            className="!mb-0 w-[239px]"
                            inputSx={{ ...commonSx, padding: '0px 8px' }}
                            fullWidth
                            placeholder="Enter Primary Email"
                          />
                        </FieldWithLabel>
                      </div>
                    ))}
                </section>
              )}
            </section>

            <AddNewEmail
              open={addNewEmail}
              onClose={() => setAddNewEmail(false)}
              onApply={value => {
                value.map(item => push(item));
                setTimeout(() => {
                  wrapperRef?.current?.scrollTo({
                    top: wrapperRef.current.scrollHeight,
                    behavior: 'smooth',
                  });
                });
              }}
            />
          </>
        )}
      </FieldArray>
      {isViewMode ? (
        <div className="mt-[2px] flex max-h-[23px] min-h-[23px] items-center">
          <p className="m-0 w-full border-0 border-b-[1px] border-solid border-blue-gray-200/[0.38] " />
        </div>
      ) : (
        <AddButton
          onClick={() => setAddNewEmail(true)}
          disable={isValid}
          toolTipText={'Add Email'}
          className="mt-[2px]"
        />
      )}
    </>
  );
};

export default memo(EmailSection);
