import { styled } from '@mui/material';
import { constant } from '../../../../libs/constant';

export const AppBarLeftSideBarHeader = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  height: constant.appBarHeight + 'px',
  minWidth: constant.drawerWidth + 'px',
  width: constant.drawerWidth + 'px',

  textTransform: 'uppercase',
  // necessary for content to be below app bar
  ...theme.mixins.toolbar,
}));

export default AppBarLeftSideBarHeader;
