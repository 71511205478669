import React from 'react';
import CosTooltip from './CosTooltip';
import { Eye, EyeHide, HideEye, HideEyeHover } from '../assets/svgs';
import { cn } from '../libs/utils';
import SvgWrapper from './SvgWrapper';

interface Props {
  isHide: boolean;
  className?: string;
  toggle?: () => void;
}

const Wrapper = ({ children }: any) => (
  <SvgWrapper
    // sx={value ? { color: '#001F38' } : { color: '#B4BBC3' }}
    sx={{ color: '#001F38' }}
    viewBox="0 0 19.859 11.81"
    className="cursor-pointer"
    style={{ marginBottom: '-6px' }}
  >
    {children}
  </SvgWrapper>
);
const HideShowComponent: React.FC<Props> = ({ isHide, className, toggle }) => {
  return (
    <div className={cn('inline-flex items-center', className)} onClick={toggle}>
      {isHide ? (
        <div>
          <CosTooltip
            title={'Hide'}
            placement="top"
            onHoverIcon={<HideEye className="text-dark-blue-900" />}
          >
            <Eye className="text-dark-blue-900" />
          </CosTooltip>
        </div>
      ) : (
        <div className="mt-[2px]">
          <CosTooltip
            title={'Show'}
            placement="top"
            onHoverIcon={<HideEyeHover className="text-dark-blue-900" />}
            yOffset={2}
          >
            <EyeHide className="text-dark-blue-900" />
          </CosTooltip>
        </div>
      )}
    </div>
  );
};

export default HideShowComponent;
