import UploadLogoForm from './UploadLogoForm';
import { Formik } from 'formik';
import { Form } from 'react-router-dom';
import FieldWithLabel from '../components/FieldWithLabel';
import { actionLabelProps } from './util';
import {
  brandingSInitialValue,
  brandingSchema,
} from '../formConfig/profileForm';
import { FormRadio } from '../../../components/formikForms';

const BrandingForm = () => {
  return (
    <Formik
      initialValues={brandingSInitialValue}
      validationSchema={brandingSchema}
      onSubmit={value => console.log('value', value)}
    >
      {({ values, errors, ...rest }) => {
        return (
          <Form>
            <div className="flex gap-5">
              {/* // TODO : use formik for */}
              <UploadLogoForm />

              <div className="flex flex-grow justify-between">
                <FieldWithLabel
                  label="Does your company have a Vision statement?"
                  required={false}
                >
                  <div className="flex gap-[10px]" role="radiogroup">
                    <FormRadio
                      name="vision"
                      value="yes"
                      label="Yes"
                      labelProps={actionLabelProps}
                      tabIndex={0}
                      id="vision-yes"
                    />
                    <FormRadio
                      name="vision"
                      value="no"
                      label="No"
                      labelProps={actionLabelProps}
                      tabIndex={0}
                      id="vision-no"
                    />
                  </div>
                </FieldWithLabel>

                <FieldWithLabel
                  label="Does your company have a Mission statement?"
                  required={false}
                >
                  <div className="flex gap-[10px]" role="radiogroup">
                    <FormRadio
                      name="mission"
                      value="yes"
                      label="Yes"
                      labelProps={actionLabelProps}
                      tabIndex={0}
                      id="mission-yes"
                    />
                    <FormRadio
                      name="mission"
                      value="no"
                      label="No"
                      labelProps={actionLabelProps}
                      tabIndex={0}
                      id="mission-no"
                    />
                  </div>
                </FieldWithLabel>

                <FieldWithLabel
                  label="Does your company have Core Values?"
                  required={false}
                >
                  <div className="flex gap-[10px]" role="radiogroup">
                    <FormRadio
                      name="coreValue"
                      value="yes"
                      label="Yes"
                      labelProps={actionLabelProps}
                      tabIndex={0}
                      id="coreValue-yes"
                    />
                    <FormRadio
                      name="coreValue"
                      value="no"
                      label="No"
                      labelProps={actionLabelProps}
                      tabIndex={0}
                      id="coreValue-no"
                    />
                  </div>
                </FieldWithLabel>
              </div>
            </div>
          </Form>
        );
      }}
    </Formik>
  );
};

export default BrandingForm;
