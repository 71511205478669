import React, { useEffect } from 'react';
import { MuiOtpInput } from 'mui-one-time-password-input';
import { styled } from '@mui/material';
import { CosButton } from '../../../components';
import useOTPVerification from './hooks/useOTPVerification';
import { FormikValues } from 'formik';
import { CosLabel } from '../../../components/formikForms/ui';

interface Props {
  formValue: FormikValues;
}

const BaseOTP = styled(MuiOtpInput)({
  '&': {
    justifyContent: 'center !important',
  },
  '& .MuiInputBase-root': {
    '&::hover': {
      outline: 'none !important',
    },
    background: '#ffffff',
    borderRadius: 5,
    boxShadow: '0px 0px 3px #A8B1B8',
  },
  '& .MuiInputBase-input': {
    padding: 7,
    paddingTop: 9,
    boxSizing: 'border-box',
    minHeight: '43px',
    minWidth: '46px',
    maxHeight: '43px',
    maxWidth: '46px',
    fontFamily: 'controller',
    fontWeight: '600',
    letterSpacing: '-0.43px',
    color: '#001F38',
    fontSize: '24px',
    lineHeight: '29px',
  },
  '& fieldset': {
    textAlign: 'left',
    position: 'absolute',
    bottom: 0,
    right: 0,
    top: '-5px',
    left: 0,
    margin: 0,
    padding: '0 8px',
    pointerEvents: 'none',
    borderRadius: 'inherit',
    overflow: 'hidden',
    minWidth: '0%',
    border: 'unset',
  },
});

const OTPVerificationForm: React.FC<Props> = ({ formValue }) => {
  const {
    otp,
    handleChange,
    isLoading,
    handleCompleted,
    isValid,
    handleSubmit,
    error,
    handleResendCode,
    btnRef,
  } = useOTPVerification();

  return (
    <section className="relative">
      <CosLabel
        isRequired={false}
        className="absolute -top-6 left-1/2 m-0 h-[21px] -translate-x-1/2 text-16/20 font-semibold text-error-900"
      >
        {error ? error : ''}
      </CosLabel>

      <p className="m-0 mb-7 text-center text-20/27 -tracking-0.43 text-dark-blue-900">
        We sent a one-time 6 digit code to your email address to <br />
        authenticate your email address.
      </p>
      <BaseOTP
        autoFocus
        value={otp}
        length={6}
        gap={'10px'}
        onChange={handleChange}
        onComplete={handleCompleted}
      />
      <div className="mt-[7px] h-[27px] text-center">
        <span
          className="link-text cursor-pointer text-[20px]/[normal]  text-buddha-love-800"
          role="button"
          tabIndex={0}
          onClick={() => handleResendCode(formValue.email)}
        >
          Resend Code
        </span>
      </div>
      <p className="my-8 text-center text-base/[normal] text-blue-gray-100">
        I did not get a code, what should I do? <br />
        Please check your spam folder for the email, if you have not received
        the email contact our support team.
      </p>
      <div className="m-0 mt-3 h-[27px] text-center">
        <span
          className="link-text cursor-pointer text-20/27 -tracking-0.36 text-buddha-love-800"
          tabIndex={0}
          role="button"
        >
          Get Support
        </span>
      </div>
      <div className="text-center">
        <CosButton
          className="mb-[50px] mt-[50px] sm:mb-[0px]"
          isLoading={isLoading}
          disabled={!isValid}
          onClick={() => handleSubmit(formValue)}
          sx={{
            margin: 'auto',
          }}
          size="large"
          color="secondary"
          ref={btnRef}
        >
          Confirm
        </CosButton>
      </div>
    </section>
  );
};

export default OTPVerificationForm;
