export const handleMouseLeave = () => {
  const listItems = document.querySelectorAll('.MuiAutocomplete-option');
  listItems.forEach(item => {
    if (item.getAttribute('aria-selected') === 'true') {
      item.classList.add('color-animation');
      item.classList.add('Mui-focused');
    } else {
      item.classList.remove('color-animation');
      item.classList.remove('Mui-focused');
    }
  });
};

export const handleMouseEnter = () => {
  const listItems = document.querySelectorAll('.MuiAutocomplete-option');
  listItems.forEach(item => {
    item.classList.remove('color-animation');
    item.classList.remove('Mui-focused');
  });
};

export const manageHighlightItem = (node: HTMLElement) => {
  // const allOptions = node.querySelector('.MuiAutocomplete-listbox');
  const allOptions = node.querySelectorAll('.MuiAutocomplete-option');
  if (allOptions) {
    allOptions.forEach((item, index) => {
      // TODO : manage select all , close , apply keyboard
      // const activeItem: any = item.querySelector('.Mui-focusVisible');

      // if (activeItem) {
      //   const activeItemDataIndex = activeItem?.dataset['option-index'] || -1;
      //   console.log('activeItemDataIndex', activeItemDataIndex);
      //   // if (+activeItemDataIndex === listItems.length - 1) {
      //   //   console.log('tem');
      //   // }
      // }

      // * remove all Mui-focused and focus only one
      if (item.classList.contains('Mui-focusVisible')) {
      } else {
        item.classList.remove('color-animation');
        item.classList.remove('Mui-focused');
      }
    });
  }
};
