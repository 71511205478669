import React, { useRef, useState } from 'react';
import { Box, Tab, Tabs, alpha, styled, TabScrollButton } from '@mui/material';
import { handleScrollButtonClick, commonTabsSX } from './tab.util';
import useTabScroll from '../../libs/hooks/useTabScroll';
import { ExpandArrow } from '../../assets/svgs';
import { CosTooltip } from '../../components';
import clsx from 'clsx';

const BpTab = styled(Tabs)(({}) => ({
  minWidth: '620px',
  maxWidth: '100%',
  width: 'calc(100vh - 132px)',
  height: '36px !important',
  transform: 'rotate(90deg) translateY(50%)',
  transformOrigin: 'left',
  backgroundColor: '#EDEEEF',
  position: 'absolute',
  paddingRight: '25px !important',
  top: '-18px',
  ...commonTabsSX,
  '& .snapScrollClass': {
    gap: '5px',
    scrollSnapType: 'x mandatory !important',
  },
  '& .mainSnapScrollClass': {
    display: 'flex',
    flexDirection: 'row',
    scrollSnapType: 'x mandatory',
    scrollBehavior: 'smooth',
    scrollSnapAlign: 'start',
  },
  '& .MuiTabs-scrollButtons': {
    color: '#07B9EC',
  },
  '& .MuiTab-root': {
    flexGrow: '1',
    scrollSnapAlign: 'start',
    height: '23px',
    minHeight: '21px',
    minWidth: '92px',
    backgroundColor: '#001F38',
    border: `1px solid ${alpha('#07B9EC', 0.25)}`,
    color: alpha('#FFFFFF', 0.7),
    borderRadius: '0px 0px 5px 5px',
    textAlign: 'center',
    fontSize: '12px',
    lineHeight: '21px',
    textTransform: 'capitalize',
    padding: 0,

    '&:hover': {
      border: 0,
      backgroundColor: '#03688e',
      color: alpha('#07B9EC', 0.7),
    },
  },
  '& .MuiTab-root[aria-selected="true"]': {
    border: 0,
    backgroundColor: '#07B9EC',
    color: alpha('#FFFFFF', 1),
    fontWeight: 600,
  },
}));

const ExpandHideShow = styled(Box)(({}) => ({
  '& .expand-hideshow': {
    boxShadow: 'inset 0px 2px 5px #A8B1B880, 0px 1px 3px #A8B1B880',
    transition: 'all 0.3s ease',
  },
  '&:hover': {
    '& .expand-hideshow': {
      backgroundColor: '#07B9EC !important',
      width: '35px !important',
    },
    '& svg': {
      color: '#FFFFFF !important',
    },
  },
}));

const CosVerticalTab: React.FC<CosTabs> = ({ tabs, ...rest }) => {
  const [value, setValue] = useState(0);
  const [isTabOpen, setIsTabOpen] = useState(true);

  const handleChange = (event: any, newValue: number) => {
    setValue(newValue);
  };
  const HideshowToggle = () => {
    setIsTabOpen(!isTabOpen);
  };

  const tabsRef = useRef<HTMLDivElement | null>(null);
  const tabType = 'vertical';
  useTabScroll(tabsRef, tabType);
  return (
    <Box>
      {isTabOpen && (
        <BpTab
          value={value}
          onChange={handleChange}
          variant="scrollable"
          selectionFollowsFocus={true}
          {...rest}
          ScrollButtonComponent={({ ...rest }) => {
            return (
              <TabScrollButton
                {...rest}
                onClick={() =>
                  handleScrollButtonClick(
                    tabsRef.current,
                    rest.direction,
                    tabType,
                  )
                }
              />
            );
          }}
          ref={tabsRef}
        >
          {tabs.map((item: CosTab, index: number) => (
            <Tab
              label={item.label}
              key={`${index}-${item.label}`}
              className="snap-start"
              // value={item.value}
            />
          ))}
        </BpTab>
      )}
      <ExpandHideShow className="absolute bottom-0 right-0 w-[37px] cursor-pointer text-end">
        <CosTooltip title={isTabOpen ? 'Hide' : 'show'} placement="left">
          <span
            role="button"
            className={clsx(
              'expand-hideshow inline-flex h-[28px] w-[18px] cursor-pointer items-center justify-center rounded-l-[15px] bg-white-whale-500',
            )}
            onClick={HideshowToggle}
          >
            <ExpandArrow
              className={clsx(
                `rotate-270 cursor-pointer border-0 text-dark-blue-900 ${isTabOpen ? '-rotate-90' : 'rotate-90'}`,
              )}
            />
          </span>
        </CosTooltip>
      </ExpandHideShow>
    </Box>
  );
};

export default CosVerticalTab;
