import { TableBody, TableBodyProps } from '@mui/material';
import React from 'react';

interface Props extends TableBodyProps {}

const CosTableBody: React.FC<Props> = props => {
  return <TableBody {...props} />;
};

export default CosTableBody;
