import React, { useState, useEffect, useRef } from 'react';
import './clockPicker.css';
import { cn, cosPadStart } from '../../../libs/utils';
import { Box, BoxProps } from '@mui/material';
import { KEYBOARD_KEY } from '../../../libs/constant';

export type ViewType = 'hours' | 'min';

interface Props extends Omit<BoxProps, 'onChange'> {
  view?: ViewType;
  value?: string;
  autoFocus?: boolean;
  onChange?: (value: string) => void;
  active?: boolean;
}

const TimeClock: React.FC<Props> = props => {
  const { view = 'hours', onChange, value, autoFocus, active, ...rest } = props;
  const clockRef = useRef<HTMLDivElement>();

  const isHoursView = view === 'hours';
  const [selectedNumber, setSelectedNumber] = useState(
    isHoursView ? '12' : '00',
  );
  const hoursWheel = [
    '12',
    '01',
    '02',
    '03',
    '04',
    '05',
    '06',
    '07',
    '08',
    '09',
    '10',
    '11',
  ];
  const minWheel = Array.from({ length: 60 }, (_, i) => cosPadStart(i));
  const numbers = isHoursView ? hoursWheel : minWheel;

  const selectByClick = (value: string) => {
    setSelectedNumber(value);
    onChange?.(value);
  };

  const onKeyDown = (event: KeyboardEvent) => {
    const key = event.key;
    const currentIndex = numbers.indexOf(selectedNumber);
    const isLastItem = currentIndex + 1 === numbers.length;
    const isFirstItem = currentIndex === 0;
    switch (key) {
      case KEYBOARD_KEY.up:
        if (isLastItem) {
          const test = numbers[0];
          setSelectedNumber(() => test);
          onChange?.(cosPadStart(test));
        } else {
          const test = numbers[currentIndex + 1];
          setSelectedNumber(() => test);
          onChange?.(cosPadStart(test));
        }

        break;
      case KEYBOARD_KEY.down:
        if (isFirstItem) {
          setSelectedNumber(() => numbers[numbers.length - 1]);
          onChange?.(numbers[numbers.length - 1]);
        } else {
          setSelectedNumber(() => numbers[currentIndex - 1]);
          onChange?.(numbers[currentIndex - 1]);
        }
        break;
      default:
        break;
    }
  };

  useEffect(() => {
    value && setSelectedNumber(value);
  }, [value]);

  useEffect(() => {
    clockRef.current?.addEventListener('keydown', onKeyDown);
    return () => clockRef.current?.removeEventListener('keydown', onKeyDown);
  }, [clockRef, selectedNumber]);

  return (
    <Box
      className={cn('picker-dial', {
        'active-dial': active,
      })}
      tabIndex={0}
      ref={clockRef}
      {...rest}
    >
      {numbers.map((value, index) => {
        const style = {
          '--i': index,
        } as React.CSSProperties;
        const isActive = +selectedNumber === +value;
        return (
          <div
            key={`${index}`}
            className={cn('dial-sector', {
              'min-dial-sector': view === 'min',
              'hide-min-dial-sector': view === 'min' && index % 5 !== 0,
            })}
            style={style}
            onClick={() => selectByClick(cosPadStart(value))}
          >
            <span
              className={cn('sector-val', {
                'active-number': isActive,
                'min-active-number':
                  isActive && view === 'min' && index % 5 !== 0,
                'min-sector-val': view === 'min',
                invisible: view === 'min' && index % 5 !== 0,
              })}
            >
              {view === 'min' ? value : +value}
            </span>

            {isActive && (
              <div className="sector-line-wrapper">
                <div className="sector-line"></div>
              </div>
            )}
          </div>
        );
      })}
    </Box>
  );
};

export default TimeClock;
