import { RouteObject } from 'react-router';
import { Settings } from '../pages/settings';

const settingsRouterHandler: RouteObject = {
  path: '/settings',
  element: <Settings />,
  children: [
    {
      path: '',
      element: <></>,
    },
  ],
};

export default settingsRouterHandler;
