import { alpha } from '@mui/material';
import { handleMouseEnter, handleMouseLeave } from './util';

export const ListBoxProps = (isAddNew: boolean, multiple: boolean) => ({
  onMouseLeave: handleMouseLeave,
  onMouseEnter: handleMouseEnter,
  sx: {
    '&': {
      maxHeight: multiple
        ? 'calc(211px - 34px)'
        : isAddNew
          ? 'calc(139px - 34px)'
          : 'calc(139px - 12px)',
      overflow: 'auto',
      position: 'static',
      padding: 0,
      marginTop: isAddNew ? 0 : '6px',
      marginBottom: multiple ? 0 : '6px',
      marginRight: '2px',
    },
    '& .MuiAutocomplete-option': {
      padding: 0,
      paddingLeft: '10px',
      paddingRight: '11px',
      fontSize: '12px',
      lineHeight: '10px',
      textTransform: 'capitalize',
      color: '#001F38',
      minHeight: 17,
      width: '100%',
      '&:not(:last-child)': {
        marginBottom: '5px',
      },
      '&.Mui-focusVisible': {
        backgroundColor: 'transparent !important',
      },
    },

    '& .MuiAutocomplete-option.Mui-focused': {
      backgroundColor: 'transparent',
      color: '#07B9EC',

      //* for multiCheckBox
      '& .COS-Unchecked-box': {
        backgroundColor: alpha('#07B9EC', 1),
        borderColor: alpha('#07B9EC', 0.5),
      },
      '& .COS-checked-box': {
        backgroundColor: alpha('#07B9EC', 0.75),
        color: alpha('#07B9EC', 0.75),
      },
    },
    '& .MuiAutocomplete-option[aria-selected="true"].Mui-focused': {
      backgroundColor: multiple ? 'transparent' : '#EDEEEF',
      color: '#07B9EC',
    },
    '& .MuiAutocomplete-option[aria-selected="true"]': {
      backgroundColor: multiple ? 'transparent' : '#EDEEEF',
      color: '#001F38',
      minHeight: multiple ? 17 : 20,
      '&:not(:last-child)': {
        marginBottom: multiple ? '5px' : '2px',
      },
    },
  },
});
