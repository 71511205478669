/**
 * @roadBlock custom svg icon not rending properly (someTime edge cut unexpectedly)
 * @tempSolution render aa icons in this component one time and icons working properly
 * @todo find proper solution why scg icon not rending properly
 */
import React, { memo } from 'react';
import {
  LanguageIconBlue,
  Logout,
  NotificationIcon,
  Settings,
  MessageIcon,
} from '../../assets/svgs';

const TempIconShow = () => (
  <div
    style={{
      display: 'none',
    }}
  >
    <Settings />
    <Logout />
    <LanguageIconBlue />
    <NotificationIcon />
    <MessageIcon />
  </div>
);
export default memo(TempIconShow);
