import { CosHorizontalTab, CosPageContentTab } from '../components/Tabs/index';
import StandardLayout from './StandardLayout';

const threeTabs = [
  { label: 'All', value: 'All' },
  { label: 'Active', value: 'Active' },
  { label: 'Inactive', value: 'Inactive' },
];

const moreThanThreeTabs = [
  { label: 'All', value: 'All' },
  { label: 'Active', value: 'Active' },
  { label: 'Inactive', value: 'Inactive' },
  { label: 'Pending', value: 'Pending' },
  { label: 'On hold', value: 'On hold' },
  { label: 'Reopen', value: 'Reopen' },
];

const contextTab = [
  { label: 'Branding / Structure', value: 'Branding / Structure' },
  { label: 'Organization Chart', value: 'Organization Chart' },
  { label: 'Company Information', value: 'Company Information' },
  { label: 'Banking / Credit Cards', value: 'Banking / Credit Cards' },
  { label: 'Assign', value: 'Assign' },
  { label: 'Work Schedule', value: 'Work Schedule' },
];

const moreThanSixContentTab = [
  { label: 'Branding / Structure', value: 'Branding / Structure' },
  { label: 'Organization Chart', value: 'Organization Chart' },
  { label: 'Company Information', value: 'Company Information' },
  { label: 'Banking / Credit Cards', value: 'Banking / Credit Cards' },
  { label: 'Assign', value: 'Assign' },
  { label: 'Work Schedule', value: 'Work Schedule' },
  { label: 'Departments', value: 'Departments' },
  { label: 'Resource Types', value: 'Resource Types' },
  { label: 'Work Schedules', value: 'Work Schedules' },
  { label: 'Holidays', value: 'Holidays' },
  { label: 'Positions / Titles', value: 'Positions / Titles' },
  { label: 'Capacities', value: 'Capacities' },
  { label: 'Library Name', value: 'Library Name' },
];

const StandardHorizontalTab = () => (
  <StandardLayout>
    <div className="space-y-10">
      <h5 className="text-center">Horizontal Tabs</h5>
      <div className="flex gap-10">
        <div>
          <p>With 3 Tabs</p>
          <CosHorizontalTab tabs={threeTabs} />
        </div>
        <div>
          <p>With More than 3 Tabs</p>
          <CosHorizontalTab tabs={moreThanThreeTabs} />
        </div>
      </div>

      <h5 className="text-center">Page Content Tabs</h5>
      <div>
        <p>With 6 Tabs</p>
        <CosPageContentTab tabs={contextTab} />
      </div>
      <div>
        <p>With More than 6 Tabs</p>
        <CosPageContentTab tabs={moreThanSixContentTab} />
      </div>
    </div>
  </StandardLayout>
);
export default StandardHorizontalTab;
