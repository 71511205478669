import {
  FieldArray,
  Form,
  Formik,
  FormikProps,
  useFormikContext,
} from 'formik';
import React from 'react';
import {
  CosBasDialog,
  PrimaryActionButtons,
} from '../../../components/dialogBox';
import { CosButton } from '../../../components';
import { Divider } from '@mui/material';
import AddButton from '../../../components/buttons/AddButton';
import { FormAutoComplete, FormInput } from '../../../components/formikForms';
import {
  EmailData,
  emailInitialValues,
  emailSchema,
  EmailTypeItems,
} from '../formConfig/profileForm';
import * as Yup from 'yup';

export const addNewEmailsSchema = Yup.object().shape({
  emails: Yup.array().of(emailSchema).min(1).required(''),
});

export const addNewEmailInitialValues = {
  emails: [{ ...emailInitialValues }],
};

interface Props {
  open: boolean;
  onClose?: () => void;
  onApply?: (value: EmailData[]) => void;
}

const commonSx = {
  minWidth: 'auto',
};

const AddNewEmail: React.FC<Props> = ({ open, onClose, onApply }) => {
  const name = 'emails';
  const { errors }: any = useFormikContext();
  const handleSubmit = (values: any) => {
    onClose?.();
    onApply?.(values[name]);
  };

  const handleClose = (
    dirty: boolean,
    resetForm: FormikProps<any>['resetForm'],
  ) => {
    dirty ? handleCancel(resetForm) : onClose?.();
  };

  const handleCancel = (callback: Function) => {
    callback();
  };
  return open ? (
    <Formik
      initialValues={addNewEmailInitialValues}
      validationSchema={addNewEmailsSchema}
      onSubmit={handleSubmit}
    >
      {({ values, setFieldValue, dirty, isValid, resetForm }: any) => {
        return (
          <CosBasDialog
            open={open}
            sx={{
              '.MuiPaper-root': {
                background: '#EDEEEF',
              },
            }}
            onEsc={() => handleClose(dirty, resetForm)}
            onEnter={() => handleSubmit(values)}
            height="250px"
            width="365px"
            heading={'New Email'}
            headingClass={
              'text-left text-18/18 text-blue-gray-900 bg-white-whale-500 px-[15px] py-[10px]'
            }
            bodyClass={'bg-white-whale-500 p-0 m-0'}
            actionClass="flex h-[44px] border-0 border-t-[0.5px] border-solid border-artist-blue-900 p-0 bg-white overflow-x-hidden"
            dialogActions={
              <PrimaryActionButtons
                showCancel={dirty}
                onCancel={() => handleCancel(resetForm)}
                onClose={() => onClose?.()}
                disableApply={!(dirty && isValid)}
                onApply={() => handleSubmit(values)}
              />
            }
          >
            <Form>
              <FieldArray name={name}>
                {({ remove, push }) => (
                  <div className="w-full px-[15px] pt-[2px]">
                    {values[name].length > 0 &&
                      values[name].map((email: EmailData, index: number) => (
                        <div
                          key={`${index}-addNewEmailPopup`}
                          className="flex w-full flex-col gap-[5px] pt-[2px]"
                        >
                          <FormAutoComplete
                            name={`${name}.${index}.emailType`}
                            options={EmailTypeItems}
                            placeholder="Select Account Type"
                            size="small"
                            className="!mb-0 rounded-[3px] shadow-3b"
                            withElevation={false}
                            isPopupInput={true}
                            onChange={(event, value) => {
                              setFieldValue(
                                `${name}.${index}.emailType`,
                                value?.value,
                              );
                            }}
                            fullWidth
                          />
                          <FormInput
                            name={`${name}.${index}.email`}
                            placeholder={
                              values.emails[index].emailType
                                ? `Enter ${values.emails[index].emailType ? values.emails[index].emailType : ''} Email`
                                : 'Enter Email'
                            }
                            size="small"
                            className="!mb-0"
                            inputSx={{
                              ...commonSx,
                            }}
                            fullWidth
                            isPopupInput={true}
                            disabled={!values.emails[index].emailType}
                          />
                          {values[name].length !== index + 1 && (
                            <Divider className="my-[10px]" />
                          )}
                        </div>
                      ))}
                    <AddButton
                      onClick={() => push(emailInitialValues)}
                      className="mb-1 mt-[4px]"
                      toolTipText={'Add Email'}
                    />
                  </div>
                )}
              </FieldArray>
            </Form>
          </CosBasDialog>
        );
      }}
    </Formik>
  ) : null;
};

export default AddNewEmail;
