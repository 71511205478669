import React from 'react';
import { FocusTrapProps, FocusTrap as MUIFocusTrap } from '@mui/base';
import FocusTrap from 'focus-trap-react';

interface Props {
  children: React.ReactElement;
  as?: 'MUI' | 'FTReact';
  muiProps?: Omit<FocusTrapProps, 'children'>;
  ftReactProps?: FocusTrap.Props;
}

const CosFocusTrap: React.FC<Props> = ({
  children,
  muiProps,
  ftReactProps,
  as = 'MUI',
}) => {
  if (ftReactProps && as === 'MUI') {
    throw new Error(
      "Invalid Props! as='MUI' don't have ftReactProps you should use muiProps refer @mui/base FocusTrapProps",
    );
  }

  if (muiProps && as === 'FTReact') {
    throw new Error(
      "Invalid Props! as='FT_React', don't have muiProps you should use ftReactProps refer focus-trap-react FocusTrap.Props",
    );
  }

  return (
    <>
      {as == 'MUI' ? (
        <MUIFocusTrap open disableAutoFocus {...muiProps}>
          {children}
        </MUIFocusTrap>
      ) : (
        <FocusTrap
          {...ftReactProps}
          focusTrapOptions={{
            allowOutsideClick: true,
            ...ftReactProps?.focusTrapOptions,
          }}
        >
          {children}
        </FocusTrap>
      )}
    </>
  );
};

export default CosFocusTrap;
