import { CosVerticalTab } from '../components/Tabs/index';
import StandardLayout from './StandardLayout';
import { Box } from '@mui/material';

const contextTab = [
  { label: 'Task List', value: 'Task List' },
  { label: 'Project List', value: 'Project List' },
  { label: 'User List', value: 'User List' },
  { label: 'Customer List', value: 'Customer List' },
  { label: 'Assign1', value: 'Assign1' },
  { label: 'Assign2', value: 'Assign2' },
  { label: 'Assign3', value: 'Assign3' },
  { label: 'Assign4', value: 'Assign4' },
  { label: 'Assign5', value: 'Assign5' },
  { label: 'Assign6', value: 'Assign6' },
];

const StandardVerticalTab = () => {
  return (
    <StandardLayout>
      <Box className="relative flex h-full items-center justify-end">
        <Box className="">
          <div className="ml-16 flex flex-col">
            <CosVerticalTab tabs={contextTab} />
          </div>
        </Box>
      </Box>
    </StandardLayout>
  );
};
export default StandardVerticalTab;
