import React from 'react';
import { FieldArray, Form, Formik, FormikProps } from 'formik';
import { CosButton } from '../../../components';
import { Divider } from '@mui/material';
import {
  CosBasDialog,
  PrimaryActionButtons,
} from '../../../components/dialogBox';
import { FormInput, FormTextArea } from '../../../components/formikForms';
import {
  AddressData,
  addressInitialValues,
  addressSchema,
} from '../formConfig/profileForm';
import * as Yup from 'yup';
import AddButton from '../../../components/buttons/AddButton';
import { primaryActionClass } from '../../../components/dialogBox/PrimaryActionButtons';

export const addNewAddressesSchema = Yup.object().shape({
  addresses: Yup.array().of(addressSchema).min(1).required(''),
});

export const addNewAddressInitialValues = {
  addresses: [{ ...addressInitialValues }],
};

interface Props {
  open: boolean;
  onClose?: () => void;
  onApply?: (value: AddressData[]) => void;
}

const commanSx = {
  minWidth: 'auto',
};

const AddNewAddress: React.FC<Props> = ({ open, onClose, onApply }) => {
  const name = 'addresses';

  const handleSubmit = (values: any) => {
    onClose?.();
    onApply?.(values[name]);
  };

  const handleClose = (
    dirty: boolean,
    resetForm: FormikProps<any>['resetForm'],
  ) => {
    dirty ? handleCancel(resetForm) : onClose?.();
  };

  const handleCancel = (callback: Function) => {
    callback();
  };

  const handleZipCodeInput = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
    index: number,
    type: 'zipCode' | 'zipCodeExt',
    setFieldValue: Function,
  ) => {
    const { value } = event.target;
    const maxValue = type === 'zipCode' ? 5 : 4;
    if (value.length <= maxValue) {
      setFieldValue(`${name}.${index}.${type}`, value);
    } else {
      setFieldValue(`${name}.${index}.${type}`, value.slice(0, maxValue));
    }
  };

  return open ? (
    <Formik
      initialValues={addNewAddressInitialValues}
      validationSchema={addNewAddressesSchema}
      onSubmit={handleSubmit}
    >
      {({ values, setFieldValue, dirty, isValid, resetForm }: any) => {
        return (
          <CosBasDialog
            open={open}
            sx={{
              '.MuiPaper-root': {
                background: '#EDEEEF',
              },
            }}
            onEsc={() => handleClose(dirty, resetForm)}
            onEnter={() => handleSubmit(values)}
            height="357px"
            width="385px"
            heading="New Address"
            headingClass="text-left text-18/18 text-blue-gray-900 bg-white-whale-500 px-[15px] py-[10px]"
            bodyClass="bg-white-whale-500 p-0 m-0 scrollbar-gutter-stable"
            actionClass={primaryActionClass}
            dialogActions={
              <PrimaryActionButtons
                showCancel={dirty}
                onCancel={() => handleCancel(resetForm)}
                onClose={() => onClose?.()}
                disableApply={!(dirty && isValid)}
                onApply={() => handleSubmit(values)}
              />
            }
          >
            <Form>
              <FieldArray name={name}>
                {({ remove, push }) => (
                  <div className="w-full pl-[15px] pr-[11px] pt-[2px]">
                    {values[name].length > 0 &&
                      values[name].map(
                        (address: AddressData, index: number) => (
                          <div
                            key={`${index}-addNewAddressPopup`}
                            className="mb-[5px] flex w-full flex-col gap-[5px]"
                          >
                            <FormInput
                              name={`${name}.${index}.addressType`}
                              placeholder="Enter Address Name *"
                              size="small"
                              className="!mb-0 w-full"
                              inputSx={commanSx}
                              isPopupInput
                              fullWidth
                            />
                            <FormTextArea
                              name={`${name}.${index}.street1`}
                              placeholder="Enter Street (Line 1) *"
                              fullWidth
                              isPopupInput
                              className="!mb-0"
                            />
                            <FormTextArea
                              name={`${name}.${index}.street2`}
                              placeholder="Enter Street (Line 2)"
                              fullWidth
                              isPopupInput
                              className="!mb-0"
                            />
                            <FormInput
                              name={`${name}.${index}.city`}
                              placeholder="Enter City *"
                              size="small"
                              className="!mb-0"
                              inputSx={commanSx}
                              isPopupInput
                              fullWidth
                            />
                            <div className="flex gap-[5px]">
                              <FormInput
                                name={`${name}.${index}.state`}
                                placeholder="Enter State *"
                                size="small"
                                className="!mb-0 w-[145px]"
                                inputSx={commanSx}
                                isPopupInput
                              />
                              <div className="flex">
                                <FormInput
                                  name={`${name}.${index}.zipCode`}
                                  placeholder="Zip Code *"
                                  type="number"
                                  size="small"
                                  className="!mb-0 w-[112px]"
                                  inputSx={{ ...commanSx, padding: '0px 5px' }}
                                  isPopupInput
                                  onChange={event =>
                                    handleZipCodeInput(
                                      event,
                                      index,
                                      'zipCode',
                                      setFieldValue,
                                    )
                                  }
                                />
                                <span className="mx-[2px] text-blue-gray-100">
                                  -
                                </span>
                                <FormInput
                                  name={`${name}.${index}.zipCodeExt`}
                                  placeholder="Ext."
                                  type="number"
                                  size="small"
                                  className="!mb-0 w-[82px]"
                                  inputSx={{ ...commanSx, textAlign: 'center' }}
                                  isPopupInput
                                  onChange={event =>
                                    handleZipCodeInput(
                                      event,
                                      index,
                                      'zipCodeExt',
                                      setFieldValue,
                                    )
                                  }
                                />
                              </div>
                            </div>
                            <FormInput
                              name={`${name}.${index}.country`}
                              size="small"
                              className="!mb-0"
                              inputSx={commanSx}
                              isPopupInput
                              fullWidth
                              readOnly
                            />

                            {values[name].length !== index + 1 && (
                              <Divider className="my-[10px]" />
                            )}
                          </div>
                        ),
                      )}
                    <AddButton
                      onClick={() => push(addressInitialValues)}
                      className="mb-1"
                      toolTipText={'Add Address'}
                    />
                  </div>
                )}
              </FieldArray>
            </Form>
          </CosBasDialog>
        );
      }}
    </Formik>
  ) : null;
};

export default AddNewAddress;
