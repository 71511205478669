import React, { useEffect, useState } from 'react';
import CosInput, { CosInputProps } from './CosInput';
import clsx from 'clsx';
import { Eye, EyeHide, HideEye, HideEyeHover } from '../../../assets/svgs';
import { SvgIconProps, Tooltip, alpha } from '@mui/material';
import SvgWrapper from '../../SvgWrapper';
import CosTooltip from '../../CosTooltip';

export interface CosPasswordInputProps extends CosInputProps {
  defaultVisibilityState?: boolean;
  withKeyIcon?: boolean;
  iconProps?: Omit<SvgIconProps, 'children'>;
  tooltipTitle?: string;
}

const HideShowComponent = React.forwardRef(function HideShowComponent(
  { value, handlePassword, iconProps, showPassword, ...rest }: any,
  ref: any,
) {
  //  Spread the props to the underlying DOM element.
  return (
    <div {...rest} ref={ref}>
      <SvgWrapper
        // sx={value ? { color: '#001F38' } : { color: '#B4BBC3' }}
        sx={{ color: '#001F38' }}
        viewBox="0 0 19.859 11.81"
        className="cursor-pointer"
        onClick={handlePassword}
        {...iconProps}
        style={{ marginBottom: '-6px' }}
      >
        {showPassword ? <Eye /> : <EyeHide />}
      </SvgWrapper>
    </div>
  );
});
const HideShowFillComponent = React.forwardRef(function HideShowFillComponent(
  { value, handlePassword, iconProps, showPassword, ...rest }: any,
  ref: any,
) {
  return (
    <div {...rest} ref={ref}>
      <SvgWrapper
        // sx={value ? { color: '#001F38' } : { color: '#B4BBC3' }}
        sx={{ color: '#001F38' }}
        viewBox="0 0 19.859 11.81"
        className="cursor-pointer"
        onClick={handlePassword}
        {...iconProps}
        style={{ marginBottom: '-6px' }}
      >
        {showPassword ? <HideEye /> : <HideEyeHover />}
      </SvgWrapper>
    </div>
  );
});

const CosPasswordInput: React.FC<CosPasswordInputProps> = ({
  isRequired = true,
  className,
  defaultVisibilityState = false,
  value,
  withKeyIcon,
  iconProps,
  tooltipTitle,
  inputSx,
  ...rest
}) => {
  const [showPassword, setShowPassword] = useState(defaultVisibilityState);
  const [isShowOpen, setShowOpen] = useState(false);
  const handlePassword = () => {
    setShowPassword(preValue => !preValue);
  };

  return (
    <div className={clsx('relative', className)}>
      <CosInput
        fullWidth
        className="!mb-0"
        value={value}
        inputSx={{
          paddingRight: `${50 + 10}px`,
          ...inputSx,
        }}
        {...rest}
        type={showPassword ? 'text' : 'password'}
        hideKeeperSuggestion={false}
      />
      <span
        className={clsx(
          'absolute right-0 z-10 inline-flex h-full w-[50px] transform items-center justify-center border-0 border-l-[0.5px] border-solid',
          { 'top-[2px]': showPassword },
        )}
        style={{ borderColor: alpha('#FFAF2A', 0.25) }}
        key={showPassword ? 'show-password' : 'text'}
      >
        <CosTooltip
          tabIndex={-1}
          title={showPassword ? 'Hide' : 'Show'}
          placement="top"
          // open={true}
          onClose={() => {
            setShowOpen(false);
          }}
          onOpen={() => {
            setShowOpen(true);
          }}
          componentsProps={{
            tooltip: {
              sx: {
                marginBottom: '8px !important',
                width: '55px',
              },
            },
          }}
        >
          <div>
            {!isShowOpen && (
              <HideShowComponent
                value={value}
                handlePassword={handlePassword}
                iconProps={iconProps}
                showPassword={showPassword}
              />
            )}

            {isShowOpen && (
              <HideShowFillComponent
                value={value}
                handlePassword={handlePassword}
                iconProps={iconProps}
                showPassword={showPassword}
              />
            )}
          </div>
        </CosTooltip>
      </span>
    </div>
  );
};

export default CosPasswordInput;
