import React from 'react';
import { CosBasDialog } from '../../../components/dialogBox';
import { FormRadio } from '../../../components/formikForms';
import { Form, Formik } from 'formik';
import { CosButton } from '../../../components';
import { Divider } from '@mui/material';
import {
  uploadTextExemptInitialValue,
  uploadTextExemptValidationSchema,
  UplodaData,
} from '../formConfig/profileForm';

interface Props {
  open: boolean;
  onClose?: () => void;
  onUpload: (data: UplodaData) => void;
}

const UploadTextExempt: React.FC<Props> = ({ open, onClose, onUpload }) => {
  const handleSubmit = (values: UplodaData) => {
    if (typeof onUpload === 'function') {
      onUpload(values);
    }
  };

  const handleClose = (setFieldValue: Function) => {
    onClose?.();
    setFieldValue(
      'textExemptStatus',
      uploadTextExemptInitialValue.textExemptStatus,
    );
    setFieldValue(
      'textExemptDocument',
      uploadTextExemptInitialValue.textExemptDocument,
    );
  };

  return open ? (
    <CosBasDialog
      open={open}
      sx={{
        '.MuiPaper-root': {
          background: '#EDEEEF',
        },
      }}
      height="304px"
      width="584px"
      heading={'Important Documents Required'}
      headingClass={
        'text-left text-18/24 text-blue-gray-900 bg-white-whale-500 p-[15px]'
      }
      showDivider={true}
      dividerClass={'border-[1px] border-solid border-blue-gray-300 opacity-15'}
      bodyClass={'bg-white-whale-500 p-0'}
    >
      <Formik
        initialValues={uploadTextExemptInitialValue}
        onSubmit={handleSubmit}
        validationSchema={uploadTextExemptValidationSchema}
      >
        {({ setFieldValue, values }) => {
          const fileName =
            values.textExemptDocument &&
            (values.textExemptDocument as File).name;
          return (
            <Form className="h-[100%]">
              <div className="flex h-[100%] flex-col justify-between">
                <div className="p-[15px]">
                  <div className="flex items-center justify-between">
                    <span className="text-12/17">
                      Tax Exempt Status Documents
                    </span>
                    <div className="flex items-center gap-[15px]">
                      <span className="text-12/17">Tax Exempt Status</span>

                      <div className="flex h-[28px] flex-row items-center gap-[13px]">
                        <FormRadio
                          name="textExemptStatus"
                          value="yes"
                          label="Yes"
                          labelProps={{
                            required: false,
                            className: 'text-12/17',
                          }}
                        />
                        <FormRadio
                          name="textExemptStatus"
                          value="no"
                          label="No"
                          labelProps={{
                            required: false,
                            className: 'text-12/17',
                          }}
                        />
                      </div>
                    </div>
                  </div>
                  <div className="opacity-1 mb-[10px] mt-[15px] flex h-[37px] items-center justify-between rounded-[5px] bg-white-whale-100 shadow-3b">
                    <span className="pl-[12px] text-left text-14/19">
                      {fileName ? fileName : 'Upload Documents'}
                    </span>
                    <CosButton
                      name="textExemptDocument"
                      size="medium"
                      component="label"
                      variant="contained"
                      className="m-[6px] text-12/17"
                      onChange={e => {
                        const input = e.target as HTMLInputElement;
                        if (input.files && input.files.length > 0) {
                          const file = input.files[0];
                          setFieldValue('textExemptDocument', file);
                        }
                      }}
                    >
                      {/* Pass component="label" if you want to upload file */}
                      Choose File
                    </CosButton>
                  </div>
                  <p className="m-0 text-center text-14/19 text-blue-gray-100">
                    Jpg, Png and pdf only.
                  </p>
                </div>
                <div>
                  <p className="m-0 pb-[16px] text-center text-13/18 text-dark-blue-900">
                    You must upload documents or update tax exemption status to
                    No to proceed
                  </p>
                  <div className="flex h-[44px] overflow-hidden border-0 border-t-[0.5px] border-solid border-artist-blue-900 bg-white-whale-500 p-0">
                    <CosButton
                      size="medium"
                      variant="text"
                      className="min-h-auto w-1/2"
                      onClick={() => handleClose(setFieldValue)}
                    >
                      Close
                    </CosButton>
                    <Divider
                      className="opacity-1 m-0 bg-artist-blue-900"
                      flexItem
                      orientation="vertical"
                    ></Divider>
                    <CosButton
                      size="medium"
                      variant="text"
                      type="submit"
                      className="min-h-auto m-0 w-1/2"
                      disabled={!values.textExemptDocument} // Disable button if no document uploaded
                    >
                      Upload
                    </CosButton>
                  </div>
                </div>
              </div>
            </Form>
          );
        }}
      </Formik>
    </CosBasDialog>
  ) : (
    <></>
  );
};

export default UploadTextExempt;
