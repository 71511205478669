import styled from '@emotion/styled';
import { Switch, SwitchProps, alpha } from '@mui/material';
import { CosTheme } from '../../libs/interface';

interface SwitchSizeConstants {
  switchHeight: number;
  switchWidth: number;
  thumbTransformX: string;
}
interface SwitchUIConstants {
  inActiveBgColor: string;
  inActiveTextColor: string;
  activeTextColorHover: string;
  inActiveTextColorHover: string;

  thumbColor: string;
  activeThumbHover: string;
  inActiveThumbHover: string;
  activeBgColor: string;
  activeBorderColor: string;
  inactiveBorderColor: string;
  borderColorHover: string;
  activeTextColor: string;
}

type SwitchUI = SwitchSizeConstants & SwitchUIConstants;
type SwitchType = 'dataTable' | 'popup';

const BaseSwitch = styled(Switch, {
  shouldForwardProp: prop =>
    prop !== 'switchUiConstants' && prop !== 'switchType',
})<{
  switchUiConstants: SwitchUI;
  switchType?: SwitchType;
}>(({ theme, switchUiConstants, switchType }) => ({
  padding: 0,
  '&.MuiSwitch-sizeSmall': {
    width: switchUiConstants.switchWidth,
    height: switchUiConstants.switchHeight,
  },
  '& .MuiSwitch-switchBase': {
    transitionDuration: '300ms',
    // * Disable
    '&.Mui-disabled + .MuiSwitch-track': {
      opacity: '0.25 !important',
    },

    //* When switch on
    '&.Mui-checked': {
      transform: `translateX(${switchUiConstants.thumbTransformX})`,
      //* when switch on -> track css
      '& + .MuiSwitch-track': {
        backgroundColor: switchUiConstants.activeBgColor,
        opacity: '1',
        color: switchUiConstants.activeTextColor,
        borderColor: switchUiConstants.activeBorderColor,
        '&::before': {
          content: switchType === 'dataTable' ? '"YES"' : '"ON"',
          left: '48%',
        },
      },
      '.MuiSwitch-input': {
        width: switchUiConstants.switchWidth,
        left: `calc(${switchUiConstants.switchHeight}px - ${switchUiConstants.switchWidth}px)`,
      },

      '&:hover + .MuiSwitch-track': {
        color: switchUiConstants.activeTextColorHover,
        borderColor: switchUiConstants.borderColorHover,
      },

      '&:hover .MuiSwitch-thumb': {
        backgroundColor: switchUiConstants.activeThumbHover,
        borderColor: switchUiConstants.borderColorHover + '!important',
        opacity: switchType === 'dataTable' ? 1 : 0.75,
      },
    },

    //* When switch is off
    '&:not(.Mui-checked)': {
      //* when switch off -> track css
      '& + .MuiSwitch-track': {
        backgroundColor: switchUiConstants.inActiveBgColor,
        color: switchUiConstants.inActiveTextColor,
        borderColor: switchUiConstants.inactiveBorderColor,
        '&::after': {
          content: switchType === 'dataTable' ? '"NO"' : '"OFF"',
          left: '54%',
        },
      },

      '.MuiSwitch-input': {
        width: `${switchUiConstants.switchWidth}px`,
        right: `calc(${switchUiConstants.switchHeight}px - ${switchUiConstants.switchWidth}px)`,
        left: 0,
      },

      '&:hover + .MuiSwitch-track': {
        color: switchUiConstants.inActiveTextColorHover,
        borderColor: switchUiConstants.borderColorHover,
      },

      //* Mark hover
      '&:hover .MuiSwitch-thumb': {
        backgroundColor: switchUiConstants.inActiveThumbHover,
        borderColor: switchUiConstants.borderColorHover + '!important',
      },
    },

    //* Default
    '& + .MuiSwitch-track': {
      borderRadius: 22 / 2,
      border: '0.5px solid',
      opacity: '1', // Manage

      '&::before, &::after': {
        position: 'absolute',
        top: '50%',
        transform: 'translate(-50%, -50%)',
        fontSize: 10,
        lineHeight: '14px',
      },
    },
  },

  '& .MuiButtonBase-root': {
    padding: 0,
    margin: 0,
    width: switchUiConstants.switchHeight,
    height: switchUiConstants.switchHeight,
  },

  //*  Round thumb
  '& .MuiSwitch-thumb': {
    margin: 0,
    boxShadow: 'none',
    boxSizing: 'border-box',
    width: switchUiConstants.switchHeight,
    height: switchUiConstants.switchHeight,
    border: '0.5px solid',
    borderColor: switchUiConstants.activeBorderColor,
    backgroundColor: '#ffffff',
    transition: 'all 0.3s ease',
    background: switchUiConstants.thumbColor,
  },

  '.Mui-disabled .MuiSwitch-thumb': {
    borderColor: alpha(switchUiConstants.activeBorderColor, 0.25),
  },
}));

/**
 * @param dataTable : switch for data table
 * @param popup : switch for popup
 */
export interface CosSwitchProps extends Omit<SwitchProps, 'size'> {
  version?: CosTheme;
  switchUIConstants?: SwitchUI;
  switchType?: SwitchType;
}

const CosSwitch: React.FC<CosSwitchProps> = ({
  version = 'light',
  switchType = 'dataTable',
  ...rest
}) => {
  const isDataTableSwitch = switchType === 'dataTable';

  const switchSize: SwitchSizeConstants = {
    switchHeight: isDataTableSwitch ? 17 : 12,
    switchWidth: isDataTableSwitch ? 52 : 40,
    thumbTransformX: isDataTableSwitch ? '35px' : '28px',
  };

  const lightPopUPSwitchUiConstants: SwitchUI = {
    ...switchSize,
    inActiveBgColor: 'transparent',
    activeBgColor: '#001F38',

    inActiveTextColor: '#001F38',
    activeTextColor: '#ffffff',
    inActiveTextColorHover: '#001F38',
    activeTextColorHover: '#ffffff',

    thumbColor: '#ffffff',

    activeThumbHover: '#FFFFFF',
    inActiveThumbHover: '#001F38',

    activeBorderColor: '#001F38',
    inactiveBorderColor: '#001F38',
    borderColorHover: '#001F38',
  };

  const darkPopUPSwitchUiConstants: SwitchUI = {
    ...switchSize,
    inActiveBgColor: 'transparent',
    activeBgColor: '#07B9EC',

    inActiveTextColor: '#07B9EC',
    activeTextColor: '#ffffff',
    inActiveTextColorHover: '#07B9EC',
    activeTextColorHover: '#ffffff',

    thumbColor: '#ffffff',

    activeThumbHover: '#FFFFFF',
    inActiveThumbHover: '#07B9EC',

    activeBorderColor: '#07B9EC',
    inactiveBorderColor: '#07B9EC',
    borderColorHover: '#07B9EC',
  };

  const lightDataTableSwitchUiConstants: SwitchUI = {
    ...switchSize,
    inActiveBgColor: 'transparent',
    activeBgColor: 'transparent',

    inActiveTextColor: '#A8B1B8',
    activeTextColor: '#001F38',
    inActiveTextColorHover: '#07B9EC',
    activeTextColorHover: '#07B9EC',

    thumbColor: '#001F38',

    activeThumbHover: '#07B9EC',
    inActiveThumbHover: '#07B9EC',

    activeBorderColor: '#001F38',
    inactiveBorderColor: '#A8B1B8',
    borderColorHover: '#07B9EC',
  };

  const darkDataTableSwitchUiConstants: SwitchUI = {
    ...switchSize,
    inActiveBgColor: 'transparent',
    activeBgColor: 'transparent',

    inActiveTextColor: '#ffffff',
    activeTextColor: '#ffffff',
    inActiveTextColorHover: '#001F38',
    activeTextColorHover: '#001F38',

    thumbColor: '#ffffff',

    activeThumbHover: '#001F38',
    inActiveThumbHover: '#001F38',

    activeBorderColor: '#ffffff',
    inactiveBorderColor: '#ffffff',
    borderColorHover: '#001F38',
  };

  const darkSwitchUiConstants = isDataTableSwitch
    ? darkDataTableSwitchUiConstants
    : darkPopUPSwitchUiConstants;
  const lightSwitchUiConstants = isDataTableSwitch
    ? lightDataTableSwitchUiConstants
    : lightPopUPSwitchUiConstants;

  return (
    <>
      <BaseSwitch
        switchUiConstants={
          version === 'dark' ? darkSwitchUiConstants : lightSwitchUiConstants
        }
        disableFocusRipple
        disableTouchRipple
        disableRipple
        size={'small'}
        switchType={switchType}
        {...rest}
      />
    </>
  );
};
export default CosSwitch;
