import React, { useEffect, useRef } from 'react';
import Autocomplete from '@mui/material/Autocomplete';
import { Box, ChipTypeMap, Paper } from '@mui/material';
import CosLabel from '../CosLabel';
import { ArrowDropDownIcon, ExpandArrow } from '../../../../assets/svgs';
import { cn } from '../../../../libs/utils';
import clsx from 'clsx';
import { CosAutoCompleteProps, ExtraProps } from './cosAutoComplete.interface';
import CosAutoCompleteInput from './CosAutoCompleteInput';
import useCosAutoComplete from './useCosAutoComplete';
import CosAutoCompleteDropdown from './CosAutoCompleteDropdown';
import { ListBoxProps } from './CosAutoCompleteItms';
import CosCheckbox from '../CosCheckbox';
import { KEYBOARD_KEY } from '../../../../libs/constant';
import { manageHighlightItem } from './util';

const CosAutoComplete = <
  Value,
  Multiple extends boolean | undefined = false,
  DisableClearable extends boolean | undefined = false,
  FreeSolo extends boolean | undefined = false,
  ChipComponent extends React.ElementType = ChipTypeMap['defaultComponent'],
>({
  inputSx,
  isRequired,
  label,
  labelProps,
  tooltipTitle,
  className,
  textFieldProps,
  name = '',
  placeholder,
  onChange,
  sx,
  isAddNew,
  addNewProps,
  arrowType = 'chevron',
  withElevation = true,
  fullWidth,
  multiple,
  options,
  isPopupInput = false,
  ...rest
}: CosAutoCompleteProps<
  Value,
  Multiple,
  DisableClearable,
  FreeSolo,
  ChipComponent
> &
  ExtraProps) => {
  const {
    handleChange,
    handleFilter,
    isOpen,
    value,
    handleOpen,
    handleClose,
    handleToggleSelectAll,
    isSelectAll,
    handleApply,
    isInitialState,
    handleCancel,
    inputRef,
    handleMultiSelect,
    cloneValue,
  } = useCosAutoComplete({ onChange, options, name, multiple: !!multiple });

  const listBoxRef: any = useRef();

  const onKeyDown = (event: KeyboardEvent) => {
    const key = event.key;
    if (isOpen) {
      switch (key) {
        case KEYBOARD_KEY.enter:
          !isInitialState && handleApply();
          break;
        case KEYBOARD_KEY.down:
          manageHighlightItem(listBoxRef?.current);
          break;
        default:
          break;
      }
    }
  };

  useEffect(() => {
    isOpen
      ? window.addEventListener('keydown', onKeyDown)
      : window.removeEventListener('keydown', onKeyDown);
    return () => window.removeEventListener('keydown', onKeyDown);
  }, [isInitialState, listBoxRef, isOpen]);

  return (
    <Box
      className={clsx('mb-4 inline-block', { 'w-full': fullWidth }, className)}
    >
      {label && (
        <CosLabel
          isRequired={isRequired}
          {...labelProps}
          className={cn('mb-[5px]', labelProps?.className)}
          tooltipTitle={tooltipTitle}
        >
          {label}
        </CosLabel>
      )}
      <Autocomplete
        open={isOpen}
        options={options}
        onOpen={handleOpen}
        onClose={handleClose}
        value={value}
        disableCloseOnSelect={!!multiple}
        multiple={multiple}
        openOnFocus
        openText={''}
        closeText={''}
        clearIcon={<span className="pointer-events-none hidden" />}
        loadingText={'Loading…'}
        onChange={handleChange}
        filterOptions={handleFilter}
        ListboxProps={ListBoxProps(!!isAddNew, !!multiple)}
        PaperComponent={({ children, className, ...paperProps }) => {
          return (
            <Paper
              className={clsx(className, 'rounded-t-none')}
              {...paperProps}
            >
              {children}
            </Paper>
          );
        }}
        PopperComponent={({ children, className, sx, ...popperProps }) => (
          <CosAutoCompleteDropdown
            isAddNew={!!isAddNew}
            addNewProps={addNewProps}
            className={className}
            sx={sx}
            {...popperProps}
            multiple={!!multiple}
            onClose={() => {
              const inputEle = inputRef.current;
              // inputEle?.blur();
              handleClose();
            }}
            handleApply={handleApply}
            isInitialState={isInitialState}
            handleCancel={handleCancel}
            onSelectAll={handleToggleSelectAll}
            isSelectAll={isSelectAll}
            ref={listBoxRef}
          >
            {children}
          </CosAutoCompleteDropdown>
        )}
        sx={{
          ...(withElevation
            ? {
                borderRadius: `3px`,
                boxShadow: '0 0 3px #A8B1B8',
              }
            : {}),
          position: 'relative',
          //* When open
          '&.Mui-expanded': {
            '& .MuiInputBase-root': {
              borderBottomLeftRadius: '0',
              borderBottomRightRadius: '0',
            },
          },
          '.COS-mask-placeHolder': {
            color: '#001f38',
            transition: 'opacity 0.3s ease',
          },
          '&:hover .COS-mask-placeHolder, &:focus .COS-mask-placeHolder': {
            opacity: 0.75,
          },
          ...sx,
        }}
        {...rest}
        renderInput={(params: any) => {
          return (
            <Box className="">
              <CosAutoCompleteInput
                type="text"
                name={name}
                {...params}
                inputSx={inputSx}
                {...textFieldProps}
                sx={{
                  '& .MuiInputBase-root': {
                    flexWrap: 'nowrap',
                  },
                  '& .MuiAutocomplete-input': {
                    minWidth: multiple ? '1px !important' : '30px',
                    lineHeight: 2,
                    fontWeight: isPopupInput ? '600' : '400',
                  },
                }}
                inputRef={inputRef}
              />
              {placeholder &&
                (Array.isArray(value) ? !value?.length : !value) &&
                !params?.inputProps?.value && (
                  <p className="COS-mask-placeHolder absolute left-[9px] top-1/2 m-0 -translate-y-1/2 text-14/18">
                    {placeholder}
                  </p>
                )}
            </Box>
          );
        }}
        popupIcon={
          <>
            {arrowType === 'chevron' ? (
              <ArrowDropDownIcon />
            ) : (
              <span className="cos-expand w-[24px]" tabIndex={-1}>
                <ExpandArrow
                  style={{ width: 12, height: 12, color: '#001F38' }}
                />
              </span>
            )}
          </>
        }
        renderTags={value => (
          <p
            className={`COS-MultiValue m-0 truncate text-14/18 ${isPopupInput ? 'font-semibold' : 'font-normal'}`}
          >
            {value.map((item: any) => item?.label).join(',')}
          </p>
        )}
        renderOption={(props, option: any, { selected }) => {
          const { onClick, ...rest } = props;
          const index = cloneValue?.findIndex(
            (item: any) => item.value === option.value,
          );
          const isSelected = index === -1 ? false : true;

          return (
            <li
              onMouseEnter={(event: any) => {
                event.target.classList.remove('Mui-focused');
                event.target.classList.add('Mui-focused');
              }}
              onMouseLeave={(event: any) => {
                event.target.classList.remove('Mui-focused');
              }}
              {...rest}
              onClick={event =>
                multiple ? handleMultiSelect(option) : onClick?.(event)
              }
              aria-selected={multiple ? isSelected : selected}
              className={cn(
                { 'Mui-focused': isSelected && multiple },
                props.className,
              )}
            >
              {multiple && (
                <CosCheckbox style={{ marginRight: 5 }} checked={isSelected} />
              )}
              {option.label}
            </li>
          );
        }}
      />
    </Box>
  );
};

export default CosAutoComplete;
