import { useEffect } from 'react';

const useTabScroll = (
  tabsRef: React.RefObject<HTMLDivElement>,
  tabType?: 'vertical' | 'horizontal',
) => {
  useEffect(() => {
    const container = tabsRef.current;
    if (!container) return;

    const scrollerContainer = container.getElementsByClassName(
      'MuiTabs-scroller',
    )[0] as HTMLElement;
    const tabWrapper = scrollerContainer.getElementsByClassName(
      'MuiTabs-flexContainer',
    )[0];

    const tab = tabWrapper.getElementsByClassName('MuiTab-root')[0];
    let tabWidth = tab.getBoundingClientRect().width + 5;
    const handleShiftMouseScroll = (event: WheelEvent) => {
      const scrollPosition = scrollerContainer.scrollLeft;
      if (tabType === 'vertical') {
        tabWidth = tab.getBoundingClientRect().height + 5;
        if (event.shiftKey) {
          scrollerContainer.classList.add('mainSnapScrollClass');
          tabWrapper.classList.add('snapScrollClass');
          event.preventDefault();
          if (event.deltaY > 0) {
            scrollerContainer.scrollTo({
              left: scrollPosition + tabWidth,
              behavior: 'smooth',
            });
          } else {
            scrollerContainer.scrollTo({
              left: scrollPosition - tabWidth,
              behavior: 'smooth',
            });
          }
        } else {
          event.preventDefault();
        }
      } else {
        if (event.shiftKey) {
          scrollerContainer.classList.add('mainSnapScrollClass');
          tabWrapper.classList.add('snapScrollClass');
          event.preventDefault();
          if (event.deltaY > 0) {
            scrollerContainer.scrollTo({
              left: scrollPosition + tabWidth,
              behavior: 'smooth',
            });
          } else {
            scrollerContainer.scrollTo({
              left: scrollPosition - tabWidth,
              behavior: 'smooth',
            });
          }
        } else {
          scrollerContainer.classList.remove('mainSnapScrollClass');
          tabWrapper.classList.remove('snapScrollClass');
          if (event.deltaY > 0) {
            scrollerContainer.scrollTo({
              left: scrollPosition + event.deltaY,
              behavior: 'smooth',
            });
          } else {
            scrollerContainer.scrollTo({
              left: scrollPosition - event.deltaY,
              behavior: 'smooth',
            });
          }
        }
      }
    };

    scrollerContainer.addEventListener('wheel', handleShiftMouseScroll, {
      passive: false,
    });
    // Cleanup event listeners on component unmount
    return () => {
      scrollerContainer.removeEventListener('wheel', handleShiftMouseScroll);
    };
  }, [tabsRef]);
};

export default useTabScroll;
