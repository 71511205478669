import ExpandArrowBtn from './ExpandArrowBtn';
import clsx from 'clsx';
import { ArrowBtnProps } from './arrow-interface';
import { cn } from '../../libs/utils';

const NextPrevArrowBtn: React.FC<ArrowBtnProps> = ({
  className,
  open,
  ...rest
}) => {
  return (
    <ExpandArrowBtn
      className={cn(
        'rotate-[270deg] text-artist-blue-900',
        {
          'rotate-90': open,
        },
        className,
      )}
      {...rest}
    />
  );
};

export default NextPrevArrowBtn;
