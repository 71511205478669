import React from 'react';
import CosRadioBox from '../components/formikForms/ui/CosRadio';
import { RadioGroup } from '@mui/material';
import StandardLayout from './StandardLayout';

const StandardPageRadio = () => {
  return (
    <StandardLayout>
      <p className="mt-0">Normal</p>
      <div className="flex h-[50px] w-[210px] items-center justify-center gap-4">
        <RadioGroup
          name="normal-radio"
          defaultValue={1}
          className="flex flex-row gap-5"
        >
          <CosRadioBox value={1} />
          <CosRadioBox value={2} />
          <CosRadioBox value={3} />
          <CosRadioBox value={4} />
          <CosRadioBox value={5} />
          <CosRadioBox value={6} />
          <CosRadioBox value={7} disabled />
        </RadioGroup>
      </div>

      <p>Gray Background</p>
      <div className="flex h-[50px] w-[210px] items-center justify-center gap-4 bg-white-whale-500">
        <RadioGroup
          name="gray-radio"
          defaultValue={1}
          className="flex flex-row gap-5"
        >
          <CosRadioBox withGrayBg value={1} />
          <CosRadioBox withGrayBg value={2} />
          <CosRadioBox withGrayBg value={3} />
          <CosRadioBox withGrayBg value={4} />
          <CosRadioBox withGrayBg value={5} />
          <CosRadioBox withGrayBg value={6} />
          <CosRadioBox withGrayBg value={7} disabled />
        </RadioGroup>
      </div>

      <p>Blue Background</p>
      <div className="flex h-[50px] w-[210px] flex-row items-center justify-center gap-4 bg-artist-blue-900">
        <RadioGroup
          name="dark-radio"
          defaultValue={1}
          className="flex flex-row gap-5"
        >
          <CosRadioBox version="dark" value={1} />
          <CosRadioBox version="dark" value={2} />
          <CosRadioBox version="dark" value={3} />
          <CosRadioBox version="dark" value={4} />
          <CosRadioBox version="dark" value={5} />
          <CosRadioBox version="dark" value={6} />
          <CosRadioBox version="dark" value={7} disabled />
        </RadioGroup>
      </div>
    </StandardLayout>
  );
};

export default StandardPageRadio;
