import { TableHead, TableHeadProps } from '@mui/material';
import React from 'react';

interface Props extends TableHeadProps {}

const CosTableHead: React.FC<Props> = props => {
  return <TableHead {...props} />;
};

export default CosTableHead;
