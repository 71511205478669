import { RefObject, useEffect, useState } from 'react';

interface Props {
  ref: RefObject<HTMLElement>;
  list: any[];
  valueKey: string; //* always same as checkBox value (* should be available into provide list)
}

const useShiftMultiCheckBox = ({ ref, list, valueKey }: Props) => {
  const [isShiftDown, setIsShiftDown] = useState<boolean>(false);
  const [selectedItems, setSelectedItems] = useState<string[]>([]);
  const [lastSelectedItem, setLastSelectedItem] = useState<string | null>(null);

  useEffect(() => {
    const handleKeyUp = (e: KeyboardEvent) => {
      if (e.key === 'Shift' && isShiftDown) {
        setIsShiftDown(false);
      }
    };

    const handleKeyDown = (e: KeyboardEvent) => {
      if (e.key === 'Shift' && !isShiftDown) {
        setIsShiftDown(true);
      }
    };

    const handleSelectStart = (e: Event) => {
      if (isShiftDown) {
        e.preventDefault();
      }
    };

    document.addEventListener('keyup', handleKeyUp);
    document.addEventListener('keydown', handleKeyDown);
    if (ref.current) {
      ref.current.addEventListener('selectstart', handleSelectStart);
    }

    return () => {
      document.removeEventListener('keyup', handleKeyUp);
      document.removeEventListener('keydown', handleKeyDown);
      if (ref.current) {
        ref.current.removeEventListener('selectstart', handleSelectStart);
      }
    };
  }, [isShiftDown]);

  // TODO: manage event
  const handleSelectItem = (e: any) => {
    const { value } = e.target;
    const nextValue = getNextValue(value);

    setSelectedItems(nextValue);
    setLastSelectedItem(value);
  };

  const getNextValue = (value: string) => {
    const hasBeenSelected = !selectedItems.includes(value);

    if (isShiftDown && lastSelectedItem !== null) {
      const newSelectedItems = getNewSelectedItems(value);
      const test2: any = new Set([...selectedItems, ...newSelectedItems]);
      const selections: any = [...test2];

      if (!hasBeenSelected) {
        return selections.filter(
          (item: any) => !newSelectedItems.includes(item),
        );
      }

      return selections;
    }

    return selectedItems.includes(value)
      ? selectedItems.filter(item => item !== value)
      : [...selectedItems, value];
  };

  const getNewSelectedItems = (value: string) => {
    const currentSelectedIndex = list.findIndex(
      item => item[valueKey] === value,
    );
    const lastSelectedIndex = list.findIndex(
      item => item[valueKey] === lastSelectedItem!,
    );

    return Array.from(
      new Set(
        list
          .slice(
            Math.min(lastSelectedIndex, currentSelectedIndex),
            Math.max(lastSelectedIndex, currentSelectedIndex) + 1,
          )
          .map(item => item[valueKey]),
      ),
    );
  };

  return {
    selectedItems,
    handleSelectItem,
  };
};

export default useShiftMultiCheckBox;
