import { cosPadStart } from '../../libs/utils';
import { MeridiemType } from './MeridiemInput';

export function getFilledArray<
  T extends (v: number, k: number) => unknown,
  Y extends ReturnType<T>,
>(range: number, mapfn: T): Y[] {
  return range <= 0 ? [] : (Array.from({ length: range }, mapfn) as Y[]);
}

export function updateIndex<T extends unknown[]>(
  array: T,
  indexItem: number,
  item: T[keyof T],
): T {
  return array.map((chipItem, index) => {
    return indexItem === index ? item : chipItem;
  }) as T;
}

export function joinArrayStrings(array: readonly string[]): string {
  return array.join('');
}

export function append<T extends unknown[]>(array: T, item: T[keyof T]): T {
  return [...array, item] as T;
}

export function set12hrFormate(
  hr: string,
  min: string,
  meridiem: MeridiemType,
): string {
  return `${cosPadStart(hr)}:${cosPadStart(min)} ${meridiem.toUpperCase()}`;
}
