import React from 'react';

interface Props {
  count: number | string;
}

const CosCountBadge: React.FC<Props> = ({ count }) => {
  return (
    <span className="text-11/18 inline-flex size-4 items-center justify-center rounded-full bg-artist-blue-900 text-white">
      {count}
    </span>
  );
};

export default CosCountBadge;
