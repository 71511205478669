import styled from '@emotion/styled';
import { SxProps, Tooltip, TooltipProps, tooltipClasses } from '@mui/material';
import React, { CSSProperties, useState } from 'react';
import { CosTheme } from '../libs/interface';
import { cn } from '../libs/utils';

interface CustomTooltipProps extends TooltipProps {
  color?: string;
  background?: string;
  textDecoration?: CSSProperties['textDecoration'];
  version?: CosTheme;
  yOffset?: number;
  xOffset?: number;
  onHoverIcon?: React.ReactNode;
  tooltipsx?: SxProps;
  wrapperClass?: string;
  tabIndex?: number;
}

const BaseTooltip = styled(({ className, ...props }: CustomTooltipProps) => (
  <Tooltip {...props} arrow classes={{ popper: className }} />
))(({ color, version, background, textDecoration, tooltipsx }: any) => ({
  [`& .${tooltipClasses.arrow}`]: {
    color:
      version === 'dark'
        ? '#001F38'
        : version === 'light'
          ? '#ffffff'
          : background,
  },
  [`& .${tooltipClasses.tooltip}`]: {
    background:
      version === 'dark'
        ? '#001F38'
        : version === 'light'
          ? '#ffffff'
          : background,
    marginLeft: '0',
    fontSize: '11px',
    boxShadow:
      version === 'dark' ? '0px 0px 3px #07B9EC' : '0px 0px 3px #A8B1B8',
    lineHeight: '17px',
    color:
      version === 'dark' ? '#ffffff' : version === 'light' ? '#001F38' : color,
    '&:hover': {
      textDecoration: textDecoration,
    },
    height: '21px',
    minWidth: '55px',
    textAlign: 'center',
    borderRadius: '3px',
    padding: '0 10px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    ...(!!tooltipsx ? tooltipsx : {}),
  },

  //* TOP
  '&.MuiTooltip-popper[data-popper-placement*="top"]': {
    '& .MuiTooltip-tooltip': {
      marginBottom: '0px !important',
    },
    '& .MuiTooltip-arrow': {
      marginBottom: '-0.5em',
    },
  },

  //* right
  '&.MuiTooltip-popper[data-popper-placement*="right"]': {
    // '& .MuiTooltip-tooltip': {
    //   marginRight: '-0.5px',
    // },
    '& .MuiTooltip-arrow': {
      marginLeft: '-0.5em',
    },
  },

  //* right
  '&.MuiTooltip-popper[data-popper-placement*="left"]': {
    // '& .MuiTooltip-tooltip': {
    //   marginLeft: '-0.5px',
    // },
    '& .MuiTooltip-arrow': {
      marginRight: '-0.5em',
    },
  },

  //* Bottom
  '&.MuiTooltip-popper[data-popper-placement*="bottom"]': {
    // '& .MuiTooltip-tooltip': {
    //   marginBottom: '-0.5px',
    // },
    '& .MuiTooltip-arrow': {
      marginTop: '-0.5em',
    },
  },
}));

const Wrapper = React.forwardRef(function MyComponent(
  { children, className, placement, ...rest }: any,
  ref: any,
) {
  return (
    <span
      {...rest}
      ref={ref}
      className={cn('inline-flex items-center', className)}
    >
      {children}
    </span>
  );
});

const CosTooltip: React.FC<CustomTooltipProps> = ({
  color,
  background,
  textDecoration,
  version = 'dark',
  children,
  xOffset = 0,
  yOffset,
  open,
  onHoverIcon,
  tooltipsx,
  wrapperClass,
  placement,
  tabIndex = 1,
  ...rest
}) => {
  const [isOpen, setIsOpen] = useState(open);

  const handleClose = () => {
    setIsOpen(false);
  };
  const handleOpen = () => {
    setIsOpen(true);
  };

  const sanitizeYOffset = placement === 'top' ? yOffset || 3.5 : yOffset || -11;

  return (
    <BaseTooltip
      arrow
      open={isOpen}
      placement={placement}
      onClose={handleClose}
      onOpen={handleOpen}
      slotProps={{
        popper: {
          modifiers: [
            {
              name: 'offset',
              options: {
                offset: [xOffset, sanitizeYOffset],
              },
            },
          ],
        },
      }}
      color={color}
      background={background}
      textDecoration={textDecoration}
      version={version}
      tooltipsx={tooltipsx}
      disableTouchListener={true}
      {...rest}
    >
      <Wrapper
        className={cn('outline-0', wrapperClass)}
        onBlur={handleClose}
        tabIndex={tabIndex}
      >
        {isOpen && onHoverIcon ? onHoverIcon : children}
      </Wrapper>
    </BaseTooltip>
  );
};
export default CosTooltip;
