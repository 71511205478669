export const constant = {
  freeTrailDays: 7,
  modalRadius: 6, //rounded-md
  messageModalRadius: 12,
  WorkScheduleModalRadius: 5,
  inputXSpacing: 10,
  drawerWidth: 200,
  smallDrawerWidth: 50,
  appBarHeight: 100,
  masterLayoutNavbarColor: '#001F38',
  navbarTopHeight: 59,
  navbarBottomHeight: 40,
  navBarItemGap: 12,
  navBarItemBorderWiderSpace: 4,

  smallSizeInputHeight: 28,
  mediumSizeInputHeight: 34,

  smallSizeInputMinWidth: 270,
  inputSmallRadius: 3,

  profileFormSpacing: 10,
};

export enum localStorageKey {
  USER_DATA = 'cosUserData',
  IS_SHOW_BUY_NOW = 'isShowBuyNow',
  ACTIVE_ROUTER = 'activeRouter',
}

export enum KEYBOARD_KEY {
  left = 'ArrowLeft',
  right = 'ArrowRight',
  up = 'ArrowUp',
  down = 'ArrowDown',
  tab = 'Tab',
  backspace = 'Backspace',
  home = 'Home',
  end = 'End',
  esc = 'Escape',
  enter = 'Enter',
}
