import { forwardRef, useState } from 'react';
import {
  Box,
  Divider,
  MenuItem,
  Popper,
  PopperProps,
  alpha,
} from '@mui/material';
import clsx from 'clsx';
import CosButton from '../../../CosButton';
import CosCheckbox from '../CosCheckbox';
import { cn } from '../../../../libs/utils';
import { handleMouseEnter, handleMouseLeave } from './util';

interface CosAutoCompleteDropdownProps extends PopperProps {
  isAddNew: boolean;
  addNewProps: any;
  multiple: boolean;
  isSelectAll: boolean;
  isInitialState: boolean;
  onClose: () => void;
  onSelectAll: () => void;
  handleApply: () => void;
  handleCancel: () => void;
}

const styleSx = {
  boxShadow: '0px 0px 3px #A8B1B8',
  borderRadius: '7px',
  backgroundColor: '#ffffff',
  '& .MuiAutocomplete-paper': {
    backgroundColor: 'transparent',
    boxShadow: 'none',
    borderRadius: '7px',
  },
  '& .MuiAutocomplete-noOptions, & .MuiAutocomplete-loading': {
    padding: '6px 10px',
    fontSize: '12px',
    lineHeight: '10px',
    textTransform: 'capitalize',
    color: '#001F38',
    height: 19.5,
  },
};

const CosAutoCompleteDropdown = forwardRef<
  HTMLDivElement,
  CosAutoCompleteDropdownProps
>(
  (
    {
      children,
      className,
      sx,
      isAddNew,
      addNewProps,
      multiple,
      onClose,
      onSelectAll,
      isSelectAll,
      handleApply,
      isInitialState,
      handleCancel,
      ...rest
    },
    ref,
  ) => {
    const [isAddNewProp, setAddNewProp] = useState(false);
    const handleAddNew = (event: any) => {
      event.preventDefault();
      setAddNewProp(true);
    };
    return (
      <Popper
        {...rest}
        className={clsx(className, 'rounded-t-none')}
        // onMouseLeave={handleMouseLeave}
        // onMouseEnter={handleMouseEnter}
        sx={{ ...sx, ...styleSx }}
        placement="bottom"
        ref={ref}
      >
        <>
          {isAddNew && !isAddNewProp ? (
            <p
              onMouseDown={handleAddNew}
              className="m-0 mb-[5px] inline-flex h-[17px] min-h-[auto] cursor-pointer items-center p-0 pl-[10px] pr-[11px] text-12/10 text-dark-blue-900"
            >
              Add New
            </p>
          ) : (
            addNewProps && <div className="">{addNewProps}</div>
          )}
          {children}
          {multiple && (
            <>
              <MenuItem
                className={cn(
                  'MuiAutocomplete-option mb-[6px] mt-[5px] flex h-[17px] pl-[10px] pr-[11px]',
                  { 'Mui-focused': isSelectAll },
                )}
                sx={{
                  color: '#001F38',
                  '&:hover': {
                    backgroundColor: 'transparent',
                    color: '#07B9EC',
                    '& .COS-Unchecked-box': {
                      backgroundColor: alpha('#07B9EC', 1),
                      borderColor: alpha('#07B9EC', 0.5),
                    },
                    '& .COS-checked-box': {
                      backgroundColor: alpha('#07B9EC', 0.75),
                      color: alpha('#07B9EC', 0.75),
                    },
                  },
                  '&.Mui-focused': {
                    color: '#07B9EC',
                  },
                }}
                onMouseDown={event => event.preventDefault()}
                onMouseEnter={handleMouseEnter}
                onMouseLeave={handleMouseLeave}
                onClick={onSelectAll}
                aria-selected={isSelectAll}
              >
                <CosCheckbox style={{ marginRight: 5 }} checked={isSelectAll} />
                <p className="m-0 inline-flex min-h-[auto] items-center p-0 text-12/10">
                  Select All
                </p>
              </MenuItem>
              <Box className="flex h-[28px] border-0 border-t-[0.5px] border-solid border-artist-blue-900/50">
                <CosButton
                  variant="text"
                  size="small"
                  color={isInitialState ? 'info' : 'secondary'}
                  className="min-h-full w-1/2"
                  onClick={() => (isInitialState ? onClose() : handleCancel())}
                  onMouseDown={event => event.preventDefault()}
                >
                  {isInitialState ? 'Close' : 'Cancel'}
                </CosButton>

                <Divider
                  className="opacity-1 bg-artist-blue-900/50"
                  flexItem
                  orientation="vertical"
                />
                <CosButton
                  variant="text"
                  size="small"
                  className="min-h-full w-1/2"
                  disabled={isInitialState}
                  onClick={handleApply}
                  onMouseDown={event => event.preventDefault()}
                >
                  Apply
                </CosButton>
              </Box>
            </>
          )}
        </>
      </Popper>
    );
  },
);

export default CosAutoCompleteDropdown;
