import { useState, useEffect } from 'react';
import { Navigate } from 'react-router-dom';
import { localStorageKey } from '../../libs/constant';
import { getLocalStorageItem } from '../../libs/utils';

const ProtectedRouter: React.FC<{ children: React.ReactNode }> = ({
  children,
}) => {
  const [isAuthenticated, setIsAuthenticated] = useState<boolean>(true);

  useEffect(() => {
    const userData = getLocalStorageItem(localStorageKey.USER_DATA);
    if (userData) {
      setIsAuthenticated(true);
    } else {
      setIsAuthenticated(false);
    }
  }, []);

  return isAuthenticated ? (
    <>{children}</>
  ) : (
    <>
      <Navigate to="/login" />
    </>
  );
};

export default ProtectedRouter;
