import { Box, Radio, RadioProps, alpha, styled } from '@mui/material';
import CosLabel, { CosLabelProps } from './CosLabel';

import clsx from 'clsx';
import { CosTheme } from '../../../libs/interface';
import { cn } from '../../../libs/utils';

const size = 11;

interface IconProps {
  version?: CosTheme;
  withGrayBg?: boolean;
}

const RadioIcon = styled('span', {
  shouldForwardProp: prop => prop !== 'version' && prop !== 'withGrayBg',
})<IconProps>(({ version, withGrayBg }) => {
  const color = version === 'light' ? '#07B9EC' : '#001F38';

  return {
    width: size,
    height: size,
    border: '1px solid',
    borderRadius: '50px',
    borderColor:
      version === 'light'
        ? withGrayBg
          ? alpha('#001F38', 0.5)
          : alpha('#07B9EC', 0.5)
        : alpha('#ffffff', 0.5),
    'input:hover ~ &, input:focus ~ &': {
      backgroundColor: alpha(color, 1),
      borderColor: alpha(color, 0.5),
    },
    'input:disabled ~ &': {
      boxShadow: 'none',
      opacity: '0.25',
    },
  };
});

const RadioCheckedIcon = styled(RadioIcon, {
  shouldForwardProp: prop => prop !== 'version',
})<IconProps>(({ version }) => {
  const color = version === 'light' ? '#07B9EC' : '#001F38';
  return {
    backgroundColor: color,
    borderColor: alpha(color, 0.5),
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    '&::before': {
      content: '""',
      display: 'block',
      width: ' 5px',
      height: '5px',
      background: 'white',
      borderRadius: '50px',
    },
    'input:hover ~ &, input:focus ~ &': {
      backgroundColor: alpha(color, 0.75),
    },
  };
});

export interface CosRadioButtonProps extends Omit<RadioProps, 'size'> {
  label?: React.ReactNode | string;
  isRequired?: boolean;
  labelProps?: Omit<CosLabelProps, 'children'>;
  error?: boolean;
  version?: CosTheme;
  withGrayBg?: boolean;
}

const CosRadioBox = (props: CosRadioButtonProps) => {
  const {
    label,
    error,
    className,
    isRequired,
    labelProps,
    version = 'light',
    withGrayBg,
    sx,
    inputProps,
    ...rest
  } = props;
  return (
    <>
      <Box
        className="flex items-center gap-[5px]"
        sx={{
          '& .Mui-checked + div > label': {
            pointerEvents: 'none',
          },
        }}
      >
        <Radio
          className={clsx('p-0', className)}
          checkedIcon={<RadioCheckedIcon version={version} />}
          icon={<RadioIcon version={version} withGrayBg={withGrayBg} />}
          color="secondary"
          sx={{
            '&.Mui-checked': {
              pointerEvents: 'none',
            },
            ...sx,
          }}
          inputProps={{
            tabIndex: 0,
            ...inputProps,
          }}
          {...rest}
        />
        {label && (
          <CosLabel
            isRequired={isRequired}
            {...labelProps}
            className={cn('cursor-pointer', labelProps?.className)}
            htmlFor={rest?.id}
          >
            {label}
          </CosLabel>
        )}
      </Box>
    </>
  );
};
export default CosRadioBox;
