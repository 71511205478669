import { configureStore } from '@reduxjs/toolkit';
import { setupListeners } from '@reduxjs/toolkit/query';
import { companyTypeAPI } from '../services/common';

export const store = configureStore({
  reducer: {
    //* Add the generated reducer as a specific top-level slice
    [companyTypeAPI.reducerPath]: companyTypeAPI.reducer,
  },
  //* Adding the api middleware enables caching, invalidation, polling,
  //* and other useful features of `rtk-query`.
  middleware: getDefaultMiddleware =>
    getDefaultMiddleware().concat(companyTypeAPI.middleware),
  // devTools: process.env.NODE_ENV !== 'production',
});

//* optional, but required for refetchOnFocus/refetchOnReconnect behaviors
//* see `setupListeners` docs - takes an optional callback as the 2nd arg for customization
setupListeners(store.dispatch);
