import { Box } from '@mui/material';
import CosSwitch from '../components/formikForms/CosSwitch';
import StandardLayout from './StandardLayout';

const StandardSwitchPage = () => (
  <StandardLayout>
    <div className="flex gap-28">
      <div className="space-y-10">
        <div className="space-x-4">
          <p>Normal</p>

          <CosSwitch switchType="popup" defaultChecked={false} />
          <CosSwitch switchType="popup" defaultChecked={true} />
        </div>

        <div className="space-x-4">
          <p>Disable</p>
          <CosSwitch switchType="popup" defaultChecked={false} disabled />
          <CosSwitch switchType="popup" defaultChecked={true} disabled />
        </div>

        <div className="space-x-4">
          <p>Dark</p>
          <div className="inline bg-dark-blue-900 p-2">
            <CosSwitch
              switchType="popup"
              defaultChecked={false}
              version="dark"
            />
          </div>
          <div className="inline bg-dark-blue-900 p-2">
            <CosSwitch
              switchType="popup"
              defaultChecked={true}
              version="dark"
            />
          </div>
        </div>
      </div>

      <div className="space-y-10">
        <div className="space-x-4"></div>
        <div>
          <p className="mb-0">Light</p>
          <div className="space-x-4 bg-white-whale-500 p-2">
            <CosSwitch defaultChecked={false} />
            <CosSwitch defaultChecked={true} />
          </div>
        </div>
        <div>
          <p className="mb-0">Blue</p>
          <div className="space-x-4 bg-artist-blue-900 p-2">
            <CosSwitch defaultChecked={false} version="dark" />
            <CosSwitch defaultChecked={true} version="dark" />
          </div>
        </div>
      </div>
    </div>
  </StandardLayout>
);
export default StandardSwitchPage;
