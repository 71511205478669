import React, { useState } from 'react';
import { cn } from '../libs/utils';
import { Box, BoxProps } from '@mui/material';

interface CustomIconProps extends BoxProps {
  onHoverIcon?: React.ReactNode;
  wrapperClass?: string;
  open?: any;
  children?: any;
  onClick?: () => void;
  disabled?: boolean;
}
const CosIcon: React.FC<CustomIconProps> = ({
  onHoverIcon,
  children,
  wrapperClass,
  onClick,
  disabled,
  ...rest
}) => {
  const [isHovered, setIsHovered] = useState(false);

  const handleMouseEnter = () => {
    setIsHovered(true);
  };

  const handleMouseLeave = () => {
    setIsHovered(false);
  };

  return (
    <Box
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
      onClick={onClick}
      role="button"
      className={cn('cursor-pointer ', wrapperClass, {
        'pointer-events-none opacity-25': disabled,
      })}
      {...rest}
    >
      {isHovered && onHoverIcon ? onHoverIcon : children}
    </Box>
  );
};

export default CosIcon;
