export const weekOptions = [
  {
    label: 'Sunday',
    value: 'Sunday',
  },
  {
    label: 'Monday',
    value: 'Monday',
  },
  {
    label: 'Tuesday',
    value: 'Tuesday',
  },
  {
    label: 'Wednesday',
    value: 'Wednesday',
  },
  {
    label: 'Thursday',
    value: 'Thursday',
  },
  {
    label: 'Friday',
    value: 'Friday',
  },
  {
    label: 'Saturday',
    value: 'Saturday',
  },
];

export const deployments = [
  {
    label: 'BuilderOps',
    value: 'BuilderOps',
  },
  {
    label: 'CommrcialOPs',
    value: 'CommrcialOPs',
  },
  {
    label: 'DisastrOps',
    value: 'DisastrOps',
  },
];

export const TimeOptions = [
  {
    label: '06:00 AM',
    value: '06:00 AM',
  },
  {
    label: '07:00 AM',
    value: '07:00 AM',
  },
  {
    label: '08:00 AM',
    value: '08:00 AM',
  },
  {
    label: '09:00 AM',
    value: '09:00 AM',
  },
  {
    label: '10:00 AM',
    value: '10:00 AM',
  },
  {
    label: '11:00 AM',
    value: '11:00 AM',
  },
];

export const HourOptions = [
  {
    label: '08 Hours',
    value: '08 Hours',
  },
  {
    label: '09 Hours',
    value: '09 Hours',
  },
  {
    label: '10 Hours',
    value: '10 Hours',
  },
  {
    label: '11 Hours',
    value: '11 Hours',
  },
];

export const BreakOptions = [
  {
    label: 'N/A',
    value: 'N/A',
  },
  {
    label: '00 Hours : 45 Minutes',
    value: '00 Hours : 45 Minutes',
  },
  {
    label: '01 Hours : 00 Minutes',
    value: '01 Hours : 00 Minutes',
  },
  {
    label: '01 Hours : 30 Minutes',
    value: '01 Hours : 30 Minutes',
  },
  {
    label: '00 Hours : 45 Minutes',
    value: '00 Hours : 45 Minutes',
  },
  {
    label: '01 Hours : 00 Minutes',
    value: '01 Hours : 00 Minutes',
  },
];
