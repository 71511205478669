import { alpha, styled } from '@mui/material';
import MuiAppBar, { AppBarProps as MuiAppBarProps } from '@mui/material/AppBar';
import { constant } from '../../../../libs/constant';

interface AppBarProps extends MuiAppBarProps {
  open?: boolean;
}

const AppBar = styled(MuiAppBar, {
  shouldForwardProp: prop => prop !== 'open',
})<AppBarProps>(({ theme, open }) => ({
  padding: 0,
  zIndex: theme.zIndex.drawer + 1,
  transition: theme.transitions.create(['width', 'margin'], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  boxShadow: `0 0 3px ${alpha('#A8B1B8', 0.5)}`,
  ...(open && {
    marginLeft: constant.drawerWidth,
    // width: `calc(100% - ${constant.drawerWidth}px)`,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  }),
  '&': {
    height: constant.appBarHeight + 'px',
    backgroundColor: constant.masterLayoutNavbarColor,
  },
}));

export default AppBar;
