import { CosInput, CosTextArea } from '../components/formikForms/ui';
import StandardLayout from './StandardLayout';

const StandardTextField = () => {
  return (
    <StandardLayout>
      <h5 className="text-center">Input Text</h5>
      <CosInput
        placeholder="Enter Company Name"
        label="Company Name"
        size="small"
      />

      <h5 className="text-center">Expand Input Text</h5>
      <CosInput
        placeholder="Enter Company Name"
        label="Company Name"
        size="small"
      />
      <br />

      <h5 className="text-center">TextArea</h5>
      <CosTextArea placeholder="Enter Street (Line 2)" />
      <h5 className="text-center">Popup Input Text</h5>
      <CosInput
        placeholder="Enter Address Name"
        size="small"
        isPopupInput={true}
      />
      <h5 className="text-center">Popup TextArea</h5>
      <CosTextArea placeholder="Enter Street (Line 2)" isPopupInput={true} />

      <h5 className="text-center">Small TextArea with </h5>

      <CosTextArea
        placeholder="Enter Street (Line 2)"
        size="small"
        isPopupInput={true}
        isExpandIcon
      />
    </StandardLayout>
  );
};

export default StandardTextField;
