import React from 'react';
import { CosAutoComplete, CosInput } from '../components/formikForms/ui';
import StandardLayout from './StandardLayout';
import { CosOptions } from '../libs/interface';
import { CosTimeInput } from '../components/time';
import { TimeOptions } from '../modules/schedule/ScheduleOption';

const optionWithMoreItem: CosOptions[] = [
  {
    label: 'Business Checking',
    value: 'Business Checking',
  },
  {
    label: 'Business Savings',
    value: 'Business Savings',
  },
  {
    label: 'Link',
    value: 'Link',
  },
  {
    label: 'Departments',
    value: 'Departments',
  },
  {
    label: 'Resource',
    value: 'Resource',
  },
  {
    label: 'Work',
    value: 'Work',
  },
  {
    label: 'Schedules',
    value: 'Schedules',
  },
  {
    label: 'Positions',
    value: 'Positions',
  },
];

const option: CosOptions[] = [
  {
    label: 'Business Checking',
    value: 'Business Checking',
  },
  {
    label: 'Business Savings',
    value: 'Business Savings',
  },
  {
    label: 'Link',
    value: 'Link',
  },
  {
    label: 'TBD-1',
    value: 'TBD-1',
  },
  {
    label: 'TBD-2',
    value: 'TBD-2',
  },
  {
    label: 'TBD',
    value: 'TBD-3',
  },
];

const multiOption: CosOptions[] = [
  {
    label: 'Sunday',
    value: 'Sunday',
  },
  {
    label: 'Monday',
    value: 'Monday',
  },
  {
    label: 'Tuesday',
    value: 'Tuesday',
  },
  {
    label: 'Wednesday',
    value: 'Wednesday',
  },
  {
    label: 'Thursday',
    value: 'Thursday',
  },
  {
    label: 'Friday',
    value: 'Friday',
  },
  {
    label: 'Saturday',
    value: 'Saturday',
  },
];

const StandardDropdownPage = () => {
  const labelProps = {
    className: 'text-white',
  };

  return (
    <StandardLayout>
      <div className="bg-white-whale-500 p-2">
        <div className="space-x-4">
          <p className="mt-0">Dark</p>
          <div className="h-[250px] bg-dark-blue-900 p-2">
            <p className="m-0 my-3 text-white">In Popup</p>
            <div className="flex  gap-5">
              <CosAutoComplete
                isAddNew={true}
                addNewProps={
                  <CosTimeInput className="w-[270px] !justify-start pl-[10px]" />
                }
                options={TimeOptions}
                placeholder="Select Start Time"
                size="small"
                label="Time Day Starts"
                labelProps={labelProps}
                withElevation={false}
                isPopupInput={true}
              />
              <CosAutoComplete
                options={option}
                placeholder="Select Account Type"
                size="small"
                // className="w-smallInput"
                label="Account Type"
                labelProps={labelProps}
                withElevation={false}
                isPopupInput={true}
              />

              <CosAutoComplete
                multiple
                options={multiOption}
                size="small"
                label="Week Day"
                placeholder="Check All that Apply"
                labelProps={labelProps}
                withElevation={false}
                isPopupInput={true}
                // open
              />
            </div>

            <p className="m-0 my-3 text-white">In Page</p>
            <div className="flex  gap-5">
              <CosAutoComplete
                isAddNew={true}
                addNewProps={
                  <CosTimeInput className="w-[270px] !justify-start pl-[10px]" />
                }
                options={TimeOptions}
                placeholder="Select Start Time"
                size="small"
                label="Time Day Starts"
                labelProps={labelProps}
                arrowType="expand"
              />
              <CosAutoComplete
                options={option}
                placeholder="Select Account Type"
                size="small"
                // className="w-smallInput"
                label="Account Type"
                labelProps={labelProps}
                withElevation={false}
                arrowType="expand"
              />
            </div>
          </div>
        </div>

        <div className="space-x-4">
          <p>Light</p>
          <div className="h-[250px] bg-white p-2">
            <p className="m-0 my-3">In Popup</p>
            <div className="flex  gap-5">
              <CosAutoComplete
                isAddNew={true}
                addNewProps={
                  <CosTimeInput className="w-[270px] !justify-start pl-[10px]" />
                }
                options={TimeOptions}
                placeholder="Select Start Time"
                size="small"
                label="Time Day Starts"
                isPopupInput={true}
              />
              <CosAutoComplete
                options={option}
                placeholder="Select Account Type"
                size="small"
                // className="w-smallInput"
                label="Account Type"
                isPopupInput={true}
              />
            </div>
            <p className="m-0 my-3">In page</p>
            <div className="flex gap-5">
              <CosAutoComplete
                isAddNew={true}
                addNewProps={
                  <CosTimeInput className="w-[270px] !justify-start pl-[10px]" />
                }
                options={TimeOptions}
                placeholder="Select Start Time"
                size="small"
                label="Time Day Starts"
                arrowType="expand"
              />
              <CosAutoComplete
                options={option}
                placeholder="Select Account Type"
                size="small"
                // className="w-smallInput"
                label="Account Type"
                arrowType="expand"
              />
            </div>
          </div>
        </div>
      </div>
    </StandardLayout>
  );
};

export default StandardDropdownPage;
