import { useRef, useState } from 'react';
import { apiUrls } from '../../../../apiUrl';
import Rest from '../../../../Rest';
import { useNavigate } from 'react-router-dom';
import { FormikValues } from 'formik';

const useOTPVerification = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState('');
  const [isValid, setIsValid] = useState(false);
  const [otp, setOtp] = useState('');
  const btnRef = useRef<HTMLButtonElement | null>(null);
  const navigate = useNavigate();

  const handleChange = (newValue: string) => {
    setIsValid(false);
    setOtp(newValue);
  };

  const handleCompleted = () => {
    setIsValid(true);
    setTimeout(() => {
      btnRef.current?.focus();
    }, 0);
  };

  const handleSubmit = async (formValue: FormikValues) => {
    setIsLoading(true);
    await Rest.post(apiUrls.OTP_VERIFY, {
      email: formValue.email,
      otp,
    })
      .then(() => {
        setIsLoading(false);
        navigate('/signup', { state: { formValue } });
      })
      .catch(({ response }) => {
        const errorMessage = response?.data?.data?.message;
        setError(errorMessage);
        setIsLoading(false);
      });
  };

  const handleResendCode = async (emailValue: string) => {
    await Rest.post(apiUrls.RESEND_OTP, { email: emailValue })
      .then()
      .catch(error => console.error(error));
  };

  return {
    isLoading,
    handleChange,
    handleSubmit,
    otp,
    handleCompleted,
    isValid,
    error,
    handleResendCode,
    btnRef,
  };
};

export default useOTPVerification;
