import React from 'react';
import Masterlayout from '../layout/masterlayout/Masterlayout';
import StandardDashboard from './StandardDashboard';
import { Outlet, RouteObject } from 'react-router';
import comingSoonCompanyHandler from './routerHandler/comingSoonCompanyHandler';

const ComingSoonCompany = () => {
  return (
    <StandardDashboard navigatorList={comingSoonCompanyHandler}>
      <Outlet />
    </StandardDashboard>
  );
};

export default ComingSoonCompany;
