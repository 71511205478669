import React from 'react';
import { Box } from '@mui/material';
import { CosBaseDialogProps } from './CosBasDialog';
import { BuilderOps, ImportComplete } from '../../assets/svgs';

interface Props extends CosBaseDialogProps {
  children?: React.ReactNode;
}

const CosImportComplete: React.FC<Props> = ({ children }) => {
  return (
    <>
      <div className="mb-5 ml-1 flex justify-center pt-[32px]">
        <div className="h-[94px] w-[94px] rounded-full bg-[#091F38]">
          <ImportComplete />
        </div>
      </div>
      <Box className="mx-[58px] flex text-center text-18/24 font-semibold leading-5 text-buddha-love-800">
        <BuilderOps className="mr-[5px]" />
        <span> Import complete</span>
      </Box>
    </>
  );
};

export default CosImportComplete;
