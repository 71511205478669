import {
  FieldArray,
  Form,
  Formik,
  FormikProps,
  useFormikContext,
} from 'formik';
import React from 'react';
import { CosButton } from '../../../components';
import { Divider } from '@mui/material';
import {
  CosBasDialog,
  PrimaryActionButtons,
} from '../../../components/dialogBox';
import AddButton from '../../../components/buttons/AddButton';
import { FormAutoComplete, FormInput } from '../../../components/formikForms';
import {
  PhoneData,
  phoneInitialValues,
  phoneSchema,
  PhoneTypeItems,
} from '../formConfig/profileForm';
import * as Yup from 'yup';

export const addNewPhonesSchema = Yup.object().shape({
  phones: Yup.array().of(phoneSchema).min(1).required(''),
});

export const addNewPhoneInitialValues = {
  phones: [{ ...phoneInitialValues }],
};

interface Props {
  open: boolean;
  onClose?: () => void;
  onApply?: (value: PhoneData[]) => void;
}

const commonSx = {
  minWidth: 'auto',
};

const AddNewPhone: React.FC<Props> = ({ open, onClose, onApply }) => {
  const name = 'phones';
  const { values }: any = useFormikContext();
  const handleSubmit = (values: any) => {
    onClose?.();
    onApply?.(values[name]);
  };

  const handleClose = () => {
    onClose?.();
  };

  const handleCancel = (
    dirty: boolean,
    resetForm: FormikProps<any>['resetForm'],
  ) => {
    if (dirty) {
      resetForm();
    }
  };

  const handlePhoneInput = (
    event: React.ChangeEvent<HTMLInputElement>,
    index: number,
    type: 'phoneNumber' | 'phoneExt',
    setFieldValue: Function,
  ) => {
    const { value } = event.target;
    let formattedValue = value.replace(/\D/g, ''); // Remove all non-digit characters

    if (type === 'phoneNumber') {
      if (formattedValue.length > 10) {
        formattedValue = formattedValue.slice(0, 10); // Limit to 10 digits
      }

      // Format the phone number as (123) 123-1234
      if (formattedValue.length > 6) {
        formattedValue = `(${formattedValue.slice(0, 3)}) ${formattedValue.slice(3, 6)} - ${formattedValue.slice(6)}`;
      } else if (formattedValue.length > 3) {
        formattedValue = `(${formattedValue.slice(0, 3)}) ${formattedValue.slice(3)}`;
      } else if (formattedValue.length > 0) {
        formattedValue = `(${formattedValue}`;
      }

      setFieldValue(`${name}.${index}.${type}`, formattedValue);
    } else {
      // Handle phone extension if necessary
      if (formattedValue.length > 3) {
        formattedValue = formattedValue.slice(0, 3); // Limit to 3 digits for phone extension
      }

      setFieldValue(`${name}.${index}.${type}`, formattedValue);
    }
  };

  return open ? (
    <Formik
      initialValues={addNewPhoneInitialValues}
      validationSchema={addNewPhonesSchema}
      onSubmit={handleSubmit}
    >
      {({ values, setFieldValue, dirty, isValid, resetForm }: any) => {
        return (
          <CosBasDialog
            open={open}
            sx={{
              '.MuiPaper-root': {
                background: '#EDEEEF',
              },
            }}
            onEsc={() => {
              dirty ? handleCancel(dirty, resetForm) : handleClose();
            }}
            onEnter={() => handleSubmit(values)}
            height="250px"
            width="365px"
            heading={'New Phone'}
            headingClass={
              'text-left text-18/18 text-blue-gray-900 bg-white-whale-500 px-[15px] py-[10px]'
            }
            bodyClass={'bg-white-whale-500 p-0 m-0'}
            actionClass="flex h-[44px] border-0 border-t-[0.5px] border-solid border-artist-blue-900 p-0 bg-white overflow-x-hidden"
            dialogActions={
              <PrimaryActionButtons
                showCancel={dirty}
                onCancel={() => handleCancel(dirty, resetForm)}
                onClose={() => handleClose()}
                disableApply={!(dirty && isValid)}
                onApply={() => handleSubmit(values)}
              />
            }
          >
            <Form>
              <FieldArray name={name}>
                {({ remove, push }) => (
                  <div className="w-full px-[15px] pt-[2px]">
                    {values[name].length > 0 &&
                      values[name].map((phone: PhoneData, index: number) => (
                        <div
                          key={`${index}-addNewPhonePopup`}
                          className="flex w-full flex-col gap-[5px] pt-[2px]"
                        >
                          <FormAutoComplete
                            name={`${name}.${index}.phoneType`}
                            options={PhoneTypeItems}
                            placeholder="Select Account Type"
                            size="small"
                            className="!mb-0 rounded-[3px] shadow-3b"
                            withElevation={false}
                            isPopupInput={true}
                            fullWidth
                            onChange={(event, value) => {
                              setFieldValue(
                                `${name}.${index}.phoneType`,
                                value?.value,
                              );
                            }}
                          />
                          <div className="flex items-center gap-[4.5px]">
                            <FormInput
                              name={`${name}.${index}.countryCode`}
                              size="small"
                              className="!mb-0 w-[33px]"
                              readOnly={true}
                              inputSx={{
                                ...commonSx,
                                padding: '0px 5px',
                                textAlign: 'center',
                              }}
                              disabled={
                                values.phones[index].phoneType ? false : true
                              }
                            />
                            <FormInput
                              name={`${name}.${index}.phoneNumber`}
                              placeholder={
                                values.phones[index].phoneType
                                  ? `Enter ${values.phones[index].phoneType ? values.phones[index].phoneType : ''} Phone`
                                  : 'Enter Phone'
                              }
                              size="small"
                              className="!mb-0"
                              inputSx={{
                                ...commonSx,
                                width: '215px',
                              }}
                              isPopupInput={true}
                              disabled={
                                values.phones[index].phoneType ? false : true
                              }
                              onChange={(event: any) =>
                                handlePhoneInput(
                                  event,
                                  index,
                                  'phoneNumber',
                                  setFieldValue,
                                )
                              }
                            />
                            <span className="text-blue-gray-100">-</span>
                            <FormInput
                              name={`${name}.${index}.phoneExt`}
                              placeholder="Ext."
                              type="number"
                              size="small"
                              className="!mb-0 w-[67px]"
                              inputSx={{
                                ...commonSx,
                                textAlign: 'center',
                              }}
                              isPopupInput={true}
                              disabled={
                                values.phones[index].phoneType ? false : true
                              }
                              onChange={(event: any) =>
                                handlePhoneInput(
                                  event,
                                  index,
                                  'phoneExt',
                                  setFieldValue,
                                )
                              }
                            />
                          </div>
                          {values[name].length !== index + 1 && (
                            <Divider className="my-[10px]" />
                          )}
                        </div>
                      ))}
                    <AddButton
                      onClick={() => push(phoneInitialValues)}
                      className="mb-1 mt-[4px]"
                      toolTipText={'Add Phone'}
                    />
                  </div>
                )}
              </FieldArray>
            </Form>
          </CosBasDialog>
        );
      }}
    </Formik>
  ) : null;
};

export default AddNewPhone;
