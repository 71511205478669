import { useState } from 'react';
import { CosDataView } from '../../components/dialogBox';
import { CosButton } from '../../components';
import { Divider } from '@mui/material';
import { Formik } from 'formik';
import { CosAutoComplete, CosInput } from '../../components/formikForms/ui';
import { CosOptions } from '../../libs/interface';
import { AddHoursField, CosTimeInput } from '../../components/time';
import {
  deployments,
  weekOptions,
  TimeOptions,
  HourOptions,
  BreakOptions,
} from './ScheduleOption';
import CosPrimaryNumberField from '../../components/formikForms/ui/numberField/CosPrimaryNumberField';

const dataViewAction = ['Close', 'Preview', 'Create'];
const dataHeading = 'Create New Work Schedule';

const multiOption: CosOptions[] = weekOptions;
const deploymentOption: CosOptions[] = deployments;
const TimeOption: CosOptions[] = TimeOptions;
const HourOption: CosOptions[] = HourOptions;
const BreakOption: CosOptions[] = BreakOptions;

const CreateNewSchedule = () => {
  const [isCreate, setCreate] = useState(true);

  const handleClose = () => {
    setCreate(false);
  };

  const labelProps = {
    className: 'mr-[10px] text-dark-blue-900 text-14/18 opacity-60',
  };

  return (
    <CosDataView
      open={isCreate}
      modalWidth="580px"
      modalHeight="494px"
      heading={dataHeading}
      isPagination={false}
      dialogAction={
        <>
          <CosButton
            size="medium"
            variant="text"
            onClick={handleClose}
            className="min-h-auto m-0"
          >
            {dataViewAction ? dataViewAction[0] : 'Close'}
          </CosButton>
          <Divider
            className="opacity-1 m-0 bg-artist-blue-900"
            flexItem
            orientation="vertical"
          ></Divider>
          <div className="relative w-1/2">
            <div className="absolute -bottom-3 right-0 w-[120px]">
              <CosButton
                size="medium"
                variant="text"
                onClick={handleClose}
                className="min-h-auto m-0 "
              >
                {dataViewAction ? dataViewAction[1] : 'Preview'}
              </CosButton>
            </div>
          </div>
          <Divider
            className="opacity-1 m-0 bg-artist-blue-900"
            flexItem
            orientation="vertical"
          ></Divider>
          <CosButton
            size="medium"
            variant="text"
            onClick={handleClose}
            className="min-h-auto m-0"
          >
            {dataViewAction ? dataViewAction[2] : 'Create'}
          </CosButton>
        </>
      }
    >
      {/* <Formik initialValues={initialValues} onSubmit={handleSubmit}> */}
      <div className="relative">
        <div className="form-group">
          <CosInput
            label="Work Schedule"
            name="scheduleName"
            // value={userDetails?.companyName}
            placeholder="Work Schedule Name"
            isRequired={false}
            labelProps={labelProps}
            className="flex"
            inputSx={{
              minWidth: '443px',
              width: '443px',
              height: '28px',
              boxShadow: '0px 0px 3px #A8B1B8',
              border: 'unset',
              textTransform: 'capitalize',
            }}
            disabled
          />
        </div>
        <div className="form-group flex justify-between">
          <CosAutoComplete
            multiple
            options={multiOption}
            placeholder="Check all that Apply"
            size="small"
            className="flex w-smallInput"
            label="Working Days"
            labelProps={labelProps}
            withElevation={false}
            isRequired={false}
            inputSx={{
              boxShadow: '0px 0px 3px #A8B1B8',
            }}
          />
          <CosInput
            label="No. of Days"
            name="noOfDays"
            // value={userDetails?.companyName}
            isRequired={false}
            labelProps={labelProps}
            className="flex"
            disabled
            inputSx={{
              minWidth: '70px',
              width: '70px',
              height: '28px',
              boxShadow: '0px 0px 3px #A8B1B8',
              border: 'unset',
              textTransform: 'capitalize',
            }}
          />
        </div>
        <div className="form-group">
          <CosAutoComplete
            options={multiOption}
            placeholder="Select Start Day"
            size="small"
            className="flex w-smallInput"
            label="Day Week Starts"
            labelProps={labelProps}
            withElevation={false}
            isRequired={false}
            inputSx={{
              boxShadow: '0px 0px 3px #A8B1B8',
            }}
          />
        </div>
        <div className="form-group">
          <CosAutoComplete
            isAddNew={true}
            addNewProps={
              <CosTimeInput className="w-[270px] !justify-start pl-[10px]" />
            }
            options={TimeOption}
            placeholder="Select Start Time"
            size="small"
            className="flex w-smallInput"
            label="Time Day Starts"
            labelProps={labelProps}
            withElevation={false}
            isRequired={false}
            inputSx={{
              boxShadow: '0px 0px 3px #A8B1B8',
            }}
          />
        </div>
        <div className="form-group">
          <CosAutoComplete
            isAddNew={true}
            addNewProps={
              <div
                className="flex w-[270px] !justify-start !py-[7px] pl-[10px]"
                style={{ boxShadow: '0px 0px 3px #A8B1B8' }}
              >
                <CosPrimaryNumberField />
                <p className="m-0 flex items-center pl-2 text-14/18 font-semibold text-dark-blue-900">
                  Hours
                </p>
              </div>
            }
            options={HourOption}
            placeholder="Select Work Hours"
            size="small"
            className="flex w-smallInput"
            label="Hours Working"
            labelProps={labelProps}
            withElevation={false}
            isRequired={false}
            inputSx={{
              boxShadow: '0px 0px 3px #A8B1B8',
            }}
          />
        </div>
        <div className="form-group">
          <CosAutoComplete
            isAddNew={true}
            addNewProps={
              <AddHoursField className="w-[270px] !justify-start pl-[10px]" />
            }
            options={BreakOption}
            placeholder="Select Break Time"
            size="small"
            className="flex w-smallInput"
            label="Break"
            labelProps={labelProps}
            withElevation={false}
            isRequired={false}
            inputSx={{
              boxShadow: '0px 0px 3px #A8B1B8',
            }}
          />
        </div>
        <Divider className="main-divider mb-4 mt-[60px]"></Divider>
        <div className="form-group pb-15px">
          <CosAutoComplete
            multiple
            options={deploymentOption}
            placeholder="Select"
            size="small"
            className="flex w-smallInput"
            label="Deployments"
            labelProps={labelProps}
            withElevation={false}
            isRequired={false}
            inputSx={{
              boxShadow: '0px 0px 3px #A8B1B8',
            }}
          />
        </div>
      </div>
      {/* </Formik> */}
    </CosDataView>
  );
};

export default CreateNewSchedule;
