import React, { useRef, useState } from 'react';
import { Tab, Tabs, alpha, styled, TabScrollButton } from '@mui/material';
import { handleScrollButtonClick } from './tab.util';

const StyledBadge = styled('div')(({ theme }) => ({
  background: '#001F38',
  color: '#FFFFFF',
  fontSize: '10px',
  lineHeight: '16px',
  borderRadius: 99,
  width: 16,
  height: 16,
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
}));

const BpTab = styled(Tabs)(({}) => ({
  '&.MuiTabs-root': {
    maxWidth: '1202px',
    minHeight: 'auto',
    height: '29px',
  },
  '& .MuiTabs-scrollButtons.Mui-disabled': {
    opacity: 0.25,
  },
  '& .MuiTab-root': {
    minHeight: '21px',
    minWidth: '190px',
    textAlign: 'center',
    background: '#EDEEEF',
    boxShadow: '0px 3px 6px #00000029',
    borderRadius: '0px 0px 5px 5px',
    border: `0.25px solid ${alpha('#51B7E7', 0.25)}`,
    fontSize: '11px',
    lineHeight: '14px',
    color: '#001F38',
    textTransform: 'capitalize',
    padding: 0,
    marginRight: '5px',
    opacity: 1,
  },
  '& .MuiTabs-scrollButtons': {
    color: '#FFFFFF',
    opacity: 1,
    width: '18px',
    height: 21,
  },
  '& .MuiTabs-indicator': {
    display: 'none',
  },
}));

interface HeaderTab extends CosTab {
  badge?: number;
}

interface Props {
  tabs: HeaderTab[];
}

const CosTopHeaderMenu: React.FC<Props> = ({ tabs, ...rest }) => {
  const [value, setValue] = useState(0);

  const handleChange = (event: any, newValue: number) => {
    setValue(newValue);
  };

  const tabsRef = useRef<HTMLDivElement>(null);
  return (
    <BpTab
      value={value}
      onChange={handleChange}
      variant="scrollable"
      selectionFollowsFocus={true}
      {...rest}
      ScrollButtonComponent={({ ...rest }) => {
        return (
          <TabScrollButton
            {...rest}
            onClick={() =>
              handleScrollButtonClick(tabsRef.current, rest.direction)
            }
          />
        );
      }}
      ref={tabsRef}
    >
      {tabs.map((item: HeaderTab, index: number) => (
        <Tab
          label={
            <div className="flex items-center justify-center gap-[3.5px]">
              <span className="inline-block">{item.label}</span>
              {item.badge && <StyledBadge>{item.badge}</StyledBadge>}
            </div>
          }
          key={`${index}-${item.label}-header-tab`}
          className="snap-start"
          value={item.value}
        />
      ))}
      ;
    </BpTab>
  );
};

export default CosTopHeaderMenu;
