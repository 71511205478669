import { useState } from 'react';
import { CosButton, CosIcon } from '../../../components';
import { Typography } from '@mui/material';
import { actionLabelProps } from './util';
import { CosRadio } from '../../../components/formikForms/ui';
import UploadLogoModal from './UploadLogoModal';
import { CropImageData } from '../../../components/imageCrop/ViewAndCropImg';
import {
  CloseHover,
  CloseHoverWhiteBG,
  EditWhiteHoverWhiteBG,
  EditWhiteHover,
} from '../../../assets/svgs';
import { cn } from '../../../libs/utils';
import { FormRadio } from '../../../components/formikForms';

const UploadLogoForm = () => {
  const [isUploadLogoOpen, setIsUploadLogoOpen] = useState(false);
  const [showActionBtn, setShowActionBtn] = useState(false);
  const [uploadedImageData, setUploadedImageData] =
    useState<CropImageData | null>(null);

  return (
    <div className="flex h-[257px] w-[162px] flex-col items-center justify-start rounded-[5px] bg-white-whale-500 pb-[9px] pt-[12px]">
      <div
        className="relative flex size-[120px] items-center justify-center rounded-full bg-white p-2 shadow-6b shadow-[#00000029]"
        onMouseEnter={() => setShowActionBtn(true)}
        onMouseLeave={() => setShowActionBtn(false)}
      >
        {uploadedImageData ? (
          <img
            src={uploadedImageData.cropImageBlobUrl}
            className="size-full rounded-full object-contain"
          />
        ) : (
          <span className="text-14/15 text-blue-gray-100">logo</span>
        )}
        {uploadedImageData && (
          <div
            className={cn(
              'base-animation absolute -bottom-1 flex  gap-1 opacity-0 transition-[opacity]',
              {
                'opacity-1': showActionBtn,
              },
            )}
          >
            <CosIcon
              onHoverIcon={<EditWhiteHover className="size-[15px]" />}
              wrapperClass="rounded-full inline-flex border"
              onClick={() => setIsUploadLogoOpen(true)}
            >
              <EditWhiteHoverWhiteBG className="size-[15px]" />
            </CosIcon>
            <CosIcon
              onHoverIcon={<CloseHover className="size-[15px]" />}
              wrapperClass="rounded-full inline-flex"
              onClick={() => setUploadedImageData(null)}
            >
              <CloseHoverWhiteBG className="size-[15px]" />
            </CosIcon>
          </div>
        )}
      </div>
      {!uploadedImageData && (
        <CosButton
          size="medium"
          variant="contained"
          className="mt-[14px]"
          onClick={() => setIsUploadLogoOpen(true)}
        >
          Upload Logo
        </CosButton>
      )}
      <Typography className="mt-[14px] px-2 text-center text-12/17 font-semibold">
        Does your company have a tagline?
      </Typography>

      <div
        className="flex h-[28px] flex-row items-center justify-center gap-[10px]"
        role="radiogroup"
      >
        {/* TODO : use formik for */}
        <FormRadio
          name={'tagLine'}
          value="yes"
          label="Yes"
          labelProps={actionLabelProps}
          tabIndex={0}
          id="tagLine-yes"
        />
        <FormRadio
          name={'tagLine'}
          value="no"
          label="No"
          labelProps={actionLabelProps}
          tabIndex={0}
          id="tagLine-no"
        />
      </div>

      {uploadedImageData && (
        <Typography className="cos-link mt-[9px] text-12/17">
          Get Help With Tag Line
        </Typography>
      )}
      {isUploadLogoOpen && (
        <UploadLogoModal
          open={isUploadLogoOpen}
          onClose={() => setIsUploadLogoOpen(false)}
          onApply={data => setUploadedImageData(data)}
          initialData={uploadedImageData?.initialFileData}
        />
      )}
    </div>
  );
};

export default UploadLogoForm;
