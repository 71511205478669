import {
  contactInformationForm,
  contactInformationSchema,
} from '../formConfig/profileForm';
import AddressSection from './AddressSection';
import { Form, Formik } from 'formik';
import PhoneSection from './PhoneSection';
import EmailSection from './EmailSection';
import { CosButton } from '../../../components';
import { Divider } from '@mui/material';
import { useState } from 'react';
import { EditIcon } from '../../../assets/svgs';

interface Props {
  isActive: boolean;
  onSubmit: (value: any) => void; // TODO : set Type
}

const ContactInformationForm: React.FC<Props> = ({ isActive, onSubmit }) => {
  const [isViewMode, setIsViewMode] = useState(false);

  const handleSubmit = (value: any) => {
    setIsViewMode(true);
    onSubmit(value);
    console.log('value', value);
  };

  const handleViewMode = () => {};

  return (
    <Formik
      initialValues={contactInformationForm}
      validationSchema={contactInformationSchema}
      onSubmit={value => {
        handleSubmit(value);
      }}
    >
      {({ values, errors, setFieldValue, dirty, isValid, ...rest }) => {
        return (
          <Form className="flex h-full flex-col justify-between">
            <div>
              <AddressSection
                name="addresses"
                isViewMode={isViewMode}
                isActive={isActive}
              />
              <PhoneSection
                name="phones"
                isViewMode={isViewMode}
                isActive={isActive}
              />
              <EmailSection
                name="emails"
                isViewMode={isViewMode}
                isActive={isActive}
              />
            </div>
            <div className="my-[6px]">
              <Divider
                className="m-0 w-full border-0 border-b-[1px] border-solid border-blue-gray-200/[0.38] "
                flexItem
                orientation="vertical"
              ></Divider>
              <div className="w-full p-[10px_15px] text-right">
                {isViewMode ? (
                  <CosButton
                    type="button"
                    size="small"
                    variant="outlined"
                    startIcon={<EditIcon />}
                    onClick={() => setIsViewMode(false)}
                  >
                    Edit
                  </CosButton>
                ) : (
                  <CosButton
                    type="button"
                    size="small"
                    disabled={!(dirty && isValid)}
                    onClick={() => {
                      handleSubmit(values);
                    }}
                  >
                    Save
                  </CosButton>
                )}
              </div>
              <Divider
                className="m-0 w-full border-0 border-b-[1px] border-solid border-blue-gray-200/[0.38] "
                flexItem
                orientation="vertical"
              ></Divider>
            </div>
          </Form>
        );
      }}
    </Formik>
  );
};

export default ContactInformationForm;
