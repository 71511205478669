import React, { useState, ChangeEvent } from 'react';
import CosInput, { CosInputProps } from './CosInput';
import { Typography } from '@mui/material';

interface Props {
  max?: number;
  showMask?: boolean;
  onReachMax?: (value: string) => void;
  onClean?: () => void;
  renderMask?: (value: string) => JSX.Element | string;
}

const MaskInput: React.FC<Props & CosInputProps> = ({
  showMask,
  max,
  onReachMax,
  onClean,
  onChange,
  inputSx,
  renderMask,
  ...rest
}) => {
  const [inputValue, setInputValue] = useState<string>('');

  const commonSx = {
    minWidth: 'auto',
    textAlign: 'center',
    padding: 0,
    color: showMask ? '#001F38' : '#ffffff',
    userSelect: showMask ? 'auto' : 'none',
    caretColor: '#001F38',
  };

  const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
    const { value } = event.target;
    if (max && value.length > max) return;

    setInputValue(value);
    onChange?.(event);

    if (value.length === max) {
      onReachMax?.(value);
    }
    if (value === '') {
      onClean?.();
    }
  };

  return (
    <div className="relative">
      <CosInput
        size="small"
        value={inputValue}
        inputSx={Object.assign(commonSx, inputSx)}
        onChange={handleChange}
        {...rest}
      />
      {!showMask && (
        <Typography className="pointer-events-none absolute left-1/2 top-1/2 -translate-x-1/2 -translate-y-1/2 text-14/15">
          {inputValue && (
            <>
              {renderMask
                ? renderMask(inputValue)
                : inputValue
                    .toString()
                    .split('')
                    ?.map((_item: any, index: number) => 'X')}
            </>
          )}
        </Typography>
      )}
    </div>
  );
};

export default MaskInput;
