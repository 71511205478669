import { Outlet } from 'react-router';
import Masterlayout from '../../layout/masterlayout/Masterlayout';
import companyRouterHandler from '../../router/companyRouterHandler';
import { ProtectedRouter } from '../../router/components';

const MyCompany = () => {
  return (
    <ProtectedRouter>
      <Masterlayout navigatorList={companyRouterHandler}>
        <Outlet />
      </Masterlayout>
    </ProtectedRouter>
  );
};

export default MyCompany;
