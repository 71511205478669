import usePagination from '@mui/material/usePagination';
import styled from '@emotion/styled';
import { cn } from '../../libs/utils';
import ParPageSelector from './ParPageSelector';

const List = styled('ul')({
  listStyle: 'none',
  padding: 0,
  margin: 0,
  display: 'flex',
});

const ActionButton = styled('div')({
  '& button': {
    all: 'unset',
    textTransform: 'capitalize',
    fontSize: 12,
    color: '#001F38',
    cursor: 'pointer',
    transition: 'all 0.3s ease',
    '&:disabled': {
      opacity: 0.25,
      pointerEvents: 'none',
    },
    '&:hover, &:focus-within': {
      color: '#07B9EC',
    },
  },
});

const PageNumber = styled('div')({
  color: '#001F38',
  '&:hover, &:focus-within': {
    color: '#07B9EC',
  },
  width: 24,
  height: 24,
  textAlign: 'center',
  '& button': {
    all: 'unset',
    width: 24,
    height: 24,
    borderRadius: 99,
    display: 'inline-flex',
    justifyContent: 'center',
    alignItems: 'center',
    textTransform: 'capitalize',
    fontSize: 12,
    cursor: 'pointer',
    '&:disabled': {
      opacity: 0.25,
      pointerEvents: 'none',
    },
    '&[aria-current="true"]': {
      backgroundColor: '#07B9EC',
      fontWeight: '400 !important',
      color: '#ffffff',
    },
  },
});

// TODO : manage type
const CosTablePagination = ({ count = 10 }: any) => {
  const { items } = usePagination({
    count: count,
  });

  return (
    <nav className="flex justify-between">
      <ParPageSelector />
      <List>
        {items.map(({ page, type, selected, ...item }, index) => {
          let children = null;

          if (type === 'start-ellipsis' || type === 'end-ellipsis') {
            children = '…';
          } else if (type === 'page') {
            children = (
              <button
                type="button"
                style={{
                  fontWeight: selected ? 'bold' : undefined,
                }}
                {...item}
              >
                {page}
              </button>
            );
          } else {
            children = (
              <button type="button" {...item}>
                {type}
              </button>
            );
          }
          return index === 0 || index === items.length - 1 ? (
            <li key={`${index}-pagination`}>
              <ActionButton
                className={cn({
                  'mr-1': index === 0,
                  'ml-1': index === items.length - 1,
                })}
              >
                {children}
              </ActionButton>
            </li>
          ) : (
            <li key={`${index}-pagination`}>
              <PageNumber>{children}</PageNumber>
            </li>
          );
        })}
      </List>
    </nav>
  );
};

export default CosTablePagination;
