import { Avatar, AvatarGroup, Box, List, ListItem, alpha } from '@mui/material';
import Drawer from './ui/Drawer';
import images from '../../../assets/images';
import BuyNowDropDown from './ui/BuyNowDropDown';
import styled from '@emotion/styled';
import {
  Collapsed,
  Company,
  Dashboard,
  Facebook,
  Insta,
  Linkedin,
  Projects,
  Resources,
  Schedules,
  SchedulrOpsPrimarylogoCollapsed,
  Twitter,
  UsersGroup,
} from '../../../assets/svgs';
import settings from '../../../assets/svgs/settings.svg';
import { memo } from 'react';
import clsx from 'clsx';
import { cn, setLocalStorageItem } from '../../../libs/utils';
import { NavLink } from 'react-router-dom';
import { localStorageKey } from '../../../libs/constant';

interface LeftSideComponent {
  className?: string;
}

interface IconComponent {
  isActive?: boolean;
}

export const PerformanceChip = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  minWidth: 72,
  minHeight: 20,
  padding: '3px 6px',
  letterSpacing: '-0.18px',
  fontSize: '10px',
  lineHeight: '14px',
  border: '1px solid #FFFFFF',
  borderRadius: '99px',
  fontWeight: 600,
}));

export interface SidebarNavigationList {
  label: string;
  link: string;
  icon: ({ isActive }: IconComponent) => JSX.Element;
  isDisable?: boolean;
  leftSideComponent?: ({ className }: LeftSideComponent) => JSX.Element;
  badgeValue?: number;
}

const sidebarNavigationList: SidebarNavigationList[] = [
  {
    label: 'My Company',
    link: '/my_company',
    icon: () => <Company />,
    isDisable: false,
    leftSideComponent: ({ className }) => (
      <div
        className={clsx(
          'flex size-7 items-center justify-center rounded-full border border-solid border-error-900',
          className,
        )}
      >
        <span className="text-10/22 text-error-900">20%</span>
      </div>
    ),
  },
  {
    label: 'Users',
    link: '/users',
    icon: () => <UsersGroup />,
    isDisable: false,
    badgeValue: 1,
  },
  {
    label: 'Settings',
    link: '/settings',
    icon: ({ isActive }) => (
      <img
        src={settings}
        style={{ width: 16, height: 16 }}
        className={cn('invert-70', {
          '!invert': isActive,
        })}
      />
    ),
    isDisable: false,
    // badgeValue: 1,
  },
  {
    label: 'Dashboard',
    link: '/dashboard',
    icon: () => <Dashboard />,
    isDisable: true,
    leftSideComponent: () => <PerformanceChip>Performance</PerformanceChip>,
    // badgeValue: 1,
  },
  {
    label: 'Projects',
    link: '/projects',
    icon: () => <Projects />,
    isDisable: true,
    // badgeValue: 1,
  },
  {
    label: 'Resources',
    link: '/resources',
    icon: () => <Resources />,
    isDisable: true,
    // badgeValue: 1,
  },
  {
    label: 'Schedules',
    link: '/schedules',
    icon: () => <Schedules />,
    isDisable: true,
    // badgeValue: 1,
  },
];

const socialLink = [Facebook, Twitter, Insta, Linkedin];

const openIconSx = {
  '& span': {
    transition: 'opacity 0.3s ease-out',
    '&:nth-of-type(1)': {
      opacity: 0.2,
    },
    '&:nth-of-type(2)': {
      opacity: 0.4,
    },
    '&:nth-of-type(3)': {
      opacity: 0.6,
    },
    '&:nth-of-type(4)': {
      opacity: 0.8,
    },
    '&:nth-of-type(5)': {
      opacity: 1,
    },
  },

  '&:hover': {
    '& span': {
      '&:nth-of-type(1)': {
        opacity: 1,
      },
      '&:nth-of-type(2)': {
        opacity: 0.8,
      },
      '&:nth-of-type(3)': {
        opacity: 0.6,
      },
      '&:nth-of-type(4)': {
        opacity: 0.4,
      },
      '&:nth-of-type(5)': {
        opacity: 0.2,
      },
    },
  },
};

interface Props {
  open: boolean;
  toggleSideBar: () => void;
  list?: SidebarNavigationList[];
}

const Sidebar: React.FC<Props> = ({ open, toggleSideBar, list }) => {
  const sidebarList = list ?? sidebarNavigationList;

  return (
    <Drawer variant="permanent" open={open}>
      {/* Header */}
      <Box
        className="mb-[1px] flex h-9 min-h-9 cursor-pointer items-center justify-center gap-[9px] bg-artist-blue-900"
        sx={open ? openIconSx : {}}
        onClick={toggleSideBar}
      >
        {Array.from('12345').map((item, index) => {
          return (
            <Box
              component="span"
              sx={{
                display: 'inline-flex',
                opacity: !open && index !== 2 ? 0 : 1,
                transform:
                  !open && index === 2 ? 'rotate(180deg)' : 'rotate(0deg)',
              }}
              key={`${item}-collapsed-icon`}
            >
              <Collapsed width={28} height={28} />
            </Box>
          );
        })}
      </Box>

      {/* Body */}
      <div className="relative flex-grow">
        <List className="p-0">
          {sidebarList.map((item, index) => {
            return (
              <NavLink
                to={item.link}
                className={cn('all-unset no-underline', {
                  'pointer-events-none': item.isDisable,
                })}
                key={`${index}-${item.label}-sidebar-list`}
                onClick={() =>
                  setLocalStorageItem(localStorageKey.ACTIVE_ROUTER, item.link)
                }
              >
                {({ isActive }) => {
                  return (
                    <>
                      <ListItem
                        disablePadding
                        disabled={item.isDisable}
                        sx={{
                          height: 37,
                          fontSize: '12px',
                          lineHeight: '18px',
                          color: isActive ? '#ffffff' : alpha('#ffffff', 0.7),

                          cursor: 'pointer',
                          pointerEvents: isActive ? 'none' : 'auto',
                          justifyContent: 'space-between',
                          backgroundColor: isActive ? '#07B9EC' : 'transparent',
                          display: 'block',
                          '&.Mui-disabled': {
                            opacity: 0.25,
                            pointerEvents: 'none',
                          },
                          '&:hover': {
                            background: isActive
                              ? '#07B9EC'
                              : alpha('#07B9EC', 0.5),
                            color: isActive ? '#ffffff' : '#61BAD7',
                          },
                        }}
                      >
                        <Box
                          sx={{
                            display: 'flex',
                            height: '100%',
                            alignItems: 'center',
                            borderBottom: `1px solid ${alpha('#A8B1B8', item?.isDisable ? 1 : 0.15)}`,
                            marginLeft: open ? '10px' : '9px',
                            marginRight: open ? '0px' : '9px',
                            paddingRight: open ? '5px' : 0,
                            paddingLeft: open ? 0 : '8px',
                            transition: 'padding 0.2s ease',
                            gap: '6px',
                          }}
                        >
                          <span
                            className={clsx('size-4', {
                              'm-auto': !open,
                            })}
                          >
                            {!!item?.icon && item.icon({ isActive })}
                          </span>
                          {/* {open && ( */}
                          <div
                            className={clsx(
                              'flex w-full items-center justify-between gap-[6px]',
                              {
                                'pointer-events-none -z-10 opacity-0': !open,
                              },
                            )}
                          >
                            <span>{item.label}</span>
                            {item?.badgeValue && (
                              <span
                                className={clsx(
                                  'flex size-[18px] items-center justify-center rounded-full bg-artist-blue-900 font-semibold text-white opacity-100',
                                  {
                                    'bg-white !text-artist-blue-900': isActive,
                                  },
                                )}
                              >
                                {item?.badgeValue}
                              </span>
                            )}
                            {!!item?.leftSideComponent &&
                              item.leftSideComponent({})}
                          </div>
                          {/* )} */}
                        </Box>
                      </ListItem>
                    </>
                  );
                }}
              </NavLink>
            );
          })}
        </List>

        {open && <BuyNowDropDown />}
      </div>

      {/* Footer */}
      <div
        className={clsx('h-[33px] bg-artist-blue-900 pl-[14px]', {
          '!px-[4px]': !open,
        })}
      >
        <AvatarGroup spacing={open ? -6 : 15.5} className="h-full justify-end">
          {socialLink.map((Logo, index) => (
            <Avatar
              key={`${index}-social-logo`}
              sx={{
                width: 'auto',
                height: 'auto',
                cursor: 'pointer',
                zIndex: index,
              }}
            >
              <Logo style={{ width: 22, height: 22 }} />
            </Avatar>
          ))}
        </AvatarGroup>
      </div>

      <div
        className={clsx(
          'h-[67px] bg-white-whale-500 pb-[2px] pl-[14px] pr-[11px] pt-[12.12px]',
          {
            '!pl-1': !open,
            '!pt-0': !open,
          },
        )}
      >
        {open ? (
          <img
            src={images.schedulerOpsLogo}
            width={175}
            height={36}
            className="cursor-pointer hover:opacity-75"
          />
        ) : (
          <SchedulrOpsPrimarylogoCollapsed />
        )}
        <span
          className={clsx(
            'block truncate text-center text-[7px] text-dark-blue-900',
            {
              'mt-8': !open,
            },
          )}
        >
          {open
            ? 'Construction Ops Software Co - All Rights Reserved ©'
            : 'COS-©'}
        </span>
      </div>
    </Drawer>
  );
};

export default memo(Sidebar);
