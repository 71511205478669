import React, { memo, useEffect, useRef } from 'react';
import { withFormik, FormikProps } from 'formik';
import {
  CosBasDialog,
  PrimaryActionButtons,
} from '../../../components/dialogBox';
import { primaryActionClass } from '../../../components/dialogBox/PrimaryActionButtons';
import { DndContainer } from '../../../components/dndFile';
import ViewAndCropImg, {
  ViewCropRef,
} from '../../../components/imageCrop/ViewAndCropImg';

interface FormValues {
  file: File | null;
}

interface Props {
  open: boolean;
  initialData?: File;
  onClose?: () => void;
  onApply?: (values: any) => void;
}

const UploadLogoModal: React.FC<Props & FormikProps<FormValues>> = ({
  open,
  onClose,
  initialData,
  values,
  dirty,
  isValid,
  setFieldValue,
  resetForm,
  onApply,
  // handleSubmit,
}) => {
  const handleOwnApply = () => {
    ref.current?.getCropImageData().then(data => {
      onApply?.(data);
    });
    setTimeout(() => {
      onClose?.();
    });
  };

  const handleClose = () => {
    dirty ? handleCancel(resetForm) : onClose?.();
  };

  const handleCancel = (callback: Function) => {
    callback();
  };

  const ref = useRef<ViewCropRef>(null);

  useEffect(() => {
    if (initialData) {
      setFieldValue('file', null);
      setFieldValue('file', initialData);
    }
  }, [initialData]);

  return (
    <CosBasDialog
      open={open}
      sx={{
        '.MuiPaper-root': {
          background: '#EDEEEF',
        },
      }}
      onEsc={handleClose}
      onEnter={() => handleOwnApply()}
      height="355px"
      width="582px"
      heading="Upload Logo"
      showDivider
      headingClass="text-left text-18/24 text-blue-gray-900 bg-white-whale-500 p-[15px]"
      bodyClass="bg-white-whale-500 text-18/24 ml-[21px] pr-[17px] my-[26px] p-0 scrollbar-gutter-stable"
      actionClass={primaryActionClass}
      dialogActions={
        <PrimaryActionButtons
          showCancel={dirty}
          onCancel={() => handleCancel(resetForm)}
          onClose={onClose}
          disableApply={!(dirty && isValid)}
          onApply={() => handleOwnApply()}
        />
      }
    >
      {values.file ? (
        <div className="flex h-full items-center justify-center">
          <ViewAndCropImg file={values.file} ref={ref} />
        </div>
      ) : (
        <DndContainer
          onGetFile={file => {
            setFieldValue('file', file[0]);
          }}
        />
      )}
    </CosBasDialog>
  );
};

const UploadLogoModalWithFormik = withFormik<Props, FormValues>({
  mapPropsToValues: props => ({
    file: null,
  }),
  enableReinitialize: true,
  handleSubmit: (values, { props }) => {},
})(UploadLogoModal);

export default memo(UploadLogoModalWithFormik);
