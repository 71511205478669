import React from 'react';
import { ArrowFillUp } from '../../../../assets/svgs';
import { Box } from '@mui/material';
import language from '../../../../assets/svgs/staticColorSvg/normalLanguageImg.svg';

const languagePopUpSx = {
  boxShadow: '0px 0px 6px #A8B1B8',
  position: 'absolute',
  borderRadius: '3px',
  backgroundColor: '#EDEEEF',
  height: 46,
  width: 61,
  right: 0,
  opacity: 0.8,
  color: '#001F38',
  lineHeight: '15px',
  fontSize: '11px',
  padding: '5px 7.8px',
  zIndex: 2,
};

interface Props {
  value: string;
  open: boolean;
  onChange: Function;
}

const AdminProfileLanguageBox: React.FC<Props> = ({
  value,
  open,
  onChange,
}) => {
  const availAbleLanguage = ['English', 'Spanish', 'French'];

  return (
    <>
      {/* <LanguageIconBlue
        style={{
          width: 12,
          height: 12,
        }}
      /> */}
      <img src={language} className="language_icon size-3" />
      <span className="flex-grow">Language</span>
      <div>
        <div className="relative flex items-center gap-1">
          <span>{value}</span>
          <ArrowFillUp />
        </div>
        {open && (
          <Box sx={languagePopUpSx}>
            {availAbleLanguage.map(
              (language, index) =>
                language !== value && (
                  <p
                    className="m-0 mb-[5px] h-[15px] cursor-pointer hover:text-artist-blue-900"
                    onClick={event => onChange(event, language)}
                    key={`${index}-${language}`}
                  >
                    {language}
                  </p>
                ),
            )}
          </Box>
        )}
      </div>
    </>
  );
};

export default AdminProfileLanguageBox;
