import { CustomError } from './interface';
import { localStorageKey } from './constant';
import { type ClassValue, clsx } from 'clsx';
import { twMerge } from 'tailwind-merge';
import { startCase } from 'lodash';

export type ObjectType = Record<string | number, any>;

/**
 * Checks if two objects are equal by comparing their JSON representations.
 * @param {Object} obj1 - The first object to compare.
 * @param {Object} obj2 - The second object to compare.
 * @returns {boolean} - True if both objects are equal, otherwise false.
 */
export const areObjectsEqualJSON = (
  obj1: ObjectType,
  obj2: ObjectType,
): boolean => {
  return JSON.stringify(obj1) === JSON.stringify(obj2);
};

export function setToLocalStringNumber(value: string | number): string {
  const formatter = new Intl.NumberFormat('en', { notation: 'standard' });
  return formatter.format(+value);
}

/**
 * @description Remove null or empty value
 */
export const sanitizeObject = (valueObj: ObjectType): ObjectType => {
  return Object.fromEntries(
    Object.entries(valueObj).filter(
      ([_key, value]) => value !== '' && value !== null && value !== undefined,
    ),
  );
};

/**
 * Checks if any key in the given object has a truthy value.
 * @param obj - The object to check.
 * @returns {boolean} - True if any key has a truthy value, otherwise false.
 */
export function hasAnyCustomError(obj: ObjectType): boolean {
  return Object.values(obj).some((value: string) => {
    const errors: CustomError[] = JSON.parse(value || '[]');
    if (Array.isArray(errors)) {
      return errors.some(error => !error.isValid);
    }
  });
}

/**
 * Checks str is isCaseSensitive or not.
 * @Rule 1: No space
 * @Rule 2: At least one character should be capital
 * @Rule 3: Should not contain special characters
 */
export function isCaseSensitive(text: string): boolean {
  return !/\s/.test(text) && /[A-Z]/.test(text) && /^[a-zA-Z0-9]+$/.test(text);
}

/**
 * @description Function to store data in local storage
 */
export function setLocalStorageItem<T>(
  key: localStorageKey,
  value: T | any,
): boolean {
  try {
    localStorage.setItem(key, JSON.stringify(value));
    return true;
  } catch (error) {
    console.error('Error setting local storage item:', error);
    return false;
  }
}

/**
 * @description Function to retrieve data from local storage
 */
export function getLocalStorageItem<T>(key: localStorageKey): T | null {
  try {
    const data = localStorage.getItem(key);
    return data ? JSON.parse(data) : null;
  } catch (error) {
    console.error('Error getting local storage item:', error);
    return null;
  }
}

/**
 * @description  Function to remove data from local storage
 */
export function removeLocalStorageItem(key: string): boolean {
  try {
    localStorage.removeItem(key);
    return true;
  } catch (error) {
    console.error('Error removing local storage item:', error);
    return false;
  }
}

/**
 * @description Function to remove all localStorage data
 */
export function cleanAllLocalStorage(): void {
  localStorage.clear();
}

/**
 * @description Email address regex validation
 */
export function checkIsValidEmail(value: string): boolean {
  const regex =
    /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}(?:\.[a-zA-Z]{2,})?$/;
  return regex.test(value);
}

/**
 * @description Check is domain name is available or not
 */
export function isDomainNameAvailable(): boolean {
  const livePattern = /^https:\/\/[a-z0-9-]+\.sch-ops\.com$/;
  if (process.env.NODE_ENV === 'production') {
    if (livePattern.test(location.origin)) {
      return true;
    } else {
      // * Allow QA env https://dev-sch-ops.constructops.com/
      return isQaEnv();
    }
  } else {
    return location.origin.split('.').length === 2;
  }
}

/**
 * @description check is origin is qa (https://dev-sch-ops.constructops.com/) env or not
 */
export function isQaEnv(): boolean {
  const qaPattern = /^https:\/\/dev-sch-ops\.constructops\.com$/;
  return qaPattern.test(location.origin);
}

/**
 * @description check is origin is qa (http://standard.constructops.com/) env or not
 */
export function isStandardEnv(): boolean {
  const host = 'standard.constructops.com';
  return location.host === host;
}

export function isLocalEnv(): boolean {
  return location.origin.includes('localhost');
}

// TODO : typography not working at some case
export const cn = (...inputs: ClassValue[]): string => {
  return twMerge(clsx(inputs));
};

export const cosPadStart = (str: string | number): string => {
  return str.toString().padStart(2, '0');
};

export const isNumberRegex = (number: string): boolean => {
  const regex = /^\d+$/;
  return regex.test(number);
};

/**
 * @description Function to use in Country Code
 */
export const isCountryCode = (number: string): boolean => {
  const regex = /^[0-9+]{1,4}$/;
  return regex.test(number);
};

export const time12hRegex = (time: string): boolean => {
  const pattern = /^\d{2}:\d{2} [AP]M$/;
  return pattern.test(time);
};

export const time24hRegex = (time: string): boolean => {
  const pattern = /^\d{2}:\d{2}$/;
  return pattern.test(time);
};

export function snakeToTitle(snakeStr: string) {
  const words = snakeStr.split('_');
  const titleCaseStr = startCase(words.join(' '));
  return titleCaseStr;
}

export function camelCaseToTitleCase(camelCaseStr: string): string {
  const result = camelCaseStr
    .replace(/([A-Z])/g, ' $1') // Insert a space before each uppercase letter
    .replace(/^./, str => str.toUpperCase()) // Capitalize the first letter of the string
    .trim(); // Remove any leading or trailing whitespace

  return result;
}

export function convertRouterToTitle(router: string) {
  const words = router.split('/');
  return snakeToTitle(words[words.length - 1]);
}

export const lastActiveRouter =
  getLocalStorageItem(localStorageKey.ACTIVE_ROUTER) || '/my_company';

/**
 * Converts a byte value to megabytes.
 *
 * @param bytes - The value in bytes.
 * @returns The value in megabytes.
 */
export function bytesToMegabytes(bytes: number): number {
  const bytesInMB = 1024 * 1024; // 1 MB = 1024 * 1024 bytes
  return bytes / bytesInMB;
}
