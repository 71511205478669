import React from 'react';
import CosSearchInput from '../components/formikForms/ui/CosSearchInput';
import StandardLayout from './StandardLayout';

const StandardSearchBar = () => {
  return (
    <StandardLayout>
      <div className="h-[100px] w-full bg-dark-blue-900 p-2">
        <CosSearchInput placeholder="Search %Company%" disableFocusElevation />
      </div>
    </StandardLayout>
  );
};

export default StandardSearchBar;
