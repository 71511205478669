import { Table, TableProps } from '@mui/material';
import React from 'react';

interface Props extends TableProps {}

const CosTable: React.FC<Props> = props => {
  return <Table {...props} />;
};

export default CosTable;
