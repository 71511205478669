import { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { getLocalStorageItem } from '../../libs/utils';
import { localStorageKey } from '../../libs/constant';

// TODO : fix auth router
export default function AuthRouter({ children }: any) {
  const [isAuthenticated, setIsAuthenticated] = useState<boolean>(true);
  const userData = getLocalStorageItem(localStorageKey.USER_DATA);
  const navigate = useNavigate();

  useEffect(() => {
    if (userData) {
      setIsAuthenticated(true);
      navigate('/my_company/profile');
    } else {
      setIsAuthenticated(false);
    }
  }, [location]);

  return isAuthenticated
    ? navigate('/my_company/profile', { replace: true })
    : children;
}
