import { CosBasDialog } from './index';
import clsx from 'clsx';
import { CosBaseDialogProps } from './CosBasDialog';
import { constant } from '../../libs/constant';
import { Box, styled } from '@mui/material';
import { NextPrevRoundBtn } from '../arrow';

interface Props extends CosBaseDialogProps {
  onClose?: () => void;
  contentBody?: React.ReactNode;
  dialogAction?: React.ReactNode;
  children?: React.ReactNode;
  actionButton?: string[];
  heading?: string;
  modalHeight?: string;
  modalWidth?: string;
  isPagination?: Boolean;
  currentPage?: number;
  totalPage?: number;
}

const HoverWrapper = styled('div')(() => ({
  '&:hover .hover-text': {
    display: 'block',
  },
  '& .nextBtn .MuiBox-root': {
    position: 'absolute',
    right: 0,
  },
}));

const HoverText = styled('span')({
  display: 'none',
});

const CosDataView: React.FC<Props> = ({
  open,
  headingClass,
  bodyClass,
  onClose,
  children,
  heading,
  dialogAction,
  actionButton,
  modalWidth,
  modalHeight,
  currentPage = 1,
  totalPage = 1,
  isPagination = false,
  ...rest
}) => {
  return open ? (
    <CosBasDialog
      open={open}
      height={modalHeight}
      width={modalWidth}
      headingClass={clsx(
        'flex justify-center text-center font-semibold text-dark-blue-900 text-18/18 pt-[9px] px-[15px]',
        headingClass,
      )}
      headerWrapperClass={'w-full'}
      heading={
        !isPagination ? (
          heading
        ) : (
          <>
            <div className="flex w-full">
              <HoverWrapper className="flex w-[90px] items-center">
                <NextPrevRoundBtn open={true} />
                <HoverText className="hover-text pl-1 text-12/10 font-semibold text-blue-gray-100">
                  Previous
                </HoverText>
              </HoverWrapper>
              <div className="inline-grid flex-grow text-center">
                {heading}
                <span className="text-12/10 text-artist-blue-900">
                  {currentPage} of {totalPage}
                </span>
              </div>
              <HoverWrapper className="nextBtn relative flex w-[65px] items-center">
                <HoverText className="hover-text pr-1 text-12/10 font-semibold text-blue-gray-100">
                  Next
                </HoverText>
                <div className="absolute right-0">
                  <NextPrevRoundBtn />
                </div>
              </HoverWrapper>
            </div>
          </>
        )
      }
      sx={{
        '.MuiPaper-root': {
          borderRadius: constant.WorkScheduleModalRadius + 'px',
          background: '#EDEEEF',
          boxShadow: '0px 0px 12px #A8B1B8',
        },
        '& .MuiDialogContent-root': {
          padding: 0,
        },
        '& .MuiDialogContent-root .form-group': {
          paddingLeft: '15px',
          paddingRight: '15px',
        },
      }}
      className="rounded-messageModal"
      bodyClass={clsx('py-[10px] mr-0', bodyClass)}
      actionClass="flex h-[42px] border-0 border-t-[0.5px] border-solid border-artist-blue-900 p-0 bg-white overflow-x-hidden w-full justify-between"
      dialogActions={dialogAction}
      {...rest}
    >
      <Box>{children}</Box>
    </CosBasDialog>
  ) : (
    <></>
  );
};

export default CosDataView;
