import React, { useState } from 'react';
import { cn } from '../../../libs/utils';
import { CosLabel } from '../../../components/formikForms/ui';
import { CosIcon } from '../../../components';
import { CloseHover, CloseUnfill } from '../../../assets/svgs';

export interface FieldWithLabelProps {
  label: string;
  required: boolean;
  children: React.ReactNode;
  labelRightText?: React.ReactNode;
  labelRightEndText?: React.ReactNode;
  className?: string;
  labelClass?: string;
  onRemove?: false | (() => void);
}

export type FieldKeyWithLabelProps = Omit<
  FieldWithLabelProps,
  'label' | 'required'
> & { formFieldKey: string };

const FieldWithLabel: React.FC<FieldWithLabelProps> = ({
  label,
  labelRightText,
  labelRightEndText,
  required,
  children,
  className,
  onRemove,
}) => {
  const [showRemoveBtn, setShowRemoveBtn] = useState(false);

  return (
    <div
      className={cn('w-full', className)}
      onMouseEnter={() => onRemove && setShowRemoveBtn(true)}
      onMouseLeave={() => onRemove && setShowRemoveBtn(false)}
    >
      <div className="mb-[5px] flex items-center">
        <CosLabel required={required} className="font-semibold">
          {label}
        </CosLabel>
        {labelRightText &&
          (typeof labelRightText === 'string' ? (
            <span className="text-12/17 font-semibold text-dark-blue-900">
              &nbsp;{labelRightText}
            </span>
          ) : (
            labelRightText
          ))}
        {onRemove && (
          <CosIcon
            onHoverIcon={<CloseHover />}
            wrapperClass={cn(
              'inline-flex item-center ml-2 opacity-0 transition-[opacity] base-animation ',
              {
                'opacity-1': showRemoveBtn,
              },
            )}
            onClick={onRemove}
          >
            <CloseUnfill />
          </CosIcon>
        )}

        {labelRightEndText &&
          (typeof labelRightEndText === 'string' ? (
            <span className="ml-auto text-12/17 font-medium text-dark-blue-900">
              &nbsp;{labelRightEndText}
            </span>
          ) : (
            labelRightEndText
          ))}
      </div>
      {children}
    </div>
  );
};

export default FieldWithLabel;
