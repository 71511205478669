import { Outlet, RouteObject } from 'react-router';
import ComingSoonCompany from '../ComingSoonCompany';

const comingSoonCompanyHandler: RouteObject = {
  path: '/coming_soon/my_company',
  element: <ComingSoonCompany />,
  children: [
    {
      path: '',
      element: <p>My Company</p>,
    },
    {
      path: 'profile',
      element: <p>My Company - profile</p>,
    },
    {
      path: 'database',
      element: (
        <>
          <Outlet />
        </>
      ),
      children: [
        {
          path: '',
          element: <p>My Company - database</p>,
        },
        {
          path: 'TBD',
          element: <p>My Company - database - TBD</p>,
        },
        {
          path: 'TBD-1',
          element: <p>My Company - database - TBD-1</p>,
        },
      ],
    },
    {
      path: 'internal_resource',
      element: (
        <>
          <Outlet />
        </>
      ),
      children: [
        {
          path: '',
          element: <p>My Company - internal_resource</p>,
        },
        {
          path: 'TBD',
          element: <p>My Company - internal_resource - TBD</p>,
        },
        {
          path: 'TBD-1',
          element: <p>My Company - internal_resource - TBD-1</p>,
        },
      ],
    },
    {
      path: 'default_setting',
      element: <p>My Company - default_setting</p>,
    },
    {
      path: 'with_more_than_8_items',
      children: [
        {
          path: '',
          element: <p>My Company - with_more_than_8_items</p>,
        },
        {
          path: 'departments',
          element: <p>My Company - with_more_than_8_items - departments</p>,
        },
        {
          path: 'resource_types',
          element: <p>My Company - with_more_than_8_items - resource_types</p>,
        },
        {
          path: 'work_schedules',
          element: <p>My Company - with_more_than_8_items - work_schedules</p>,
        },
        {
          path: 'holidays',
          element: <p>My Company - with_more_than_8_items - holidays</p>,
        },
        {
          path: 'positions_titles',
          element: (
            <p>My Company - with_more_than_8_items - positions_titles</p>
          ),
        },
        {
          path: 'capacities',
          element: <p>My Company - with_more_than_8_items - capacities</p>,
        },
        {
          path: 'library Name',
          element: <p>My Company - with_more_than_8_items - Name</p>,
        },
        {
          path: 'TBD_1',
          element: <p>My Company - with_more_than_8_items - TBD_1</p>,
        },
        {
          path: 'TBD_2',
          element: <p>My Company - with_more_than_8_items - TBD_2</p>,
        },
        {
          path: 'TBD_3',
          element: <p>My Company - with_more_than_8_items - TBD_3</p>,
        },
        {
          path: 'TBD_4',
          element: <p>My Company - with_more_than_8_items - TBD_4</p>,
        },
        {
          path: 'TBD)5_with_large_or_extra_text',
          element: (
            <p>
              My Company - with_more_than_8_items - 5_with_large_or_extra_text
            </p>
          ),
        },
      ],
    },
  ],
};

export default comingSoonCompanyHandler;
