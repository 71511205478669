import React, { useEffect, useState } from 'react';
import { CosBasDialog, CosSessionInput } from './index';
import clsx from 'clsx';
import { Box, Divider } from '@mui/material';
import { constant } from '../../libs/constant';
import { CosBaseDialogProps } from './CosBasDialog';
import CosButton from '../CosButton';
import { CosCheckbox } from '../formikForms/ui';

interface Props extends CosBaseDialogProps {
  onClose?: () => void;
  button?: string[];
  heading?: string;
  contentBody?: React.ReactNode;
  children?: React.ReactNode;
  onSessionConfirm?: () => void;
}

const CosSession: React.FC<Props> = ({
  headingClass,
  bodyClass,
  open,
  onSessionConfirm,
  onClose,
  button,
  heading,
  children,
  ...rest
}) => {
  const [isUpdateEnable, setUpdateEnable] = useState(false);

  const onExtendSession = () => {
    setUpdateEnable(true);
  };

  const handleLogout = () => {
    setUpdateEnable(false);
    onClose?.();
  };

  return open ? (
    <CosBasDialog
      open={open}
      headingClass={clsx(
        'flex justify-center font-semibold text-artist-blue-900 text-18/22 pt-2.5',
        headingClass,
      )}
      height="250px"
      width="425px"
      sx={{
        '.MuiPaper-root': {
          borderRadius: constant.messageModalRadius + 'px',
          background: '#EDEEEF',
          boxShadow: '0px 0px 12px #A8B1B8',
        },
      }}
      className="rounded-messageModal"
      bodyClass={clsx(
        'min-w-[350px] max-w-[425px] px-[15px] py-[10px]',
        bodyClass,
      )}
      actionClass="flex h-[44px] border-0 border-t-[0.5px] border-solid border-artist-blue-900 p-0 bg-white overflow-x-hidden"
      dialogActions={
        <>
          <CosButton
            size="medium"
            variant="text"
            onClick={handleLogout}
            className="min-h-auto w-1/2"
            color="error"
          >
            {button ? button[0] : 'LogOut'}
          </CosButton>
          <Divider
            className="opacity-1 m-0 bg-artist-blue-900"
            flexItem
            orientation="vertical"
          ></Divider>
          <CosButton
            size="medium"
            variant="text"
            onClick={handleLogout}
            className="min-h-auto m-0 w-1/2"
            disabled={!isUpdateEnable}
          >
            {button ? button[1] : 'Update'}
          </CosButton>
        </>
      }
      heading={heading}
      {...rest}
    >
      {isUpdateEnable ? (
        <CosSessionInput></CosSessionInput>
      ) : (
        <Box className="text-center text-13/18">
          <p className="m-0">
            Your session time limit is set to 8 hours. Your session will expire
            in %15 minutes%.
          </p>
          <CosButton
            size="medium"
            variant="outlined"
            className="mt-[31px]"
            onClick={onExtendSession}
          >
            Extend my Session
          </CosButton>
          <p className="m-0 flex justify-center pt-[26px]">
            <CosCheckbox className="pr-[8px]" /> Don’t ask me again.
          </p>
        </Box>
      )}
    </CosBasDialog>
  ) : (
    <></>
  );
};

export default CosSession;
