import { Box, Typography } from '@mui/material';
import { useState, type CSSProperties, type FC } from 'react';
import type { DropTargetMonitor } from 'react-dnd';
import { useDrop } from 'react-dnd';
import { NativeTypes } from 'react-dnd-html5-backend';
import CosButton from '../CosButton';
import { bytesToMegabytes } from '../../libs/utils';

export interface TargetBoxProps {
  onDrop: (files: File[]) => void;
  children?: React.ReactNode | Function;
}

export const TargetBox: FC<TargetBoxProps> = props => {
  const { onDrop, children } = props;
  const [error, setError] = useState('');

  const [{ canDrop, isOver }, drop] = useDrop(
    () => ({
      accept: [NativeTypes.FILE],
      drop(item: { files: any[] }) {
        handleUploadFile(item.files);
      },
      canDrop(item: any) {
        return true;
      },
      hover(item: any) {},
      collect: (monitor: DropTargetMonitor) => {
        return {
          isOver: monitor.isOver(),
          canDrop: monitor.canDrop(),
        };
      },
    }),
    [props],
  );

  const handleUploadFile = (files: File[]) => {
    if (files.length > 1) {
      setError('Allow only one file at a time');
      return;
    }

    const file = files[0];
    console.log(file);

    if (!file.type.startsWith('image')) {
      setError('Allow image type file only');
      return;
    }

    const fileSize = bytesToMegabytes(file.size);

    if (fileSize > 1) {
      setError('File size should be less than 1 Mb');
      return;
    }

    setError('');
    onDrop(files);
  };

  const isActive = canDrop && isOver;
  return (
    <Box ref={drop}>
      {children ? (
        // TODO:  manage children
        // typeof children === 'function' ? (
        //   children(isActive)
        // ) : (
        //   children
        // )
        <></>
      ) : (
        <Box
          className="flex flex-col items-center p-4"
          sx={{
            border: '1px dashed #707070',
            backgroundColor: 'white',
            width: '100%',
            opacity: isActive ? 0.5 : 1,
          }}
        >
          <Typography className="text-18/18">
            Drag & Drop File Here to Upload
          </Typography>
          <Typography className="mt-[12px] text-center text-18/18 font-semibold">
            or
          </Typography>
          <CosButton
            component="label"
            className="mt-[26px]"
            onChange={e => {
              const input = e.target as HTMLInputElement;
              if (input.files && input.files.length > 0) {
                const files: any = input.files;
                handleUploadFile(files);
              }
            }}
          >
            Select File
          </CosButton>
          <Typography className="mt-[20px] h-[19px] text-center text-14/19 text-error-900">
            {error}
          </Typography>
          <Typography className="mt-[13px] text-center text-14/19 text-blue-gray-100">
            Image only. Maximum file size - 1 Mb
          </Typography>
        </Box>
      )}
    </Box>
  );
};
