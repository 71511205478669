import React, { memo, useRef, useState } from 'react';
import AddButton from '../../../components/buttons/AddButton';
import { Typography } from '@mui/material';
import { CosCountBadge } from '../../../components';
import { FieldArray, useFormikContext } from 'formik';
import FieldWithLabel from '../components/FieldWithLabel';
import { FormInput } from '../../../components/formikForms';
import AddNewPhone from './AddNewPhone';
// import { isCountryCode } from '../../../libs/utils';

const commonSx = {
  minWidth: 'auto',
};

interface Props {
  name: string;
  isViewMode: boolean;
  isActive: boolean;
}

const PhoneSection: React.FC<Props> = ({ name, isViewMode, isActive }) => {
  const [addNewPhone, setAddNewPhone] = useState(false);
  const wrapperRef = useRef<HTMLDivElement>(null);

  const valueClass = 'text-14/15 ';
  const { setFieldValue, dirty, values, errors }: any = useFormikContext();
  const value = values[name];
  const isValid = !(dirty && !errors[name]);

  const handlePhoneInput = (
    event: React.ChangeEvent<HTMLInputElement>,
    index: number,
    type: 'phoneNumber' | 'phoneExt',
  ) => {
    const { value } = event.target;
    let formattedValue = value.replace(/\D/g, ''); // Remove all non-digit characters

    if (type === 'phoneNumber') {
      if (formattedValue.length > 10) {
        formattedValue = formattedValue.slice(0, 10); // Limit to 10 digits
      }

      // Format the phone number as (123) 123-1234
      if (formattedValue.length > 6) {
        formattedValue = `(${formattedValue.slice(0, 3)}) ${formattedValue.slice(3, 6)} - ${formattedValue.slice(6)}`;
      } else if (formattedValue.length > 3) {
        formattedValue = `(${formattedValue.slice(0, 3)}) ${formattedValue.slice(3)}`;
      } else if (formattedValue.length > 0) {
        formattedValue = `(${formattedValue}`;
      }

      setFieldValue(`${name}.${index}.${type}`, formattedValue);
    } else {
      // Handle phone extension if necessary
      if (formattedValue.length > 3) {
        formattedValue = formattedValue.slice(0, 3); // Limit to 3 digits for phone extension
      }

      setFieldValue(`${name}.${index}.${type}`, formattedValue);
    }
  };

  // ! this fuction is use in handle in country code
  // const handleCountryCoode = (
  //   event: React.ChangeEvent<HTMLInputElement>,
  //   index: number,
  // ) => {
  //   if (event.target.value === '' || event.target.value === undefined) {
  //     setFieldValue(`${name}.${index}.countryCode`, '+1');
  //   }
  //   if (isCountryCode(event.target.value) == true) {
  //     setFieldValue(`${name}.${index}.countryCode`, event.target.value);
  //   }
  // };

  return (
    <>
      <FieldArray name={name}>
        {({ remove, push }) => (
          <>
            <Typography
              component="h6"
              className="mb-profile-8px flex items-center gap-[5px] text-16/22 font-semibold"
            >
              {value.length !== 1 ? 'Phones' : 'Phone'}
              {isActive && <CosCountBadge count={value.length} />}
            </Typography>
            <section
              className="max-h-[53px] min-h-[53px] overflow-auto px-[1px]"
              ref={wrapperRef}
            >
              {isViewMode ? (
                <section className="mr-[4px] flex max-h-[53px] min-h-[53px] flex-wrap gap-x-[30px] gap-y-[14px]">
                  {value.length > 0 &&
                    value.map((phone: any, index: number) => (
                      <div
                        className="flex w-fit gap-[5px] pb-[1px]"
                        key={`${index}-phone`}
                      >
                        <FieldWithLabel
                          label={phone.phoneType + ' Phone'}
                          required={index > 0 ? false : true}
                        >
                          <div className="flex gap-[5px]">
                            <Typography className={`${valueClass}`}>
                              {phone.countryCode}&nbsp;
                              {phone.phoneNumber}
                              {phone.phoneExt ? `, Ext. ${phone.phoneExt}` : ''}
                            </Typography>
                          </div>
                        </FieldWithLabel>
                      </div>
                    ))}
                </section>
              ) : (
                <section className="mr-[4px] flex flex-wrap gap-x-[15px] gap-y-[14px]">
                  {value.length > 0 &&
                    value.map((phone: any, index: number) => (
                      <div
                        className="flex w-fit gap-[5px] pb-[1px]"
                        key={`${index}-phone`}
                      >
                        <FieldWithLabel
                          label={phone['phoneType'] + ' Phone'}
                          required={index > 0 ? false : true}
                          onRemove={index > 0 ? () => remove(index) : false}
                        >
                          <div className="flex gap-[5px]">
                            <FormInput
                              name={`${name}.${index}.countryCode`}
                              size="small"
                              className="!mb-0 w-[33px]"
                              readOnly={true}
                              inputSx={{
                                ...commonSx,
                                padding: '0px 9px',
                                textAlign: 'center',
                              }}
                              // onChange={(event: any) =>
                              //   handleCountryCoode(event, index)
                              // }
                            />
                            <FormInput
                              name={`${name}.${index}.phoneNumber`}
                              size="small"
                              className="!mb-0 w-[151px]"
                              inputSx={{ ...commonSx, padding: '0px 8px' }}
                              placeholder="Enter Primary Phone"
                              onChange={(event: any) => {
                                handlePhoneInput(event, index, 'phoneNumber');
                              }}
                            />
                          </div>
                        </FieldWithLabel>
                        <FieldWithLabel label="Ext." required={false}>
                          <FormInput
                            name={`${name}.${index}.phoneExt`}
                            size="small"
                            className="!mb-0 w-[69px]"
                            type="number"
                            inputSx={{ ...commonSx, padding: '0px 4px' }}
                            placeholder="Enter Ext."
                            onChange={(event: any) =>
                              handlePhoneInput(event, index, 'phoneExt')
                            }
                          />
                        </FieldWithLabel>
                      </div>
                    ))}
                </section>
              )}
            </section>

            <AddNewPhone
              open={addNewPhone}
              onClose={() => setAddNewPhone(false)}
              onApply={value => {
                value.map(item => push(item));
                setTimeout(() => {
                  wrapperRef?.current?.scrollTo({
                    top: wrapperRef.current.scrollHeight,
                    behavior: 'smooth',
                  });
                });
              }}
            />
          </>
        )}
      </FieldArray>{' '}
      {isViewMode ? (
        <div className="mt-[2px] flex max-h-[23px] min-h-[23px] items-center">
          <p className="m-0 w-full border-0 border-b-[1px] border-solid border-blue-gray-200/[0.38] " />
        </div>
      ) : (
        <AddButton
          onClick={() => setAddNewPhone(true)}
          disable={isValid}
          toolTipText={'Add Phone'}
          className="mt-[2px]"
        />
      )}
    </>
  );
};

export default memo(PhoneSection);
