import { CosTopHeaderMenu } from '../components/Tabs/index';
import StandardLayout from './StandardLayout';

const label = [
  { label: 'TBD', value: 'TBD-1', badge: 5 },
  { label: 'TBD', value: 'TBD-2', badge: 2 },
  { label: 'TBD', value: 'TBD-3', badge: 2 },
  { label: 'TBD', value: 'TBD-4', badge: 2 },
  { label: 'TBD', value: 'TBD-5', badge: 2 },
  { label: 'Message Center', value: 'TBD-8', badge: 6 },
];

const StandardTopHeader = () => (
  <StandardLayout>
    <h5 className="text-center">Top Header Menus</h5>
    <div className="flex h-[60px] justify-center bg-buddha-love-850">
      <CosTopHeaderMenu tabs={label} />
    </div>
  </StandardLayout>
);
export default StandardTopHeader;
