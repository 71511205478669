import { useRef, useState } from 'react';
import { cosPadStart, isNumberRegex } from '../../../../libs/utils';

interface NumberHookArg {
  min: number;
  max: number;
  defaultValue?: number;
  onChange?: (value: any) => void;
}

const useNumberHook = ({ min, max, defaultValue, onChange }: NumberHookArg) => {
  const numberInputRef = useRef<any>();

  const [value, setValue] = useState(cosPadStart(defaultValue || 0));

  const focusCurrentTarget = () => {
    numberInputRef?.current?.focus();
  };

  const onIncrement = () => {
    focusCurrentTarget();
    const newValue = cosPadStart(+value + 1);
    if (+value !== +max) {
      setValue(newValue);
      onChange?.(newValue);
    }
  };

  const onDecrement = () => {
    focusCurrentTarget();
    const newValue = cosPadStart(+value - 1);
    if (+value !== +min) {
      setValue(newValue);
      onChange?.(newValue);
    }
  };

  const handleChange = (event: any) => {
    const newValue = event.target.value;
    // Check if the new value is either '0' or an empty string
    if (newValue === '0' || newValue === '') {
      //in -> '00'
      setValue(() => newValue); //op => 0 pr ''
      onChange?.(newValue);
    } else if (isNumberRegex(newValue) && newValue <= max) {
      // If the new value is a number and less than or equal to the maximum allowed value
      if (newValue < 10) {
        // If the number is less than 10, pad it with leading zeros  in -> (1)
        setValue(() => cosPadStart(newValue)); //op -> (01)
        onChange?.(cosPadStart(newValue));
      } else {
        // If the number is greater than or equal to 10
        if (newValue.startsWith('0')) {
          // If it starts with a zero, remove the leading zero in -> (012)
          setValue(() => newValue.slice(1)); //op -> (12)
          onChange?.(newValue.slice(1));
        } else {
          // Otherwise, update the state with the new value
          setValue(() => newValue);
          onChange?.(newValue);
        }
      }
    }
  };

  const onKeyDown = (event: any) => {
    if (event.key === '+' || event.key === 'ArrowUp') {
      event.preventDefault();
      onIncrement();
    }
    if (event.key === '-' || event.key === 'ArrowDown') {
      event.preventDefault();
      onDecrement();
    }
  };

  return {
    value,
    handleChange,
    onKeyDown,
    onIncrement,
    onDecrement,
    numberInputRef,
    disableDecrement: +value === +min,
    disableIncrement: +value === +max,
  };
};

export default useNumberHook;
