import * as React from 'react';
import { alpha, styled } from '@mui/material/styles';
import Button, { ButtonProps } from '@mui/material/Button';
import clsx from 'clsx';

const BaseButton = styled(Button, {
  shouldForwardProp: prop => prop !== 'customSize',
})<CosButtonProps>(({
  color = 'info',
  theme,
  disableElevation,
  customSize,
}: any) => {
  const currentColor = theme.palette[color].main;
  return {
    '&:hover, &:focus': {
      boxShadow: disableElevation ? 'none' : '0px 3px 6px #00000029',
    },
    borderRadius: 3,
    boxShadow: disableElevation ? 'none' : '0px 3px 6px #00000029',
    textTransform: 'capitalize',
    color: '#ffffff',
    position: 'relative',
    fontWeight: '600',

    // fontSize: 16,
    // border: '1px solid',
    // lineHeight: 1.5,
    // color: '#FFB92C',
    // borderColor: 'currentColor',
    paddingLeft: 20,
    paddingRight: 20,

    // * Medium Size
    '&.MuiButton-sizeMedium': {
      height: '25px',
      minWidth: '120px',
      Padding: '0 15px',
      fontSize: '12px',
    },

    // * Small size
    '&.MuiButton-sizeSmall': {
      minWidth: '65px',
      height: '20px',
      fontSize: '12px',
      padding: '0 10px',
    },

    //* Large size
    '&.MuiButton-sizeLarge': {
      minWidth: '140px',
      height: '35px',
      fontSize: '18px',
      padding: '0 20px',
    },

    // * Extra Large
    ...(customSize === 'extraLarge'
      ? {
          '&.MuiButton-root': {
            minWidth: '450px',
            height: '40px',
            padding: '0 25px',
            fontSize: '18px',
          },
        }
      : {}),

    ...(customSize === 'extraSmall'
      ? {
          '&.MuiButton-root': {
            minWidth: '45px',
            height: '15px',
            padding: '0 10px',
            fontSize: '10px',
          },
        }
      : {}),

    // * Outline
    '&.MuiButton-outlined': {
      color: currentColor,
      borderColor: alpha(currentColor, 1),
      '&:hover, &:focus': {
        color: '#ffffff',
        backgroundColor: currentColor,
        borderColor: currentColor,
      },
    },

    // * Text
    '&.MuiButton-text': {
      all: 'unset',
      color: currentColor,
      minWidth: '120px',
      transition: 'transform 0.3s ease-in-out',
      fontWeight: 400,
      textAlign: 'center',
      '&:hover, &:focus': {
        background: 'transparent',
      },
      '&.MuiButton-sizeMedium': {
        fontSize: '17px',
        minWidth: '120px',
        // padding: '0 20px',
        letterSpacing: '-0.41px',
        '&:hover, &:focus': {
          fontWeight: 500,
          transform: 'scale(1.1)',
        },
      },
      '&.MuiButton-sizeSmall': {
        minWidth: '65px',
        // padding: '0 10px',
        fontSize: '14px',
        letterSpacing: '-0.41px',
        '&:hover, &:focus': {
          fontWeight: 500,
          transform: 'scale(1.07)',
        },
      },
    },

    // * contained
    '&.MuiButton-contained': {
      '&:hover, &:focus': {
        backgroundColor: alpha(currentColor, 0.75),
        borderColor: alpha(currentColor, 0.75),
      },
    },

    // * Icon working with small size
    '& .MuiButton-startIcon': {
      margin: '0',
      paddingRight: '3px',
    },

    '&.Mui-disabled': {
      color: currentColor,
      opacity: 0.25,
      pointerEvents: 'none',
      '&.MuiButton-contained': {
        color: '#ffffff',
        backgroundColor: currentColor,
      },
    },
  };
});

interface CosButtonProps extends Omit<ButtonProps, 'Size'> {
  children: React.ReactNode;
  isLoading?: boolean;
  component?: any;
  customSize?: 'extraLarge' | 'extraSmall';
}

const VisuallyHiddenInput = styled('input')({
  clip: 'rect(0 0 0 0)',
  clipPath: 'inset(50%)',
  height: 1,
  overflow: 'hidden',
  position: 'absolute',
  bottom: 0,
  left: 0,
  whiteSpace: 'nowrap',
  width: 1,
});

const CosButton = React.forwardRef<HTMLButtonElement, CosButtonProps>(
  (props, ref) => {
    const {
      children,
      className,
      isLoading,
      onClick,
      color = 'info',
      variant = 'outlined',
      component,
      customSize,
      size,
      ...rest
    } = props;

    if (customSize && size) {
      throw new Error(
        "Both 'customSize' and 'size' props cannot be used together.",
      );
    }

    const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
      if (isLoading) {
        event.stopPropagation();
        event.preventDefault();
        return;
      }
      if (onClick) {
        onClick(event);
      }
    };

    return (
      <>
        <BaseButton
          disableRipple
          disableElevation
          disableFocusRipple
          className={clsx(
            isLoading ? 'cursor-progress' : 'cursor-pointer',
            className,
          )}
          onClick={handleClick}
          color={color}
          variant={variant}
          component={component}
          onMouseOutCapture={(event: React.SyntheticEvent) => {
            const target = event.target as HTMLButtonElement;
            target.blur();
          }}
          customSize={customSize}
          size={size}
          {...rest}
          ref={ref}
        >
          <p className="m-0 inline-block text-center">{children}</p>
          {component === 'label' && (
            <VisuallyHiddenInput type="file" tabIndex={-1} />
          )}
        </BaseButton>
      </>
    );
  },
);

export default CosButton;
