/**
 * @description Ref : mui-one-time-password-input package
 */
import React, { CSSProperties, KeyboardEventHandler } from 'react';
import { alpha, styled } from '@mui/material';
import { KEYBOARD_KEY, constant } from '../../libs/constant';
import { getFilledArray, joinArrayStrings, updateIndex } from './helper';
import { AnyFunction, useEvent } from './useEvent';
import { cn, cosPadStart, isNumberRegex } from '../../libs/utils';

const BaseInput = styled('input')({
  justifyContent: 'center !important',
  outline: 'none !important',
  borderRadius: 0,
  borderColor: 'transparent',
  color: '#001F38',
  padding: 0,
  boxSizing: 'border-box',
  minHeight: '26px',
  minWidth: '16px',
  maxHeight: '26px',
  maxWidth: '16px',
  fontWeight: '600',
  fontSize: '12px',
  backgroundColor: '#EDEEEF',
  textAlign: 'center',
  '&:hover': {
    color: alpha('#001F38', 0.75),
  },
  '&:focus': {
    color: alpha('#001F38', 1),
  },
  '&:first-of-type': {
    borderTopLeftRadius: constant.inputSmallRadius,
    borderBottomLeftRadius: constant.inputSmallRadius,
    borderRight: '0.5px solid #A8B1B8',
  },
  '&:nth-of-type(2)': {
    borderTopRightRadius: constant.inputSmallRadius,
    borderBottomRightRadius: constant.inputSmallRadius,
  },
});

export const StyleRoot = styled('div')({
  borderColor: 'transparent',
  backgroundColor: '#EDEEEF',
  borderRadius: constant.inputSmallRadius,
  boxShadow: '0px 0px 3px #A8B1B8',
});

type ValueSplitted = {
  character: string;
  inputRef: React.RefObject<HTMLInputElement>;
}[];

interface Props {
  id: string;
  value: string;
  onChange: Function;
  onComplete?: AnyFunction;
  className?: string;
  onFocus?: Function;
  onBlur?: Function;
  onBlurCapture?: () => void;
  onKeyDown?: Function;
  onKeyUp?: KeyboardEventHandler<HTMLInputElement>;
  onEmpty?: Function;
  max?: number;
  min?: number;
}

const ScheduleInput: React.FC<Props> = ({
  value = '',
  onChange,
  onComplete,
  className,
  onFocus,
  onBlur,
  onKeyDown,
  onBlurCapture,
  onEmpty,
  onKeyUp,
  id,
  max = 99,
  min = 0,
}) => {
  const length = 2;
  const initialValue = React.useRef(value);
  const onCallbackEvent = useEvent(onComplete);

  // * Allow only digit
  const validateChar = (value: string, index: number) => {
    return isNumberRegex(value);
  };

  const matchIsCompletedEvent = useEvent(
    (filledStrings: string, triggerOnEmptyEvent: boolean = true) => {
      const finalValue = filledStrings.slice(0, length);
      if (!finalValue && triggerOnEmptyEvent) {
        onEmpty?.();
      }
      return {
        isCompleted: finalValue.length === length,
        finalValue,
      };
    },
  );

  React.useEffect(() => {
    const { isCompleted, finalValue } = matchIsCompletedEvent(
      initialValue.current,
      false,
    );

    if (isCompleted) {
      onCallbackEvent(finalValue);
    }
  }, [length, onCallbackEvent, matchIsCompletedEvent]);

  const valueSplitted: ValueSplitted = getFilledArray(
    length as number,
    (_, index) => {
      return {
        character: (value as string)[index] || '',
        inputRef: React.createRef<HTMLInputElement>(),
      };
    },
  );

  const getIndexByInputElement = (inputElement: HTMLInputElement) => {
    return valueSplitted.findIndex(({ inputRef }) => {
      return inputRef.current === inputElement;
    });
  };

  const getCharactersSplitted = () => {
    return valueSplitted.map(({ character }) => {
      return character;
    });
  };

  const replaceCharOfValue = (charIndex: number, charValue: string) => {
    const newValueSplitted = updateIndex(
      getCharactersSplitted(),
      charIndex,
      charValue,
    );

    return joinArrayStrings(newValueSplitted);
  };

  const focusInputByIndex = (inputIndex: number) => {
    valueSplitted[inputIndex]?.inputRef.current?.focus();
  };

  const selectInputByIndex = (inputIndex: number) => {
    valueSplitted[inputIndex]?.inputRef.current?.select();
  };

  const manageCaretForNextInput = (currentInputIndex: number) => {
    if (currentInputIndex + 1 === length) {
      return;
    }

    if (valueSplitted[currentInputIndex + 1].character) {
      selectInputByIndex(currentInputIndex + 1);
    } else {
      focusInputByIndex(currentInputIndex + 1);
    }
  };

  const matchIsCharIsValid = (character: string, index: number) => {
    return typeof validateChar !== 'function'
      ? true
      : validateChar(character, index);
  };

  const handleOneInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const currentInputIndex = getIndexByInputElement(event.target);

    //* Autofill from sms (Not refired is our case)
    // if (currentInputIndex === 0 && event.target.value.length > 1) {
    //   const { finalValue, isCompleted } = matchIsCompletedEvent(
    //     event.target.value,
    //   );
    //   onChange?.(finalValue);

    //   if (isCompleted) {
    //     onComplete?.(finalValue);
    //   }

    //   selectInputByIndex(finalValue.length - 1);

    //   return;
    // }

    const initialChar = event.target.value[0] || '';
    let character = initialChar;

    //* Handle backspace so check character (or check custom validation)
    if (character && !matchIsCharIsValid(character, currentInputIndex)) {
      character = '';
    }

    const inputValue = replaceCharOfValue(currentInputIndex, character);

    let newValue = inputValue;
    if (+inputValue > max) {
      newValue = max.toString();
    } else if (inputValue.length === 2 && +inputValue < min) {
      newValue = cosPadStart(min.toString());
    }

    onChange?.(newValue);

    const { isCompleted, finalValue } = matchIsCompletedEvent(newValue);

    if (isCompleted) {
      onComplete?.(finalValue);
    }

    //* Char is valid so go to next input ()
    if (character !== '') {
      //* handle when the filled input is before the input selected
      if (newValue.length - 1 < currentInputIndex) {
        selectInputByIndex(newValue.length);
      } else {
        manageCaretForNextInput(currentInputIndex);
      }

      //* Only for backspace so don't go to previous input if the char is invalid
    } else if (initialChar === '') {
      if (newValue.length <= currentInputIndex) {
        selectInputByIndex(currentInputIndex - 1);
      }
    }
  };

  const handleOneInputKeyDown = (
    event: React.KeyboardEvent<HTMLDivElement>,
  ) => {
    const inputElement = event.target as HTMLInputElement;
    const startPos = inputElement.selectionStart; //* Not working for number type
    const endPos = inputElement.selectionEnd; //* Not working for number type
    const currentInputIndex = getIndexByInputElement(inputElement);
    const isCaretBeforeChar = startPos === 0 && endPos === 0;

    if (inputElement.value === event.key) {
      // * if enter same value go to next field
      event.preventDefault();
      manageCaretForNextInput(currentInputIndex);
    } else if (KEYBOARD_KEY.backspace === event.key) {
      if (!inputElement.value) {
        //* move to prev input
        event.preventDefault();
        selectInputByIndex(currentInputIndex - 1);
      } else if (isCaretBeforeChar) {
        //* Caret is before the character and there is a character, so remove it
        event.preventDefault();
        const newValue = replaceCharOfValue(currentInputIndex, '');
        onChange?.(newValue);

        if (newValue.length <= currentInputIndex) {
          selectInputByIndex(currentInputIndex - 1);
        }
      }
    } else if (KEYBOARD_KEY.left === event.key) {
      event.preventDefault();
      selectInputByIndex(currentInputIndex - 1);
    } else if (KEYBOARD_KEY.right === event.key) {
      event.preventDefault();
      selectInputByIndex(currentInputIndex + 1);
    } else if (KEYBOARD_KEY.home === event.key) {
      event.preventDefault();
      selectInputByIndex(0);
    } else if (KEYBOARD_KEY.end === event.key) {
      event.preventDefault();
      selectInputByIndex(valueSplitted.length - 1);
    }
  };

  const handleBlur = (
    event: React.FocusEvent<HTMLInputElement | HTMLTextAreaElement, Element>,
  ) => {
    const anInputIsFocused = valueSplitted.some(({ inputRef }) => {
      return inputRef.current === event.relatedTarget;
    });

    if (!anInputIsFocused) {
      const { isCompleted, finalValue } = matchIsCompletedEvent(value);
      onBlur?.(finalValue, isCompleted);
    }
  };

  return (
    <StyleRoot>
      {valueSplitted.map(({ character, inputRef }, index) => {
        return (
          <BaseInput
            // type="number"
            autoComplete="one-time-code"
            value={character}
            ref={inputRef}
            id={`${id}-${index}`}
            className={cn(`CosTextField CosTextField-${index + 1}`, className)}
            onFocus={event => {
              event.preventDefault();
              event.target.select();
              onFocus?.(event);
            }}
            onPaste={event => event.preventDefault()}
            onChange={handleOneInputChange}
            onKeyDown={event => {
              handleOneInputKeyDown(event);
              onKeyDown?.(event);
            }}
            onBlur={event => {
              handleBlur(event);
              onBlur?.(event);
            }}
            onKeyUp={onKeyUp}
            onBlurCapture={onBlurCapture}
            key={index}
          />
        );
      })}
    </StyleRoot>
  );
};

export default ScheduleInput;
