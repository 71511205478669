import React from 'react';
import StandardDashboard from './StandardDashboard';
import { Outlet } from 'react-router';
import { comingSoonSettingHandler } from './routerHandler/comingSoonSettingHandler';

const ComingSoonSettings = () => {
  return (
    <StandardDashboard navigatorList={comingSoonSettingHandler}>
      <p>Settings</p>
      <Outlet />
    </StandardDashboard>
  );
};

export default ComingSoonSettings;
