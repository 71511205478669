import * as Yup from 'yup';
import { getRequiredFieldFromSchema } from '../../../libs/helper';
import { checkIsValidEmail, getLocalStorageItem } from '../../../libs/utils';
import { localStorageKey } from '../../../libs/constant';

const userDetails: any = getLocalStorageItem(localStorageKey.USER_DATA);

export const topFormInitialValues = {
  companyName: userDetails?.companyName,
  country: 'United States',
  language: 'English',
  fullLegalName: '',
  dbaName: '',
  organizationType: 'forProfit',
  textExempt: 'no',
  entityType: '',
  taxId: '',
  taxIdValue: '',
};

export const topFormValidationSchema = Yup.object().shape({
  companyName: Yup.string().required(''),
  country: Yup.string().required(''),
  language: Yup.string().required(''),
  fullLegalName: Yup.string().required(''),
  dbaName: Yup.string(),
  organizationType: Yup.string().required(''),
  textExempt: Yup.string().required(''),
  entityType: Yup.string().required(''),
  taxId: Yup.string().required(''),
  taxIdValue: Yup.string().required('').min(9),
});

export const entityTypeItems = [
  {
    label: 'Sole Proprietor',
    value: 'soleProprietor',
  },
  {
    label: 'Partnership',
    value: 'partnership',
  },
  {
    label: 'LLC',
    value: 'LLC',
  },
  {
    label: 'S-Corporation',
    value: 'S-Corporation',
  },
  {
    label: 'C-Corporation',
    value: 'C-Corporation',
  },
];

export const taxIdItems = [
  {
    label: 'EIN / Employer Identification Number',
    value: 'EIN',
  },
  {
    label: 'SS / Social Security Number',
    value: 'SS',
  },
];

export const PhoneTypeItems = [
  {
    label: 'Work',
    value: 'Work',
  },
  {
    label: 'Office',
    value: 'Office',
  },

  {
    label: 'Fax',
    value: 'Fax',
  },
  {
    label: 'Cell',
    value: 'Cell',
  },
];

export const EmailTypeItems = [
  {
    label: 'Work',
    value: 'Work',
  },
  {
    label: 'Office',
    value: 'Office',
  },

  {
    label: 'Fax',
    value: 'Fax',
  },
  {
    label: 'Cell',
    value: 'Cell',
  },
];

export const topFormFormField = getRequiredFieldFromSchema(
  topFormValidationSchema,
);

export interface UplodaData {
  textExemptStatus: 'yes' | 'no';
  textExemptDocument: File | null;
}

export const uploadTextExemptInitialValue: UplodaData = {
  textExemptStatus: 'no',
  textExemptDocument: null,
};

export const uploadTextExemptValidationSchema = Yup.object().shape({
  textExemptStatus: Yup.string().required(''),
  textExemptDocument: Yup.mixed().required(''),
});

// * Contact Information form

export interface AddressData {
  addressType?: string;
  street1?: string;
  street2?: string;
  city?: string;
  state?: string;
  zipCode?: string;
  zipCodeExt?: string;
  country?: string;
}

export const addressInitialValues: AddressData = {
  street1: '',
  street2: '',
  city: '',
  state: '',
  zipCode: '',
  zipCodeExt: '',
  addressType: '',
  country: 'United States',
};

export const addressSchema = Yup.object().shape({
  addressType: Yup.string().required(''),
  street1: Yup.string().required(''),
  street2: Yup.string(),
  city: Yup.string().required(''),
  state: Yup.string().required(''),
  zipCode: Yup.string().required('').min(5, '').max(5, ''),
  zipCodeExt: Yup.string().min(4, '').max(4, ''),
  country: Yup.string().required(''),
});

export interface PhoneData {
  phoneType?: string;
  countryCode?: string;
  phoneNumber?: string;
  phoneExt?: string;
}

export const phoneInitialValues: PhoneData = {
  phoneType: '',
  countryCode: '+1',
  phoneNumber: '',
  phoneExt: '',
};

export const phoneSchema = Yup.object().shape({
  phoneType: Yup.string().required(''),
  countryCode: Yup.string().required(''),
  phoneNumber: Yup.string().required('').min(16, '').max(16, ''),
  phoneExt: Yup.string().min(3, '').max(3, ''),
});

export interface EmailData {
  emailType?: string;
  email?: string;
}
export const emailInitialValues: EmailData = {
  emailType: '',
  email: '',
};
export const emailSchema = Yup.object().shape({
  emailType: Yup.string().required(''),
  email: Yup.string()
    .test('is-valid', 'Email Address is Invalid', value =>
      checkIsValidEmail(value || ''),
    )
    .required(''),
});

export const contactInformationForm = {
  addresses: [
    {
      ...addressInitialValues,
      addressType: 'Primary Mailing Address',
    },
    {
      ...addressInitialValues,
      addressType: 'Primary Shipping Address',
    },
  ],
  phones: [
    {
      ...phoneInitialValues,
      phoneType: 'Primary',
    },
  ],
  emails: [
    {
      ...emailInitialValues,
      emailType: 'Primary',
    },
  ],
};

export const contactInformationSchema = Yup.object().shape({
  addresses: Yup.array().of(addressSchema).min(2).required(''),
  phones: Yup.array().of(phoneSchema).min(1).required(''),
  emails: Yup.array().of(emailSchema).min(1).required(''),
});

// * Branding Form

export const coreInitialValue = {
  label: '',
  value: '',
};

export const coreValueSchema = Yup.object().shape({
  label: Yup.string().required(''),
  value: Yup.string().required(''),
});

export const brandingSInitialValue = {
  vision: '',
  mission: '',
  coreValue: '',
};

export const brandingSchema = Yup.object().shape({
  uploadLogo: Yup.string(),
  tagLine: Yup.string(),
  vision: Yup.string(),
  mission: Yup.string(),
  coreValue: Yup.lazy(value =>
    typeof value === 'string' ? Yup.string() : Yup.array().of(coreValueSchema),
  ),
});
