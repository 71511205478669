import styled from '@emotion/styled';
import { constant } from '../../libs/constant';

export const TImeFieldWrapper = styled('div')(({ theme }) => ({
  display: 'inline-flex',
  alignItems: 'center',
  justifyContent: 'center',
  height: 36,
  boxShadow: '0px 0px 3px #A8B1B8',
  borderRadius: constant.inputSmallRadius,
  padding: 5,
  fontSize: 12,
  color: '#001F38',
  position: 'relative',
}));
