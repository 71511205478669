import React from 'react';
import { Add, AddHover, Plus } from '../../assets/svgs';
import { cn } from '../../libs/utils';
import { Divider } from '@mui/material';
import CosIcon from '../CosIcon';
import CosTooltip from '../CosTooltip';

interface Props {
  onClick: () => void;
  disable?: boolean;
  className?: string;
  toolTipText?: string;
}

const AddButton: React.FC<Props> = ({
  onClick,
  disable,
  className,
  toolTipText,
}) => {
  return (
    <div className={cn('relative flex w-full items-center', className)}>
      <p className="m-0 w-full border-0 border-b-[1px] border-solid border-blue-gray-200/[0.38] p-0 " />
      <CosTooltip title={toolTipText} placement="top" tabIndex={-1}>
        <CosIcon
          onHoverIcon={
            <Add className="size-[21px] rounded-full bg-dark-blue-900 text-white" />
          }
          onClick={onClick}
          disabled={disable}
          wrapperClass={
            'mx-[7px] flex justify-center items-center border-solid border border-[1px] border-blue-gray-900/50 rounded-full overfloe-hidden'
          }
        >
          <Add className="size-[21px]" />
        </CosIcon>
      </CosTooltip>
      <p className="m-0 w-full border-0 border-b-[1px] border-solid border-blue-gray-200/[0.38] " />
    </div>
  );
};

export default AddButton;
