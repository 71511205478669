import React, { memo, useMemo } from 'react';
import Navbar from './components/Navbar';
import Sidebar from './components/Sidebar';
import { Box, CssBaseline } from '@mui/material';
import DrawerHeader from './components/ui/AppBarLeftSideBarHeader';
import TempIconShow from './TempIconShow';
import { cn } from '../../libs/utils';
import { RouteObject } from 'react-router';

export interface MasterLayoutProps {
  children: React.ReactNode;
  navigatorList: RouteObject;
  layoutColor?: 'primary' | 'secondary';
  layoutType?: 'table' | 'normal';
}

const Masterlayout: React.FC<MasterLayoutProps> = ({
  children,
  layoutColor = 'primary',
  layoutType = 'normal',
  navigatorList,
}) => {
  const [open, setOpen] = React.useState(true);

  const toggleSideBar = () => {
    setOpen(preValue => !preValue);
  };

  const isTableLayout = useMemo(() => layoutType === 'table', [layoutType]);
  return (
    <>
      <Box sx={{ display: 'flex' }}>
        <CssBaseline />
        <Navbar open={open} navigatorList={navigatorList} />
        <Sidebar open={open} toggleSideBar={toggleSideBar} />

        <Box
          component="main"
          className={cn('flex-grow overflow-x-auto bg-white-whale-500 p-2', {
            'cos-secondary-scrollbar': !isTableLayout,
            'cos-primary-scrollbar pr-[2px]': isTableLayout,
          })}
        >
          <DrawerHeader />
          <Box
            sx={{
              background: isTableLayout ? '#EDEEEF' : '#ffffff',
              borderRadius: isTableLayout ? 0 : '4px',
              boxShadow: '0px 0px 4px #00000017',
              overflow: 'auto',
            }}
          >
            <Box
              sx={{
                display: 'flex',
                flexDirection: 'column',
                height: isTableLayout
                  ? 'calc(100vh - 116px)'
                  : 'calc(100vh - 132px)',
                margin: isTableLayout ? 0 : '8px 0',
                marginRight: '2px',
                marginLeft: isTableLayout ? 0 : '8px',
                paddingRight: isTableLayout ? 0 : '2px',
                scrollbarGutter: isTableLayout ? 'auto' : 'stable',
                overflow: 'auto',
                position: 'relative',
              }}
            >
              {children}
            </Box>
          </Box>
        </Box>
      </Box>
      <TempIconShow />
    </>
  );
};

export default memo(Masterlayout);
