import { styled } from '@mui/material';

const CosTableSpacer = styled('div')(({ theme }) => ({
  border: '2px solid #ffffff',
  borderBottomWidth: 1,
  borderTopWidth: '0',
  height: '100%',
  width: 'calc(100% - 6px)',
}));

export default CosTableSpacer;
