import React, { HTMLAttributes } from 'react';
import Dialog, { DialogProps } from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import Divider from '@mui/material/Divider';
import { alpha, styled } from '@mui/material/styles';
import { Theme } from '@emotion/react';
import { KEYBOARD_KEY, constant } from '../../libs/constant';
import clsx from 'clsx';

export interface CosBaseDialogProps extends DialogProps {
  heading?: React.ReactNode;
  children?: React.ReactNode;
  dialogActions?: React.ReactNode;
  width?: string;
  height?: string;
  className?: string;
  showDivider?: boolean;
  dividerClass?: HTMLAttributes<HTMLDivElement>['className'];
  actionClass?: HTMLAttributes<HTMLDivElement>['className'];
  bodyClass?: HTMLAttributes<HTMLDivElement>['className'];
  headingClass?: HTMLAttributes<HTMLDivElement>['className'];
  headerWrapperClass?: HTMLAttributes<HTMLDivElement>['className'];
  onClose?: (event: any) => void;
  onEsc?: (event: any) => void;
  onEnter?: (event: any) => void;
}

const StyledDialog = styled(Dialog, {
  shouldForwardProp: prop => prop !== 'width' && prop !== 'height',
})<{
  width?: string;
  height?: string;
}>(
  ({
    theme,
    width,
    height,
  }: {
    theme: Theme;
    width?: string;
    height?: string;
  }) => ({
    '& .MuiPaper-root': {
      width: width || 'auto',
      height: height || 'auto',
      boxShadow: '0px 0px 3px #a8b1b8',
      borderRadius: `${constant.modalRadius}px`,
      color: '#001F38',
    },
    '& .MuiDialogContent-root': {
      padding: '15px',
      marginRight: 2,
    },
    '& .MuiDialogActions-root': {
      padding: '10px 24px',
    },
    '& .MuiBackdrop-root': {
      backgroundColor: alpha('#001f38', 0.75),
      pointerEvents: 'none',
      '&.MuiBackdrop-clickable': {
        // Override pointer-events for the clickable part of the backdrop
        pointerEvents: 'none',
      },
    },
  }),
);
const CosBasDialog: React.FC<CosBaseDialogProps> = props => {
  const {
    open,
    onClose,
    heading,
    children,
    dialogActions,
    maxWidth,
    width,
    height,
    className,
    showDivider = false,
    dividerClass,
    actionClass,
    bodyClass,
    headingClass,
    headerWrapperClass,
    onEsc,
    onEnter,
    ...rest
  } = props;

  const handleClose = (event: React.SyntheticEvent, reason: string) => {
    // * Avoid esc and bacDrop event
    if (reason !== 'backdropClick' && reason !== 'escapeKeyDown') {
      onClose && onClose(event);
    }
  };

  const handleKeyDown: React.KeyboardEventHandler<
    HTMLButtonElement | HTMLDivElement
  > = event => {
    const key = event.key;
    switch (key) {
      case KEYBOARD_KEY.esc:
        onEsc?.(event);
        break;
      case KEYBOARD_KEY.enter:
        onEnter?.(event);
        break;
      default:
        break;
    }
  };

  return (
    <div>
      <StyledDialog
        open={open}
        onClose={handleClose}
        onKeyDown={handleKeyDown}
        width={width}
        height={height}
        className={className}
        maxWidth={maxWidth || 'md'}
        {...rest}
      >
        {heading && (
          <DialogTitle className={headingClass}>
            <div
              className={clsx(
                'flex items-start justify-between',
                headerWrapperClass,
              )}
            >
              <div className="font-blue h3 w-full">{heading}</div>
            </div>
          </DialogTitle>
        )}
        {showDivider && <Divider className={`m-0 ${dividerClass}`} />}
        <DialogContent className={bodyClass}>{children}</DialogContent>
        {dialogActions && (
          <DialogActions className={actionClass}>{dialogActions}</DialogActions>
        )}
      </StyledDialog>
    </div>
  );
};

export default CosBasDialog;
