import { useState } from 'react';
import { alpha, styled } from '@mui/material';
import { cn } from '../../libs/utils';
import {
  BrandingForm,
  ContactInformationForm,
  ProfileTopForm,
} from '../../modules/myCompany/profile';

const Tab = styled('div')(() => ({
  height: '21px',
  width: '100%',
  backgroundColor: alpha('#A8B1B8', 0.7),
  border: `0.25px solid ${alpha('#07B9EC', 0.25)}`,
  borderRadius: '0px 0px 5px 5px',
  textAlign: 'center',
  fontSize: '12px',
  lineHeight: '21px',
  color: '#001F38',
  textTransform: 'capitalize',
  padding: 0,
  cursor: 'pointer',

  '&:hover': {
    border: 0,
    backgroundColor: alpha('#03688E', 1),
    borderColor: alpha('#03688E', 1),
    color: alpha('#61BAD7', 1),
  },
  '&[aria-selected="true"]': {
    border: 0,
    backgroundColor: '#07B9EC',
    color: alpha('#FFFFFF', 1),
    pointerEvents: 'none',
  },
}));

const Profile = () => {
  const [isBranding, setIsBranding] = useState(false);
  const [isBrandingDisable, setIsBrandingDisable] = useState(true);
  const [isSecondFormActive, setIsSecondFormActive] = useState(false);

  const handleProfileTopFormSubmit = (value: any) => {
    setIsSecondFormActive(true);
  };

  return (
    <main className="h-full">
      <p className="m-0 mb-[5px] text-right text-11/15 text-error-900">
        * Required Fields
      </p>
      <section className="px-[7px]">
        <ProfileTopForm onSubmit={value => handleProfileTopFormSubmit(value)} />
      </section>
      <section
        className={cn({
          'pointer-events-none h-[calc(100%-247px)] opacity-25':
            !isSecondFormActive,
          'h-[calc(100%-247px)]': isSecondFormActive,
        })}
      >
        <div className="mb-profile-main mt-5 flex gap-[6px] px-[7px]">
          <Tab aria-selected={!isBranding} onClick={() => setIsBranding(false)}>
            Contact Information
          </Tab>
          <Tab
            aria-selected={isBranding}
            onClick={() => setIsBranding(true)}
            className={cn({
              'pointer-events-none opacity-25':
                isBrandingDisable && isSecondFormActive,
            })}
          >
            Branding / Structure
          </Tab>
        </div>

        <div className="h-full">
          {isBranding ? (
            <BrandingForm />
          ) : (
            <section className="h-full pl-[6px]">
              <ContactInformationForm
                isActive={isSecondFormActive}
                onSubmit={() => setIsBrandingDisable(false)}
              />
            </section>
          )}
        </div>
      </section>
    </main>
  );
};
export default Profile;
