import { Button } from '@mui/material';
import { CosAddNew, CosImport, CosSession } from '../components/dialogBox';
import StandardLayout from './StandardLayout';
import { useState } from 'react';
import { BuilderOps } from '../assets/svgs';
import CreateNewSchedule from '../modules/schedule/CreateNewSchedule';
import ViewSchedule from '../modules/schedule/ViewSchedule';

const label = ['Close', 'Confirm'];
const heading = 'Confirm Import (%Count%)';

const sessionAction = ['LogOut', 'Update'];
const sessionHeading = 'Session Expiring';

const StandardPopup = () => {
  const [importPopup, setImportPopup] = useState(false);
  const [sessionPopup, setSessionPopup] = useState(false);
  const [addNew, setAddNew] = useState(false);
  const [isCreate, setCreate] = useState(false);
  const [isView, setView] = useState(false);

  return (
    <StandardLayout>
      <div className="flex">
        <div className="px-4">
          <p>Import Popup</p>
          <Button variant="outlined" onClick={() => setImportPopup(true)}>
            Import
          </Button>
          <CosImport
            open={importPopup}
            button={label}
            heading={heading}
            onClose={() => setImportPopup(false)}
          >
            <>
              <div className="flex">
                <p className="m-0">Confirm you are ready to import data from</p>
                <BuilderOps className="ml-[5px]" />
              </div>
              <p className="m-0">
                library to create your company’s %Name% database
              </p>
            </>
          </CosImport>
        </div>
        <div className="px-4">
          <p>Session Popup</p>
          <Button variant="outlined" onClick={() => setSessionPopup(true)}>
            Session
          </Button>
          <CosSession
            open={sessionPopup}
            button={sessionAction}
            heading={sessionHeading}
            onClose={() => setSessionPopup(false)}
          ></CosSession>
        </div>
        <div className="px-4">
          <p>Add New Popup</p>
          <Button variant="outlined" onClick={() => setAddNew(true)}>
            Add New
          </Button>
          <CosAddNew open={addNew} onClose={() => setAddNew(false)}></CosAddNew>
        </div>
        <div className="px-4">
          <p>Create New Schedule</p>
          <Button variant="outlined" onClick={() => setCreate(true)}>
            Create
          </Button>
          {isCreate && <CreateNewSchedule></CreateNewSchedule>}
        </div>
        <div className="px-4">
          <p>View Schedule</p>
          <Button variant="outlined" onClick={() => setView(true)}>
            View
          </Button>
          {isView && <ViewSchedule></ViewSchedule>}
        </div>
      </div>
    </StandardLayout>
  );
};

export default StandardPopup;
