import React from 'react';
import StandardLayout from './StandardLayout';
import { CosTablePagination } from '../components/Tables';

const StandardPagination = () => {
  return (
    <StandardLayout>
      <p className="mb-1">With 2 page</p>
      <CosTablePagination count={2} />
      <p className="mb-1">With 10 page</p>
      <CosTablePagination count={10} />
    </StandardLayout>
  );
};

export default StandardPagination;
