import { useEffect, useLayoutEffect } from 'react';
import LoginForm from '../modules/auth/login/components/LoginForm';
import AuthLayout from '../layout/auth/AuthLayout';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { isMobile } from 'react-device-detect';
import {
  cleanAllLocalStorage,
  getLocalStorageItem,
  isDomainNameAvailable,
  lastActiveRouter,
} from '../libs/utils';
import { localStorageKey } from '../libs/constant';

const LoginPage = () => {
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();

  useEffect(() => {
    const userData = getLocalStorageItem(localStorageKey.USER_DATA);
    const email = searchParams.get('email');
    const isDomain = isDomainNameAvailable();
    if (!isDomain) {
      navigate('/register', { replace: true });
    } else if (userData) {
      if (email) {
        cleanAllLocalStorage();
      } else {
        navigate(lastActiveRouter, { replace: true });
      }
    }
  }, []);

  useLayoutEffect(() => {
    const isValidURL = isDomainNameAvailable();
    if (isMobile) {
      navigate('/pageinfo', { replace: true });
    } else if (!isValidURL) {
      navigate('/register', { replace: true });
    }
  }, []);

  return (
    <AuthLayout>
      <LoginForm onFormSubmit={() => {}} />
    </AuthLayout>
  );
};

export default LoginPage;
