import { RouteObject } from 'react-router';
import {
  StandardSwitchPage,
  StandardHorizontalTab,
  StandardDropdownPage,
  StandardTopHeader,
  StandardScrollBar,
  StandardPageScrollbar,
  StandardPageButton,
  StandardPageCheckbox,
  StandardPageRadio,
  StandardPageTooltip,
  StandardPageArrow,
  StandardHorizontalScrollBar,
  StandardTextField,
  StandardNumberField,
  StandardDataTable,
  StandardActionBtn,
  StandardSearchBar,
  StandardTimeInput,
  StandardPopup,
  StandardPagination,
  StandardVerticalTab,
} from '..';
import { isLocalEnv, isStandardEnv } from '../../libs/utils';
import comingSoonCompanyHandler from './comingSoonCompanyHandler';
import { comingSoonUsersHandler } from './comingSoonUsersHandler';
import { comingSoonSettingHandler } from './comingSoonSettingHandler';
import StandardModalActionBtn from '../StandardModalActionBtn';

const routers: RouteObject[] = [
  {
    path: '/standard',
    children: [
      {
        path: '',
        element: <StandardSwitchPage />,
      },
      {
        path: 'switch',
        element: <StandardSwitchPage />,
      },
      {
        path: 'horizontal_tab',
        element: <StandardHorizontalTab />,
      },
      {
        path: 'vertical_tab',
        element: <StandardVerticalTab />,
      },
      {
        path: 'dropdown',
        element: <StandardDropdownPage />,
      },
      {
        path: 'top-header',
        element: <StandardTopHeader />,
      },
      {
        path: 'scrollbar',
        element: <StandardScrollBar />,
      },
      {
        path: 'horizontal_scrollbar',
        element: <StandardHorizontalScrollBar />,
      },
      {
        path: 'page_content_with_scrollbar',
        element: <StandardPageScrollbar />,
      },
      {
        path: 'button',
        element: <StandardPageButton />,
      },
      {
        path: 'checkbox',
        element: <StandardPageCheckbox />,
      },
      {
        path: 'radio',
        element: <StandardPageRadio />,
      },
      {
        path: 'tooltip',
        element: <StandardPageTooltip />,
      },
      {
        path: 'arrow',
        element: <StandardPageArrow />,
      },
      {
        path: 'text_fields',
        element: <StandardTextField />,
      },
      {
        path: 'number_fields',
        element: <StandardNumberField />,
      },
      {
        path: 'data-table',
        element: <StandardDataTable />,
      },
      {
        path: 'icon',
        element: <StandardActionBtn />,
      },
      {
        path: 'searchBar',
        element: <StandardSearchBar />,
      },
      {
        path: 'timeInput',
        element: <StandardTimeInput />,
      },
      {
        path: 'popup',
        element: <StandardPopup />,
      },
      {
        path: 'modalActionBtn',
        element: <StandardModalActionBtn />,
      },
      {
        path: 'pagination',
        element: <StandardPagination />,
      },
    ],
  },
  comingSoonCompanyHandler,
  comingSoonUsersHandler,
  comingSoonSettingHandler,
];

const standardRouterHandler: RouteObject[] =
  isStandardEnv() || isLocalEnv() ? routers : [];

export default standardRouterHandler;
