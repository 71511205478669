import { useEffect, useRef, useState } from 'react';
import { CloseIcon, CosBasDialog } from '../components/dialogBox';
import { constant } from '../libs/constant';
import { SliderTab } from '../modules/auth/authentication/Components';
import {
  OTPVerificationForm,
  RegisterYourCompanyForm,
} from '../modules/auth/authentication';
import { Box } from '@mui/material';
import {
  cleanAllLocalStorage,
  isDomainNameAvailable,
  isQaEnv,
} from '../libs/utils';
import { useNavigate } from 'react-router-dom';

const Authentication = () => {
  const [formNumber, setFormNumber] = useState(0);
  const formValueRef = useRef<any>();

  useEffect(() => {
    const isDomain = isDomainNameAvailable();
    const isQA = isQaEnv();
    if (isDomain && process.env.NODE_ENV === 'production' && !isQA) {
      window.open('https://sch-ops.com/register', '_self');
    }
  }, []);

  return (
    <CosBasDialog
      className="dialogsetting"
      open={true}
      width="737px"
      height="646px"
      headerWrapperClass={'items-baseline'}
      headingClass="p-0 pt-5 sm:mx-[79px] mx-[30px]"
      bodyClass="p-0 sm:pl-[79px] pl-[30px]"
      heading={
        <div>
          <p className="m-0 text-center font-controller text-24/29 font-semibold -tracking-0.43">
            <span>
              You're only{' '}
              <span className="inline-block w-[1ch]">{2 - +formNumber}</span>
              &nbsp;steps away from starting your free &nbsp;
              {constant.freeTrailDays} day trial
            </span>
            <CloseIcon className="absolute right-[8px] top-2 sm:top-5 lg:right-[17px]" />
          </p>
          <SliderTab defaultValue={formNumber} className="mt-[34px]" disable />
        </div>
      }
      sx={{
        '.MuiPaper-root': {
          borderRadius: constant.messageModalRadius + 'px',
          boxShadow: '0 0 12px #A8B1B8',
          background: '#EDEEEF',
        },
      }}
    >
      <div>
        {formNumber === 0 && (
          <Box component="section" className="mt-[50px]">
            <RegisterYourCompanyForm
              onNext={formValue => {
                formValueRef.current = formValue;
                setFormNumber(1);
              }}
            />
          </Box>
        )}
        {formNumber === 1 && (
          <Box component="section" className="mt-7 pr-[30px] sm:pr-[79px]">
            <OTPVerificationForm formValue={formValueRef.current} />
          </Box>
        )}
      </div>
    </CosBasDialog>
  );
};

export default Authentication;
