import React, { useEffect, useRef, useState } from 'react';
import { Tab, TabScrollButton, Tabs, alpha, styled } from '@mui/material';
import { handleScrollButtonClick, commonTabsSX } from './tab.util';
import useTabScroll from '../../libs/hooks/useTabScroll';

const BpTab = styled(Tabs)(({}) => ({
  maxWidth: '1207px',
  ...commonTabsSX,
  '& .snapScrollClass': {
    gap: '5px',
    scrollSnapType: 'x mandatory !important',
  },
  '& .mainSnapScrollClass': {
    display: 'flex',
    flexDirection: 'row',
    scrollSnapType: 'x mandatory',
    scrollBehavior: 'smooth',
    scrollSnapAlign: 'start',
  },
  '& .MuiTabs-scrollButtons': {
    color: '#07B9EC',
  },
  '& .MuiTab-root': {
    scrollSnapAlign: 'start',
    height: '21px',
    minWidth: '190px',
    minHeight: '21px',
    backgroundColor: alpha('#A8B1B8', 0.7),
    border: `0.25px solid ${alpha('#07B9EC', 0.25)}`,
    borderRadius: '0px 0px 5px 5px',
    textAlign: 'center',
    fontSize: '12px',
    lineHeight: '21 px',
    color: '#001F38',
    textTransform: 'capitalize',
    padding: 0,

    '&:hover': {
      border: 0,
      backgroundColor: alpha('#03688E', 1),
      borderColor: alpha('#03688E', 1),
      color: alpha('#61BAD7', 1),
    },
  },
  '& .MuiTab-root[aria-selected="true"]': {
    border: 0,
    backgroundColor: '#07B9EC',
    color: alpha('#FFFFFF', 1),
    fontWeight: 600,
  },
}));

const CosPageContentTab: React.FC<CosTabs> = ({ tabs, ...rest }) => {
  const [value, setValue] = useState(0);

  const handleChange = (event: any, newValue: number) => {
    setValue(newValue);
  };

  const tabsRef = useRef<HTMLDivElement | null>(null);

  useTabScroll(tabsRef);

  return (
    <BpTab
      value={value}
      onChange={handleChange}
      variant="scrollable"
      selectionFollowsFocus={true}
      {...rest}
      ScrollButtonComponent={({ ...rest }) => {
        return (
          <TabScrollButton
            {...rest}
            onClick={() =>
              handleScrollButtonClick(tabsRef.current, rest.direction)
            }
          />
        );
      }}
      ref={tabsRef}
    >
      {tabs.map((item: CosTab, index: number) => (
        <Tab
          label={item.label}
          key={`${index}-${item.label}-page-tab`}
          className="snap-start"
          // value={item.value}
        />
      ))}
      ;
    </BpTab>
  );
};

export default CosPageContentTab;
