import React, { useState } from 'react';
import { CosCheckbox } from '../formikForms/ui';
import CosPrimaryNumberField from '../formikForms/ui/numberField/CosPrimaryNumberField';
import { Box } from '@mui/material';

interface Props {
  contentBody?: React.ReactNode;
  children?: React.ReactNode;
}

const CosSessionInput: React.FC<Props> = ({ children, ...rest }) => {
  const [isError, setError] = useState(false);
  const InputChange = (event: any) => {
    event >= 8 ? setError(true) : setError(false);
  };

  return (
    <Box className="text-center text-13/18">
      <p className="m-0 pb-[5px]">
        You have %13 minutes% to extend your session before you are logged out.
      </p>
      <div>
        <p className="m-0 pb-[5px]">Extend my Session</p>
        <span className="flex items-center justify-center font-semibold">
          By
          <div className="mx-[5px]">
            <CosPrimaryNumberField
              max={8}
              defaultValue={1}
              onChange={InputChange}
            />
          </div>
          Hour
        </span>
      </div>
      {isError && (
        <p className="m-0 py-[4px] font-semibold text-error-900">
          You can't extend this more than %8% hours
        </p>
      )}
      <p className={'m-0 flex justify-center ' + (isError ? '' : 'pt-[26px]')}>
        <CosCheckbox className="pr-[8px]" /> Make this your default setting.
      </p>
    </Box>
  );
};

export default CosSessionInput;
