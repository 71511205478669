import React, { memo, useRef, useState } from 'react';
import AddNewAddress from './AddNewAddress';
import AddButton from '../../../components/buttons/AddButton';
import { Typography } from '@mui/material';
import { CosCountBadge } from '../../../components';
import { FieldArray, useFormikContext } from 'formik';
import FieldWithLabel from '../components/FieldWithLabel';
import { FormInput, FormTextArea } from '../../../components/formikForms';
import { CosCheckbox } from '../../../components/formikForms/ui';
import { contactInformationForm } from '../formConfig/profileForm';
import { camelCaseToTitleCase } from '../../../libs/utils';

const commonSx = {
  minWidth: 'auto',
};

interface Props {
  name: string;
  isViewMode: boolean;
  isActive: boolean;
}

const AddressSection: React.FC<Props> = ({ name, isViewMode, isActive }) => {
  const [addNewAddress, setAddNewAddress] = useState(false);
  const [isShippingAddressSame, setIShippingAddressSame] = useState(false);

  const wrapperRef = useRef<HTMLDivElement>(null);

  const labelClass = 'text-14/19 font-semibold text-dark-blue-900';
  const valueClass = 'text-14/15 h-[26px]';

  const { setFieldValue, dirty, values, errors }: any = useFormikContext();
  const value = values[name];
  const isValid = !(dirty && !errors[name]);

  const handleZipCodeInput = (
    event: React.ChangeEvent<HTMLInputElement>,
    index: number,
    type: 'zipCode' | 'zipCodeExt',
  ) => {
    const { value } = event.target;
    const maxValue = type === 'zipCode' ? 5 : 4;
    if (value.length <= maxValue) {
      setFieldValue(`${name}.${index}.${type}`, value);
    } else {
      setFieldValue(`${name}.${index}.${type}`, value.slice(0, maxValue));
    }
  };

  const handleOnBlur = (filedName: string, index: number) => {
    isShippingAddressSame &&
      setFieldValue(`${name}.1.${filedName}`, value[0][filedName]);
  };

  const handleMailingCheckBox = () => {
    const { addressType, ...rest } = value[0];
    setIShippingAddressSame(preValue => !preValue);
    setFieldValue(`${name}.1`, {
      addressType: contactInformationForm.addresses[1].addressType,
      ...rest,
    });
  };
  const renderFormFiledValue = (value: string) => (
    <Typography className="text-14/15">
      {camelCaseToTitleCase(value)}
    </Typography>
  );
  // console.log('value', value);
  // console.log('value.name', value[0].street1);

  return (
    <>
      <FieldArray name={name}>
        {({ remove, push }) => (
          <>
            <Typography
              component="h6"
              className="mb-profile-8px flex items-center gap-[5px] text-16/22 font-semibold"
            >
              Addresses
              {isActive && <CosCountBadge count={value.length} />}
            </Typography>
            <section
              className="max-h-[120px] min-h-[120px] overflow-auto px-[1px]"
              ref={wrapperRef}
            >
              {isViewMode ? (
                <section className="mr-[4px] grid grid-cols-3 gap-x-[30px] gap-y-[14px]">
                  {value.length > 0 &&
                    value.map((address: any, index: number) => (
                      <div className="pb-[1px]" key={`${index}-address`}>
                        <FieldWithLabel
                          label={address.addressType}
                          required={index > 1 ? false : true}
                        >
                          <div className="flex gap-[15px]">
                            <div className="flex w-full flex-col gap-[5px]">
                              <Typography className={`${labelClass}`}>
                                Street
                              </Typography>
                              <Typography className={`${valueClass}`}>
                                {address.street1}
                                {address.street2}
                              </Typography>
                              <Typography className={`${labelClass}`}>
                                State
                              </Typography>
                              <Typography className={`!h-fit ${valueClass}`}>
                                {address.state}
                              </Typography>
                            </div>
                            <div className="flex w-full flex-col gap-[5px]">
                              <div className="flex w-full justify-between gap-[8px]">
                                <div className="flex flex-col gap-[5px]">
                                  <Typography className={`${labelClass}`}>
                                    City
                                  </Typography>
                                  <Typography className={`${valueClass}`}>
                                    {address.city}
                                  </Typography>
                                </div>
                                <div className="flex flex-col gap-[5px]">
                                  <Typography className={`${labelClass}`}>
                                    Country
                                  </Typography>
                                  <Typography className={`${valueClass}`}>
                                    {address.country}
                                  </Typography>
                                </div>
                              </div>
                              <div className="flex w-full flex-col gap-[5px]">
                                <Typography className={`${labelClass}`}>
                                  Zip Code - Ext.
                                </Typography>
                                <Typography className={`!h-fit ${valueClass}`}>
                                  {address.zipCode}
                                  <span className="mx-[2px]">-</span>
                                  {address.zipCodeExt}
                                </Typography>
                              </div>
                            </div>
                          </div>
                        </FieldWithLabel>
                      </div>
                    ))}
                </section>
              ) : (
                <section className="mr-[4px] grid grid-cols-2 gap-x-[30px] gap-y-[14px]">
                  {value.length > 0 &&
                    value.map((address: any, index: number) => (
                      <div className="pb-[1px]" key={`${index}-address`}>
                        <FieldWithLabel
                          label={address['addressType']}
                          required={index > 1 ? false : true}
                          onRemove={index > 1 ? () => remove(index) : false}
                          labelRightEndText={
                            index === 1 && (
                              <div className="ml-auto">
                                <CosCheckbox
                                  tabIndex={-1}
                                  label="Check if same as Primary Mailing Address"
                                  id={`${index}-same as primary`}
                                  labelProps={{
                                    className:
                                      'text-11/15 text-blue-gray-100 cursor-pointer',
                                    required: false,
                                  }}
                                  checked={isShippingAddressSame}
                                  onChange={() => handleMailingCheckBox()}
                                />
                              </div>
                            )
                          }
                        >
                          <div className="flex gap-[15px]">
                            <div className="flex w-full flex-col gap-[5px]">
                              <FormTextArea
                                name={`${name}.${index}.street1`}
                                placeholder="Enter Street (Line 1)"
                                className="!mb-0"
                                fullWidth
                                readOnly={index === 1 && isShippingAddressSame}
                                onBlur={() => handleOnBlur('street1', index)}
                              />
                              <FormTextArea
                                name={`${name}.${index}.street2`}
                                placeholder="Enter Street (Line 2)"
                                className="!mb-0"
                                fullWidth
                                readOnly={index === 1 && isShippingAddressSame}
                                onBlur={() => handleOnBlur('street2', index)}
                              />
                            </div>
                            <div className="flex w-full flex-col gap-[5px]">
                              <FormInput
                                size="small"
                                className="!mb-0"
                                fullWidth
                                inputSx={commonSx}
                                name={`${name}.${index}.city`}
                                placeholder="Enter City"
                                readOnly={index === 1 && isShippingAddressSame}
                                onBlur={() => handleOnBlur('city', index)}
                              />
                              <div className="flex w-full gap-[8px]">
                                <FormInput
                                  size="small"
                                  className="!mb-0 w-1/2"
                                  fullWidth
                                  inputSx={commonSx}
                                  name={`${name}.${index}.state`}
                                  placeholder="Enter State"
                                  readOnly={
                                    index === 1 && isShippingAddressSame
                                  }
                                  onBlur={() => handleOnBlur('state', index)}
                                />
                                <div className="flex w-1/2">
                                  <FormInput
                                    size="small"
                                    className="!mb-0"
                                    inputSx={commonSx}
                                    name={`${name}.${index}.zipCode`}
                                    placeholder="Zip Code"
                                    type="number"
                                    onChange={(event: any) =>
                                      handleZipCodeInput(
                                        event,
                                        index,
                                        'zipCode',
                                      )
                                    }
                                    readOnly={
                                      index === 1 && isShippingAddressSame
                                    }
                                    onBlur={() =>
                                      handleOnBlur('zipCode', index)
                                    }
                                  />
                                  <span className="mx-[2px]">-</span>
                                  <FormInput
                                    size="small"
                                    className="!mb-0"
                                    inputSx={{
                                      ...commonSx,
                                      textAlign: 'center',
                                    }}
                                    name={`${name}.${index}.zipCodeExt`}
                                    placeholder="Ext."
                                    type="number"
                                    onChange={(event: any) =>
                                      handleZipCodeInput(
                                        event,
                                        index,
                                        'zipCodeExt',
                                      )
                                    }
                                    readOnly={
                                      index === 1 && isShippingAddressSame
                                    }
                                    onBlur={() =>
                                      handleOnBlur('zipCodeExt', index)
                                    }
                                  />
                                </div>
                              </div>
                              <FormInput
                                size="small"
                                className="!mb-0"
                                fullWidth
                                inputSx={commonSx}
                                name={`${name}.${index}.country`}
                                placeholder="Enter Country"
                                readOnly
                              />
                            </div>
                          </div>
                        </FieldWithLabel>
                      </div>
                    ))}
                </section>
              )}
            </section>

            <AddNewAddress
              open={addNewAddress}
              onClose={() => setAddNewAddress(false)}
              onApply={value => {
                value.map(item => push(item));
                setTimeout(() => {
                  wrapperRef?.current?.scrollTo({
                    top: wrapperRef.current.scrollHeight,
                    behavior: 'smooth',
                  });
                });
              }}
            />
          </>
        )}
      </FieldArray>
      {isViewMode ? (
        <div className="flex max-h-[23px] min-h-[23px] items-center">
          <p className="m-0 w-full border-0 border-b-[1px] border-solid border-blue-gray-200/[0.38] " />
        </div>
      ) : (
        <AddButton
          onClick={() => setAddNewAddress(true)}
          disable={isValid}
          toolTipText={'Add Address'}
        />
      )}
    </>
  );
};

export default memo(AddressSection);
