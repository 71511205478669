import { Outlet, RouteObject } from 'react-router';
import { MyCompany, Profile } from '../pages/myCompany';

const companyRouterHandler: RouteObject = {
  path: '/my_company',
  element: <MyCompany />,
  children: [
    {
      path: '',
      element: <></>,
    },
    {
      path: 'profile',
      element: <Profile />,
    },
    {
      path: 'database',
      element: (
        <>
          <Outlet />
        </>
      ),
      children: [
        {
          path: 'work_schedules',
        },
        {
          path: 'holidays',
        },
      ],
    },
    {
      path: 'internal_resource',
      element: <></>,
    },
    {
      path: 'default_setting',
      element: <></>,
    },
  ],
};

export default companyRouterHandler;
