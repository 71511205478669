import { Box, BoxProps, Typography } from '@mui/material';
import React from 'react';
import { constant } from '../../../../libs/constant';
import clsx from 'clsx';
import { cn } from '../../../../libs/utils';

interface Props extends BoxProps {
  children: React.ReactNode;
  active?: boolean;
}

const NaVigatorTextItem: React.FC<Props> = ({
  children,
  className,
  active,
  sx,
  ...rest
}) => {
  return (
    <Box
      className={cn(
        'flex items-center border-0 border-b-[3px] border-solid border-transparent pt-[8px] text-12/17 font-semibold -tracking-0.22 text-white transition-all duration-300 hover:text-artist-blue-900 focus:text-artist-blue-900',
        {
          'border-artist-blue-900 !text-artist-blue-900': active,
        },
        className,
      )}
      sx={{
        height: constant.navbarBottomHeight,
        fontSize: '12px',
        lineHeight: '17px',
        padding: constant.navBarItemBorderWiderSpace + 'px',
        boxSizing: 'border-box',
        ...sx,
      }}
      {...rest}
    >
      {children}
    </Box>
  );
};

export default NaVigatorTextItem;
