import { createBrowserRouter, Navigate } from 'react-router-dom';
import companyRouterHandler from './companyRouterHandler';
import standardRouterHandler from '../standardUIpage/routerHandler/standardRouterHandler';
import authRouterHandler from './authRouterHandler';
import CompanyInfo from '../pages/Pageinfo';
import userRouterHandler from './userRouterHandler';
import settingsRouterHandler from './settingRouterHandler';
import { lastActiveRouter } from '../libs/utils';

const mergeRouter = createBrowserRouter([
  ...standardRouterHandler,
  companyRouterHandler,
  authRouterHandler,
  userRouterHandler,
  settingsRouterHandler,
  {
    path: '/',
    element: <Navigate to={lastActiveRouter} />,
  },
  {
    path: '*',
    element: <Navigate to="/login" replace={true} />,
  },
  {
    path: '/pageinfo',
    element: <CompanyInfo />,
  },
]);

export default mergeRouter;
