import React from 'react';
import CosTooltip from '../CosTooltip';
import {
  Eye,
  HideEye,
  CloseHover,
  CloseUnfill,
  editBlue,
  editWhite,
  editWhiteHover,
  editBlueHover,
} from '../../assets/svgs';

interface Props {
  index: number;
  onEditClick: () => void;
  onViewClick: () => void;
  onDeleteClick: () => void;
}

const CosTableActionButton: React.FC<Props> = ({ index }) => {
  return (
    <div className="cosAction-cell">
      <CosTooltip
        title={'View'}
        placement="left"
        aria-label={`view-${index}`}
        id={`view-${index}`}
        onHoverIcon={
          <HideEye
            className={
              (index + 1) % 2 === 0
                ? '!text-dark-blue-900'
                : '!text-artist-blue-900'
            }
          />
        }
      >
        <Eye className="!outline-0" />
      </CosTooltip>
      <CosTooltip
        title={'Edit'}
        placement="top"
        aria-label={`edit-${index}`}
        id={`edit-${index}`}
        onHoverIcon={
          <img
            src={(index + 1) % 2 === 0 ? editWhiteHover : editBlueHover}
            style={{ width: 15, height: 15 }}
          />
        }
      >
        <img
          src={(index + 1) % 2 === 0 ? editWhite : editBlue}
          style={{ width: 15, height: 15 }}
          className="!outline-0"
        />
      </CosTooltip>
      <CosTooltip
        title={'Delete'}
        placement="top"
        aria-label={`delete-${index}`}
        id={`delete-${index}`}
        onHoverIcon={<CloseHover />}
      >
        <CloseUnfill className="!outline-0" />
      </CosTooltip>
    </div>
  );
};

export default CosTableActionButton;
