import { useState } from 'react';
import { CosDataView } from '../../components/dialogBox';
import { CosButton } from '../../components';
import { Divider } from '@mui/material';

const dataViewAction = ['Close', 'Edit', 'Delete'];
const dataHeading = 'Work Schedule';

const ViewSchedule = () => {
  const [isCreate, setCreate] = useState(true);

  const handleClose = () => {
    setCreate(false);
  };

  return (
    <CosDataView
      open={isCreate}
      modalWidth={'580px'}
      modalHeight={'494px'}
      heading={dataHeading}
      isPagination={true}
      dialogAction={
        <>
          <CosButton
            size="medium"
            variant="text"
            onClick={handleClose}
            className="min-h-auto m-0"
          >
            {dataViewAction ? dataViewAction[0] : 'Close'}
          </CosButton>
          <Divider
            className="opacity-1 m-0 bg-artist-blue-900"
            flexItem
            orientation="vertical"
          ></Divider>
          <div className="relative w-1/2">
            <div className="absolute -bottom-3 right-0 w-[120px]">
              <CosButton
                size="medium"
                variant="text"
                onClick={handleClose}
                className="min-h-auto m-0 "
              >
                {dataViewAction ? dataViewAction[1] : 'Edit'}
              </CosButton>
            </div>
          </div>
          <Divider
            className="opacity-1 m-0 bg-artist-blue-900"
            flexItem
            orientation="vertical"
          ></Divider>
          <CosButton
            size="medium"
            variant="text"
            onClick={handleClose}
            className="min-h-auto m-0 text-error-900"
          >
            {dataViewAction ? dataViewAction[2] : 'Delete'}
          </CosButton>
        </>
      }
    ></CosDataView>
  );
};

export default ViewSchedule;
