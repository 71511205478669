import React from 'react';
import StandardDashboard from './StandardDashboard';
import { Outlet } from 'react-router';
import { comingSoonUsersHandler } from './routerHandler/comingSoonUsersHandler';

const ComingSoonPageUsers = () => {
  return (
    <StandardDashboard navigatorList={comingSoonUsersHandler}>
      <Outlet />
    </StandardDashboard>
  );
};

export default ComingSoonPageUsers;
