import React from 'react';
import { Box } from '@mui/material';
import { CosBaseDialogProps } from './CosBasDialog';
import Counter, {
  CountProps,
} from '../../modules/auth/register/components/counter/Counter';
import images from '../../assets/images';
import { BuilderOps } from '../../assets/svgs';

interface Props extends CosBaseDialogProps {
  contentBody?: React.ReactNode;
  children?: React.ReactNode;
  afterConfiguring?: Function;
  countProps?: Omit<CountProps, 'startFrom' | 'upto'>;
}

const CosImportProgress: React.FC<Props> = ({
  children,
  countProps,
  afterConfiguring,
  ...rest
}) => {
  return (
    <>
      <p className="mb-1.5 mt-0 text-center text-18/24 font-semibold leading-5 text-buddha-love-800">
        {/* // TODO :  manage dynamic counter */}
        Optimizing
        <Counter
          upto="100"
          startFrom="40"
          afterCompleted={afterConfiguring}
          {...countProps}
        />
        %
      </p>
      <div className="mb-1.5 ml-1 flex justify-center">
        <div className="h-[94px] w-[94px] rounded-full bg-[#091F38]">
          <img src={images.yellowGif} />
        </div>
      </div>
      <Box className="text-center text-13/18">
        <div className="flex justify-center">
          <p className="m-0">Importing from</p>
          <BuilderOps className="mx-[5px]" />
          <p className="m-0">%Name% library is in</p>
        </div>
        <span>
          progress. In the next steps you will be able to review and customize
          your %Name% database for your company.
        </span>
      </Box>
    </>
  );
};

export default CosImportProgress;
