import * as React from 'react';
import MenuItem from '@mui/material/MenuItem';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import { IconButton, ListItemText, alpha } from '@mui/material';
import { CosCheckbox } from '../formikForms/ui';
import { ExpandArrowBtn } from '../arrow';
import { cn } from '../../libs/utils';

const MenuProps = {
  disablePortal: true,
  PaperProps: {
    sx: {
      boxShadow: '0px 0px 3px #A8B1B8',
      borderRadius: '5px',
      width: 120,
      height: 117,
    },
  },
  sx: {
    '& .MuiList-padding': {
      padding: '6px 11px 6px 10px',
      display: 'flex',
      flexDirection: 'column',
      gap: '5px',
    },
    '& .MuiMenuItem-root': {
      backgroundColor: 'transparent !important',
      height: 17,
      minHeight: 'unset',
      padding: 0,
      gap: '5px',
      color: '#001F38',
      '&:hover, &:focus': {
        color: '#07B9EC',
        '& .COS-Unchecked-box': {
          backgroundColor: alpha('#07B9EC', 1),
          borderColor: alpha('#07B9EC', 0.5),
        },
        '& .COS-checked-box': {
          backgroundColor: alpha('#07B9EC', 0.75),
          color: alpha('#07B9EC', 0.75),
        },
      },
    },
    '& .MuiListItemText-primary': {
      fontSize: 12,
    },
    '& .Mui-selected.MuiMenuItem-root': {
      '&:hover, &:focus': {
        color: alpha('#07B9EC', 0.75),
      },
      color: '#07B9EC',
    },
  },
};

const Options = [
  { label: 'Show (10)', value: '10' },
  { label: 'Show (25)', value: '25' },
  { label: 'Show (50)', value: '50' },
  { label: 'Show (100)', value: '100' },
  { label: 'Show (All)', value: 'all' },
];

export default function ControlledOpenSelect() {
  const [parPage, setParPage] = React.useState<number | string>('10');
  const [open, setOpen] = React.useState(false);

  const handleChange = (event: SelectChangeEvent<typeof parPage>) => {
    setParPage(event.target.value);
  };

  const toggle = () => setOpen(preValue => !preValue);
  return (
    <div className="flex items-center gap-[15px]">
      <Select
        value={parPage}
        onChange={handleChange}
        renderValue={selected =>
          'SHOW ' +
          Options.find(
            option => selected.toString() === option.value.toString(),
          )?.value
        }
        MenuProps={MenuProps}
        IconComponent={() => (
          <IconButton className="hover:bg-transparent" onClick={() => toggle()}>
            <ExpandArrowBtn
              className={cn(
                'size-3 text-artist-blue-900 duration-[0.3s] ease-[ease]',
                {
                  'rotate-180': open,
                },
              )}
            />
          </IconButton>
        )}
        open={open}
        onClose={toggle}
        onOpen={toggle}
        sx={{
          '&.MuiInputBase-root': {
            height: '25px',
            width: '120px',
            outline: '0 !important',
            border: '0 !important',
            backgroundColor: '#EDEEEF',
            color: '#07B9EC',
            padding: 0,
            fontSize: 12,
            fontWeight: 600,
            paddingLeft: '10px',
            justifyContent: 'center',
          },
          '& .MuiOutlinedInput-input': {
            textTransform: 'uppercase',
            width: 'auto',
            padding: '0 !important',
            outline: '0 !important',
            border: '0 !important',
          },
          '& .MuiOutlinedInput-notchedOutline': {
            padding: 0,
            outline: '0 !important',
            border: '0 !important',
          },
        }}
      >
        {Options.map((option, index) => (
          <MenuItem key={`${index}-${option.value}`} value={option.value}>
            <CosCheckbox checked={parPage === option.value} />
            <ListItemText primary={option.label} />
          </MenuItem>
        ))}
      </Select>

      {/* // TODO : manage dynamically */}
      <p className="m-0 text-12/17 text-artist-blue-900">
        SHOWING 1 - 50 OF 80 RESULTS
      </p>
    </div>
  );
}
