import clsx from 'clsx';
import { useEffect, useRef, useState } from 'react';
import CosInput, { CosInputProps } from './CosInput';
import styled from '@emotion/styled';
import { Search, SearchYellowIcon } from '../../../assets/svgs';
import SvgWrapper from '../../SvgWrapper';

const CosSearchInput: React.FC<CosInputProps> = ({
  className,
  inputSx,
  ...rest
}) => {
  const inputRef = useRef<HTMLInputElement>();
  return (
    <>
      <div className={clsx('relative inline-block w-[200px]')}>
        <CosInput
          className={clsx('!mb-0 min-w-[200px]', className)}
          inputSx={{
            height: '25px',
            border: 0,
            fontSize: '12px',
            lineHeight: '16px',
            paddingRight: '33px',
            paddingLeft: 10,
            minWidth: 'unset',
            ...inputSx,
          }}
          fullWidth
          inputRef={inputRef}
          {...rest}
        />
        <span className="absolute right-0 top-1/2 h-full translate-y-[-37%] px-2">
          <SearchYellowIcon
            className="color-animation cursor-pointer text-dark-blue-900 hover:text-buddha-love-800"
            onClick={() => {
              inputRef.current?.focus();
            }}
          />
        </span>
      </div>
    </>
  );
};
export default CosSearchInput;
