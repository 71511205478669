import React from 'react';
import { ChevronArrowBtn } from '../../../arrow';
import { Box, BoxProps } from '@mui/material';

interface Props extends BoxProps {
  onIncrement: Function;
  onDecrement: Function;
  isDisableDecrement?: boolean;
  isDisableIncrement?: boolean;
}

const IncrementDecrementBtn: React.FC<Props> = props => {
  const {
    onIncrement,
    onDecrement,
    isDisableDecrement,
    isDisableIncrement,
    ...rest
  } = props;
  return (
    <Box className="flex flex-col gap-1" {...rest}>
      <span
        onClick={() => !isDisableDecrement && onDecrement()}
        className="order-1 inline-flex"
      >
        <ChevronArrowBtn disable={isDisableDecrement} open={false} />
      </span>
      <span
        onClick={() => !isDisableIncrement && onIncrement()}
        className="inline-flex"
      >
        <ChevronArrowBtn disable={isDisableIncrement} open={true} />
      </span>
    </Box>
  );
};

export default IncrementDecrementBtn;
