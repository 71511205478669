import { Form, Formik, FormikValues } from 'formik';
import React, { useLayoutEffect, useState } from 'react';
import useLoginFrom from '../hooks/useLoginFrom';
import { Typography } from '@mui/material';
import { FormCheckbox, FormPassword } from '../../../../components/formikForms';
import { CosButton, CosFormErrorAlert } from '../../../../components';
import Rest from '../../../../Rest';
import { CustomError } from '../../../../libs/interface';
import { useNavigate } from 'react-router-dom';
import { setLocalStorageItem } from '../../../../libs/utils';
import './LoginForm.css';
import { CosLabel } from '../../../../components/formikForms/ui';
import { QuestionRed, QuestionRedDark } from '../../../../assets/svgs';
import CosFocusTrap from '../../../../components/CosFocusTrap';
import clsx from 'clsx';
import { localStorageKey } from '../../../../libs/constant';

interface Props {
  onFormSubmit: (formValue: FormikValues) => void;
}

const LoginForm: React.FC<Props> = ({ onFormSubmit }) => {
  const navigate = useNavigate();

  const [error, setError] = useState(null);
  const {
    initialValues,
    validationSchema,
    handleOnChange,
    customFieldError,
    setInitialValues,
    handleOnBlur,
  } = useLoginFrom();
  const currentUrl = window.location.href;
  const url = new URL(currentUrl);
  const email = url.searchParams.get('email');
  // TODO : set company user type
  const [userDetails, setUserDetails] = useState<any>({});
  const [userNotFound, setUserNotFound] = useState('');
  const [isLoading, setIsLoading] = useState(true);

  const handleSubmit = (formValue: FormikValues) => {
    const emailRegex = /\S+@\S+\.\S+/;
    const isEmail = emailRegex.test(formValue.email);
    const { email, ...rest } = formValue;
    const payload = {
      ...rest,
    };
    if (isEmail) {
      payload['email'] = formValue.email;
    } else {
      payload['userName'] = formValue.email;
    }
    Rest.post('/auth/login', payload)
      .then(({ data }) => {
        onFormSubmit && onFormSubmit(formValue);
        navigate('/my_company/profile');
        setLocalStorageItem(localStorageKey.USER_DATA, data.data.user);
      })
      .catch(error => {
        const errorMessage = error.response.data.data.message;
        if (error.response.status === 423) {
          window.open(
            `${location.origin}/contactus?data=${JSON.stringify({ error: errorMessage, companyName: userDetails?.companyName })}`,
            '_blank',
          );
        } else if (error.response.status === 404) {
          setUserNotFound(errorMessage);
        } else if (error.response.status === 401) {
          setUserNotFound('');
          setError(errorMessage);
        }
      });
  };

  //* Bind EmailVerify api
  const loginConfirmation = async () => {
    const param = email;
    if (email) {
      Rest.get(`/auth/emailverify?email=${param}`)
        .then(response => {
          return response;
        })
        .catch(error => {
          console.error(error);
        });
    }
  };

  const renderPasswordErrors = (errors: CustomError[], className?: string) => {
    return (
      errors &&
      errors.map((err: CustomError) => {
        return (
          <CosFormErrorAlert
            severity={err.isValid ? 'success' : 'error'}
            className={clsx(className)}
            key={err.message}
            icon={true}
            sx={{
              width: '100% !important',
              maxHeight: 27,
            }}
          >
            {err.message}
          </CosFormErrorAlert>
        );
      })
    );
  };

  const setUserCompany = () => {
    const url = window.location.href;
    const domainStartIndex = url.indexOf('//') + 2;
    const domainEndIndex = url.indexOf('.', domainStartIndex);
    const extractedDomain = url.substring(domainStartIndex, domainEndIndex);

    const payload: any = { domainName: extractedDomain };
    setIsLoading(true);

    Rest.post(`/auth/companydetails`, payload)
      .then(({ data: { data } }) => {
        const { user } = data;
        setUserDetails(user);
        if (user.isRememberMe) {
          setInitialValues(preValue => ({
            ...preValue,
            ['email']: user.userName,
            ['isRememberMe']: user.isRememberMe,
          }));
        }
        setIsLoading(false);
      })
      .catch(error => {
        setIsLoading(false);
        console.error(error);
      });
  };

  useLayoutEffect(() => {
    setUserCompany();
    loginConfirmation();
  }, []);

  const commonSx = {
    background: '#EAEBED',
    border: '1px solid #FCBD3D',
    fontWeight: '600',
  };

  if (isLoading) {
    return <></>;
  }
  return (
    <CosFocusTrap>
      <div className="h-full">
        <Formik
          enableReinitialize={true}
          initialValues={initialValues}
          validationSchema={validationSchema}
          onSubmit={handleSubmit}
        >
          {formik => {
            const { isValid, setFieldValue, dirty } = formik;
            return (
              <Form className="h-full">
                <>
                  <section className="row flex justify-center">
                    <div className=" positionsetting">
                      <div className="logo_space"></div>
                      <div className="error_space text-center"></div>
                      <div className="flex h-[24px] items-center justify-center ">
                        <CosLabel
                          tooltipTitle={userNotFound ? 'Texthere' : ''}
                          isRequired={false}
                          hoverIcon={<QuestionRed />}
                          icon={<QuestionRedDark />}
                          className="m-0 h-[21px] text-16/20 font-semibold text-error-900"
                        >
                          {userNotFound ? userNotFound : ''}
                        </CosLabel>

                        {error ? (
                          <CosLabel
                            tooltipTitle={error ? 'Texthere' : ''}
                            isRequired={false}
                            className="m-0"
                            hoverIcon={<QuestionRed />}
                            icon={<QuestionRedDark />}
                          >
                            <>
                              <span
                                className="text-error-900"
                                style={{ fontWeight: 600, fontSize: '16px' }}
                              >
                                Try Again -
                              </span>
                              &nbsp; &nbsp;
                              <span
                                className="text-error-900"
                                style={{ fontSize: '16px' }}
                              >
                                {error}
                              </span>
                            </>
                          </CosLabel>
                        ) : (
                          <></>
                        )}
                      </div>
                      <Typography
                        variant="h6"
                        className="m-0 mb-1 h-[29px] text-center font-controller text-24/29 font-semibold capitalize -tracking-0.43 text-dark-blue-900"
                      >
                        {userDetails?.companyName}
                      </Typography>
                      <p className="text-center font-controller text-14/18 font-semibold  tracking-normal text-buddha-love-800">
                        Enhanced Optimization
                      </p>
                      <section className="m-auto w-[480px]">
                        <div className={'h-[73px]'}>
                          <FormPassword
                            withElevation={false}
                            disableFocusElevation
                            autoFocus={userDetails?.isRememberMe ? false : true}
                            defaultVisibilityState={true}
                            name="email"
                            hideDefaultError
                            placeholder="USERNAME / EMAIL *"
                            inputSx={commonSx}
                            className="!mb-1"
                            onChange={event => handleOnChange(event, formik)}
                            onBlur={event => handleOnBlur(event, formik)}
                          />
                          {customFieldError.email &&
                            renderPasswordErrors(
                              JSON.parse(customFieldError.email),
                            )}
                        </div>
                        <div className={'h-[73px]'}>
                          <FormPassword
                            withElevation={false}
                            disableFocusElevation
                            hideDefaultError
                            autoFocus={userDetails?.isRememberMe ? true : false}
                            name="password"
                            placeholder="PASSWORD *"
                            className="!mb-1"
                            inputSx={commonSx}
                            onChange={event => handleOnChange(event, formik)}
                            onBlur={event => handleOnBlur(event, formik)}
                          />
                          {customFieldError.password &&
                            renderPasswordErrors(
                              JSON.parse(customFieldError.password),
                            )}
                        </div>

                        <div className="row flex justify-between">
                          <FormCheckbox
                            name="isRememberMe"
                            isRequired={false}
                            label={'Remember Me'}
                            labelProps={{
                              className:
                                'text-16/22 tracking-normal text-dark-blue opacity-100',
                            }}
                          />
                          <span className="text-dark-blue text-16/22 tracking-normal">
                            <a
                              href=""
                              className="outline-none focus:underline"
                              tabIndex={1}
                            >
                              Forgot Password ?
                            </a>
                          </span>
                        </div>
                        <div className="mb-8 mt-4">
                          <CosButton
                            type="submit"
                            fullWidth
                            disabled={!(dirty && isValid)}
                            color="secondary"
                            customSize="extraLarge"
                          >
                            Log In
                          </CosButton>
                        </div>
                        <span className="text-dark-blue block text-center text-16/22 tracking-normal">
                          <a
                            href=""
                            className="text-artist-blue-900 outline-none focus:underline"
                          >
                            Trouble Logging In?
                          </a>
                        </span>
                      </section>
                    </div>
                  </section>
                </>
              </Form>
            );
          }}
        </Formik>
      </div>
    </CosFocusTrap>
  );
};
export default LoginForm;
