/**
 * @description use for when modal have two action btn (mostly close and apply)
 */
import React from 'react';
import CosButton from '../CosButton';
import { Divider } from '@mui/material';

export const primaryActionClass =
  'flex h-[44px] border-0 border-t-[0.5px] border-solid border-artist-blue-900 p-0 bg-white overflow-x-hidden';

interface Props {
  cancelText?: string;
  closeText?: string;
  applyText?: string;
  showCancel?: boolean;
  disableApply?: boolean;
  onClose?: () => void;
  onCancel?: () => void;
  onApply?: () => void;
}

const PrimaryActionButtons: React.FC<Props> = props => {
  const {
    cancelText = 'Cancel',
    applyText = 'Apply & Close',
    closeText = 'Close',
    onApply,
    onCancel,
    onClose,
    showCancel,
    disableApply,
  } = props;
  return (
    <>
      <CosButton
        size="medium"
        type="button"
        variant="text"
        onClick={() => (showCancel ? onCancel?.() : onClose?.())}
        className="min-h-auto w-1/2"
        color={showCancel ? 'secondary' : 'info'}
      >
        {showCancel ? cancelText : closeText}
      </CosButton>
      <Divider
        className="opacity-1 m-0 bg-artist-blue-900"
        flexItem
        orientation="vertical"
      />
      <CosButton
        size="medium"
        type="button"
        variant="text"
        className="min-h-auto m-0 w-1/2"
        onClick={onApply}
        disabled={disableApply}
      >
        {applyText}
      </CosButton>
    </>
  );
};

export default PrimaryActionButtons;
