import React from 'react';
import StandardLayout from './StandardLayout';

const StandardPageScrollbar = () => {
  return (
    <StandardLayout>
      <div className="bg-white-whale-500 p-2">
        <div className="h-[1000px]">
          <p className="m-0">
            If Page have large content with scrollbar than it's have 2px spacing
            from right side to scrollbar
          </p>
        </div>
      </div>
    </StandardLayout>
  );
};

export default StandardPageScrollbar;
