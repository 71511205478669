import React, { useEffect, useLayoutEffect, useMemo, useState } from 'react';
import { Typography } from '@mui/material';
import { Form, Formik, FormikValues } from 'formik';
import useSignUpFrom, { SignUpFromInitialValues } from '../hooks/useSignUpFrom';
import {
  FormCheckbox,
  FormInput,
  FormPassword,
} from '../../../../components/formikForms';
import images from '../../../../assets/images';
import { areObjectsEqualJSON, hasAnyCustomError } from '../../../../libs/utils';
import { CosFormErrorAlert, CosButton } from '../../../../components';
import clsx from 'clsx';
import { CustomError } from '../../../../libs/interface';
import { CosBasDialog } from '../../../../components/dialogBox';
import ConfiguringDomainModal from './ConfiguringDomainModal';
import { useLocation, useNavigate } from 'react-router-dom';
import Rest from '../../../../Rest';
import './SignUpForm.css';
import { constant } from '../../../../libs/constant';
import CosFocusTrap from '../../../../components/CosFocusTrap';
interface Props {
  onFormSubmit?: (formValue: FormikValues) => void;
}

const SignUpForm: React.FC<Props> = ({ onFormSubmit }) => {
  const {
    initialValues: oldValueInitialValues,
    validationSchema,
    handleOnChange,
    customFieldError,
    handleOnBlur,
    handleDebounceUserName,
    handleKeyUp,
    domainFieldWidth,
    handleOnFocus,
    handleDebounceOnChange,
    validateUserName,
    removeCheckError,
    setError,
  } = useSignUpFrom();

  const navigate = useNavigate();
  const location = useLocation();
  const registerValue = location.state?.formValue || {};
  const { companyType, ...sanitizeRegisterValue } = registerValue;

  useLayoutEffect(() => {
    !location.state?.formValue && navigate('/register', { replace: true });
  }, []);

  const commonSx = {
    textTransform: 'capitalize',
    border: '1px solid #FCBD3D',
    boxShadow: 'unset !important',
    fontWeight: '600',
  };

  const renderPasswordErrors = (errors: CustomError[], className?: string) => {
    return (
      errors && (
        <div className="flex flex-col gap-1">
          {errors.map((err: CustomError) => {
            return (
              <CosFormErrorAlert
                severity={err.isValid ? 'success' : 'error'}
                className={clsx('!mt-[-12px] mb-3', className)}
                key={err.message}
                icon={true}
              >
                {err.message}
              </CosFormErrorAlert>
            );
          })}
        </div>
      )
    );
  };

  const [domainModal, setDomainModal] = useState(false);
  const [pauseConfiguringDomain, setPauseConfiguringDomain] = useState(false);

  const handleSubmit = async (formValue: FormikValues) => {
    setPauseConfiguringDomain(true);
    Rest.post('/auth/register', formValue)
      .then(response => {
        setDomainModal(true);
        setPauseConfiguringDomain(false);
        return response;
      })
      .catch(error => {
        setPauseConfiguringDomain(false);
        setDomainModal(false);
        console.error(error);
      });

    onFormSubmit && onFormSubmit(formValue);
  };

  const goToConfirmationPage = (formValue: FormikValues) => {
    setDomainModal(false);
    navigate('/confirmation', { state: { email: formValue.email } });
  };

  const initialValues: SignUpFromInitialValues = useMemo(() => {
    return Object.assign(oldValueInitialValues, sanitizeRegisterValue, {
      ['userName']: `${(sanitizeRegisterValue?.firstName || '')?.toCapitalize()}${(sanitizeRegisterValue?.lastName || '')?.toCapitalize()}`,
    });
  }, [sanitizeRegisterValue]);

  useEffect(() => {
    if (initialValues.userName) {
      const userNameErrorMessage = validateUserName(
        initialValues.userName,
        false,
      );
      setError('userName', userNameErrorMessage);
      removeCheckError(userNameErrorMessage, 'userName');
    }
  }, [initialValues.userName]);

  return (
    // TODO : remove validationSchema and manage with validate
    // * Currently error message UI showing using custom array and check form is valid or not using yup
    <CosFocusTrap>
      <div className="signup-form cos-dark-scrollbar h-auto pt-[16px] lg:h-full">
        <Formik
          initialValues={initialValues}
          validationSchema={validationSchema}
          onSubmit={handleSubmit}
          validateOnBlur
        >
          {formik => {
            const { dirty, values, isValid, setFieldValue } = formik;
            return (
              <Form className="h-auto lg:h-full">
                <React.Fragment>
                  <section className="flex h-auto flex-col bg-[#001f38] lg:h-full">
                    <Typography
                      variant="h6"
                      className="m-0 mb-4 text-center font-controller font-semibold text-white"
                    >
                      Optimizing {constant.freeTrailDays} Day Trial
                    </Typography>
                    <div
                      className={clsx(
                        'mx-1',
                        'mr-1',
                        'lg:overflow-auto',
                        'px-[21px]',
                        {
                          'lg:max-h-[calc(100vh-355px)]':
                            dirty &&
                            !areObjectsEqualJSON(values, initialValues),
                          'lg:max-h-[calc(100vh-239px)]':
                            !dirty ||
                            areObjectsEqualJSON(values, initialValues),
                        },
                      )}
                    >
                      <div>
                        <FormInput
                          fullWidth
                          hideDefaultError
                          // readOnly
                          name="companyName"
                          placeholder="YOUR COMPANY NAME *"
                          onChange={event => handleOnChange(event, formik)}
                          onBlur={event => handleOnBlur(event, formik)}
                          debounceCallback={event =>
                            handleDebounceOnChange(event, formik)
                          }
                          inputSx={commonSx}
                        />
                        {customFieldError.companyName &&
                          renderPasswordErrors(
                            JSON.parse(customFieldError.companyName),
                          )}
                      </div>
                      <div className="flex gap-3">
                        <div className="flex-1">
                          <FormInput
                            fullWidth
                            hideDefaultError
                            name="firstName"
                            placeholder="FIRST NAME *"
                            onChange={event => handleOnChange(event, formik)}
                            onBlur={event => handleOnBlur(event, formik)}
                            inputSx={commonSx}
                          />
                          {customFieldError.firstName &&
                            renderPasswordErrors(
                              JSON.parse(customFieldError.firstName),
                            )}
                        </div>
                        <FormInput
                          name="int"
                          placeholder="INT."
                          className="!w-[53px]"
                          inputSx={{
                            ...commonSx,
                            textTransform: 'uppercase',
                            minWidth: 'auto',
                          }}
                        />
                        <div className="flex-1">
                          <FormInput
                            fullWidth
                            hideDefaultError
                            name="lastName"
                            placeholder="LAST NAME *"
                            onChange={event => handleOnChange(event, formik)}
                            onBlur={event => handleOnBlur(event, formik)}
                            inputSx={commonSx}
                          />
                          {customFieldError.lastName &&
                            renderPasswordErrors(
                              JSON.parse(customFieldError.lastName),
                            )}
                        </div>
                      </div>
                      <div>
                        <FormInput
                          fullWidth
                          readOnly
                          inputProps={{
                            tabIndex: -1,
                          }}
                          hideDefaultError
                          name="email"
                          placeholder="EMAIL ADDRESS *"
                          // onChange={event => handleOnChange(event, formik)}
                          // onBlur={event => handleOnBlur(event, formik)}
                          inputSx={{
                            ...commonSx,
                            textTransform: 'lowercase',
                          }}
                          // debounceCallback={event =>
                          //   handleDebounceOnChange(event, formik)
                          // }
                        />
                        {customFieldError.email &&
                          renderPasswordErrors(
                            JSON.parse(customFieldError.email),
                            '!mb-2',
                          )}
                      </div>

                      <div className="relative">
                        <div className="domainName relative">
                          <FormInput
                            fullWidth
                            hideDefaultError
                            autoFocus
                            name="domainName"
                            // placeholder="DOMAIN NAME"
                            label="CREATE YOUR CUSTOMIZED DOMAIN NAME HERE"
                            className="relative"
                            labelProps={{
                              sx: {
                                letterSpacing: '-0.25px',
                                color: '#ffffff',
                              },
                            }}
                            inputSx={{
                              ...commonSx,
                              borderRadius: '3px 0 0 3px !important',
                              borderRight: 0,
                              paddingRight: 2,
                              textTransform: 'lowercase',
                              minWidth: 'auto',
                            }}
                            inputWrapperClass="flex"
                            sx={{
                              width: domainFieldWidth,
                              minWidth: '113px',
                            }}
                            tooltipTitle="DomainName is also Missing"
                            onChange={event => handleOnChange(event, formik)}
                            onKeyDown={e => {
                              if (e.code === 'Space') {
                                return false;
                              }
                            }}
                            onBlur={event => handleOnBlur(event, formik)}
                            debounceCallback={event =>
                              handleDebounceOnChange(event, formik)
                            }
                          >
                            <span
                              className="flex flex-1 items-center whitespace-nowrap rounded-r-[3px] bg-artist-blue-900 px-1 pr-[10px]  ps-1 text-14/18 leading-4 text-white"
                              style={{
                                borderRadius: '0 3px 3px 0',
                                border: '1.5px solid #FCBD3D',
                                borderLeft: 0,
                                paddingLeft: 2,
                              }}
                            >
                              .sch-ops.com
                            </span>
                          </FormInput>
                          {customFieldError.domainName &&
                            renderPasswordErrors(
                              JSON.parse(customFieldError.domainName),
                            )}
                        </div>
                      </div>

                      <div>
                        <FormPassword
                          hideDefaultError
                          name="userName"
                          placeholder="USERNAME *"
                          debounceCallback={event =>
                            handleDebounceUserName(event)
                          }
                          defaultVisibilityState={true}
                          onChange={event => handleOnChange(event, formik)}
                          onBlur={event => handleOnBlur(event, formik)}
                          onKeyUp={event =>
                            handleKeyUp(
                              event,
                              customFieldError.userName || '',
                              'userName',
                            )
                          }
                          inputSx={commonSx}
                        />
                        {customFieldError.userName &&
                          renderPasswordErrors(
                            JSON.parse(customFieldError.userName),
                          )}
                      </div>

                      <div>
                        <FormPassword
                          hideDefaultError={!!customFieldError.password}
                          name="password"
                          placeholder="PASSWORD *"
                          onChange={event => handleOnChange(event, formik)}
                          onFocus={event => handleOnFocus(event, formik)}
                          onBlur={event => handleOnBlur(event, formik)}
                          onKeyUp={event =>
                            handleKeyUp(
                              event,
                              customFieldError.password || '',
                              'password',
                            )
                          }
                          inputSx={commonSx}
                        />
                        {customFieldError.password &&
                          renderPasswordErrors(
                            JSON.parse(customFieldError.password),
                          )}
                      </div>

                      <div className="countryValue relative">
                        <FormInput
                          fullWidth
                          tooltipTitle="Country is also Missing"
                          name="country"
                          startIcon={<img src={images.usFlag_x2} width={27} />}
                          placeholder="COUNTRY"
                          label="COUNTRY"
                          labelProps={{
                            sx: { fontWeight: 500, color: '#ffffff' },
                          }}
                          inputProps={{
                            tabIndex: -1,
                          }}
                          inputSx={commonSx}
                          readOnly
                        />
                      </div>
                    </div>

                    <section className="pb-[16px] lg:mt-auto">
                      <div
                        className="mt-[25px] bg-white-whale-500 px-[25px] py-3"
                        style={{
                          boxShadow:
                            'inset 0px 3px 6px #00000029, 0px 3px 6px #00000029',
                        }}
                      >
                        <FormCheckbox
                          isValid={isValid}
                          name="terms"
                          label={
                            <React.Fragment>
                              <span>Agree to the</span>
                              &nbsp;
                              <img
                                src={images.SchedulrOpsTertiarylogo}
                                height={16}
                              />
                              &nbsp;
                              <span className="text-blue-gray-100">
                                Cloud Terms of Service&nbsp;
                              </span>
                              <span>and&nbsp;</span>
                              <span className="text-blue-gray-100">
                                Privacy Policy
                              </span>
                            </React.Fragment>
                          }
                          labelProps={{
                            className:
                              '!mb-0 text-dark-blue-900 flex flex-wrap items-center !mb-0 text-[13px]',
                          }}
                        />
                        <FormCheckbox
                          name="keepMeUpdate"
                          isRequired={false}
                          label={
                            'Please keep me updated on news, offers and training.'
                          }
                          labelProps={{
                            className: '!mb-0 text-dark-blue-900 text-[13px]',
                          }}
                        />
                      </div>
                      {/* {dirty && !areObjectsEqualJSON(values, initialValues) && ( */}
                      <div className="mt-4 px-[18px]">
                        <CosButton
                          type="submit"
                          color="secondary"
                          customSize="extraLarge"
                          fullWidth
                          disabled={
                            !(
                              dirty &&
                              isValid &&
                              !hasAnyCustomError(customFieldError)
                            )
                          }
                          isLoading={pauseConfiguringDomain}
                        >
                          Start Optimizing
                        </CosButton>
                      </div>
                      {/* )} */}
                    </section>
                  </section>

                  {domainModal && (
                    <CosBasDialog
                      open={domainModal}
                      onClose={() => setDomainModal(false)}
                      className="modaldialog" // * only use this className if want to same UI (size or bg-color)
                    >
                      <ConfiguringDomainModal
                        afterConfiguring={() => goToConfirmationPage(values)}
                        domainName={values.domainName}
                        countProps={{
                          pauseAt96: pauseConfiguringDomain,
                        }}
                      />
                    </CosBasDialog>
                  )}
                </React.Fragment>
              </Form>
            );
          }}
        </Formik>
      </div>
    </CosFocusTrap>
  );
};

export default SignUpForm;
