import { useCallback, useState } from 'react';
import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import { TargetBox } from './TargetBox';
import ViewAndCropImg from '../imageCrop/ViewAndCropImg';

interface Props {
  children?: React.ReactNode | Function;
  onGetFile?: (files: File[]) => void;
}

const DndContainer: React.FC<Props> = ({ children, onGetFile }) => {
  const [droppedFiles, setDroppedFiles] = useState<File[]>([]);

  const handleFileDrop = useCallback(
    (files: File[]) => {
      setDroppedFiles(files);
      onGetFile?.(files);
    },
    [setDroppedFiles],
  );

  return (
    <DndProvider backend={HTML5Backend}>
      {droppedFiles.length ? (
        <div className="flex h-full items-center justify-center">
          <ViewAndCropImg file={droppedFiles[0]} />
        </div>
      ) : (
        <TargetBox onDrop={handleFileDrop} children={children} />
      )}
    </DndProvider>
  );
};

export default DndContainer;
