import React, { useRef, useState } from 'react';
import { Typography } from '@mui/material';
import { HideShowComponent } from '../../../components';
import { moveTabToNextField, moveTabToPrevField } from '../../../libs/helper';
import MaskInput from '../../../components/formikForms/ui/MaskInput';
import { CosInputProps } from '../../../components/formikForms/ui/CosInput';

interface Props {
  onChange: (value: string) => void;
  inputProps?: CosInputProps;
}

const SSTaxId: React.FC<Props> = ({ onChange, inputProps }) => {
  const [isHide, setIsHide] = useState(true);

  const valueRef = useRef<{
    prefix?: string;
    centric?: string;
    suffix?: string;
  } | null>(null);

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { value, name } = event.target;
    valueRef.current = {
      ...valueRef.current,
      [name]: value,
    };
    onChange(
      `${valueRef.current.prefix || ''}${valueRef.current.centric || ''}${valueRef.current.suffix || ''}`,
    );
  };

  return (
    <div className="flex">
      <MaskInput
        max={3}
        className="!mb-0 w-8"
        showMask={!isHide}
        onReachMax={() => moveTabToNextField()}
        type="number"
        onChange={handleChange}
        name="prefix"
        {...inputProps}
      />
      <Typography className="w-[16px] text-center">-</Typography>
      <MaskInput
        max={2}
        className="!mb-0 w-8"
        showMask={!isHide}
        onReachMax={() => moveTabToNextField()}
        onClean={moveTabToPrevField}
        type="number"
        onChange={handleChange}
        name="centric"
        {...inputProps}
      />
      <Typography className="w-[16px] text-center">-</Typography>
      <MaskInput
        max={4}
        className="!mb-0 w-[42px]"
        showMask={!isHide}
        onClean={moveTabToPrevField}
        type="number"
        renderMask={inputValue =>
          inputValue
            .toString()
            .split('')
            ?.map((_item: any, index: number) => '*')
            .join('')
        }
        onChange={handleChange}
        name="suffix"
        {...inputProps}
      />
      <HideShowComponent
        isHide={isHide}
        className="ml-[10px]"
        toggle={() => setIsHide(prevValue => !prevValue)}
      />
    </div>
  );
};

export default SSTaxId;

// 153
//       138
