String.prototype.toCapitalize = function (): string {
  // return this.charAt(0).toUpperCase() + this.slice(1).toLowerCase();
  return this.toLowerCase().replace(/\b\w/g, function (word) {
    return word.toUpperCase();
  });
};

String.prototype.toTitleCase = function (): string {
  // return this.charAt(0).toUpperCase() + this.slice(1).toLowerCase();
  return this.split(' ')
    .map((word, index) => {
      return word.length <= 3 && index !== 0
        ? word.toLowerCase()
        : word.charAt(0).toUpperCase() + word.slice(1).toLowerCase();
    })
    .join(' ');
};

export {};
