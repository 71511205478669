import { useEffect, useRef, useState } from 'react';
import { TImeFieldWrapper } from './TImeFieldWrapper';
import ScheduleInput from './ScheduleInput';
import { moveTabToNextField, moveTabToPrevField } from '../../libs/helper';

interface Props {
  className?: string;
}
const AddHoursField: React.FC<Props> = ({ className }) => {
  const [hours, setHours] = useState('');
  const [mins, setMins] = useState('');

  const ref = useRef<HTMLDivElement>(null);

  return (
    <TImeFieldWrapper ref={ref} className={className}>
      <ScheduleInput
        value={hours}
        onChange={(hour: string) => setHours(hour)}
        onComplete={() => moveTabToNextField(ref.current as HTMLElement)}
        id="hours"
        max={23}
      />
      <span className="pl-[3px] pr-[5px] font-semibold">Hr(s)</span>
      <ScheduleInput
        value={mins}
        onChange={(mins: string) => setMins(mins)}
        onEmpty={() => moveTabToPrevField(ref.current as HTMLElement)}
        id="min"
        max={59}
      />
      <span className="pl-[3px] font-semibold">Min(s)</span>
    </TImeFieldWrapper>
  );
};

export default AddHoursField;
