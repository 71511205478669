import React, {
  useState,
  useRef,
  useEffect,
  forwardRef,
  useImperativeHandle,
} from 'react';
import ReactCrop, { Crop, PercentCrop, PixelCrop } from 'react-image-crop';
import { canvasPreview } from './canvasPreview';
import 'react-image-crop/dist/ReactCrop.css';
import { useDebounceEffect } from './util';

interface Props {
  file: File;
  onCrop?: (crop: PixelCrop, percentageCrop: PercentCrop) => void;
}

export interface CropImageData {
  cropImageData: File;
  cropImageBlobUrl: string;
  initialFileData: File;
}

export interface ViewCropRef {
  getCropImageData: () => Promise<CropImageData>;
}

const ViewAndCropImg = forwardRef<ViewCropRef | null, Props>(
  ({ file, onCrop }, ref) => {
    useImperativeHandle(ref, () => {
      return {
        getCropImageData: getCropImage,
      };
    });

    const [imgSrc, setImgSrc] = useState('');
    const previewCanvasRef = useRef<HTMLCanvasElement>(null);
    const imgRef = useRef<HTMLImageElement>(null);
    const blobUrlRef = useRef('');
    const [crop, setCrop] = useState<Crop>();
    const [completedCrop, setCompletedCrop] = useState<PixelCrop>();

    const scale = 1;
    const rotate = 0;

    useEffect(() => {
      setCrop(undefined); //* Makes crop preview update between images.
      const reader = new FileReader();
      reader.addEventListener('load', () =>
        setImgSrc(reader.result?.toString() || ''),
      );
      reader.readAsDataURL(file);
    }, [file]);

    function onImageLoad(e: React.SyntheticEvent<HTMLImageElement>) {
      setCrop({
        unit: '%', // Can be 'px' or '%'
        x: 25,
        y: 25,
        width: 50,
        height: 50,
      });
    }

    //* FOR Download crop Image
    const getCropImage = async (): Promise<CropImageData> => {
      const image = imgRef.current;
      const previewCanvas = previewCanvasRef.current;
      if (!image || !previewCanvas || !completedCrop) {
        throw new Error('Crop canvas does not exist');
      }

      // This will size relative to the uploaded image
      // size. If you want to size according to what they
      // are looking at on screen, remove scaleX + scaleY
      const scaleX = image.naturalWidth / image.width;
      const scaleY = image.naturalHeight / image.height;

      const offscreen: any = new OffscreenCanvas(
        completedCrop.width * scaleX,
        completedCrop.height * scaleY,
      );
      const ctx = offscreen.getContext('2d');
      if (!ctx) {
        throw new Error('No 2d context');
      }

      ctx.drawImage(
        previewCanvas,
        0,
        0,
        previewCanvas.width,
        previewCanvas.height,
        0,
        0,
        offscreen.width,
        offscreen.height,
      );
      // You might want { type: "image/jpeg", quality: <0 to 1> } to
      // reduce image size
      const blob = await offscreen.convertToBlob({
        type: 'image/png',
      });

      if (blobUrlRef.current) {
        URL.revokeObjectURL(blobUrlRef.current);
      }
      const blobUrl = URL.createObjectURL(blob);
      blobUrlRef.current = blobUrl;

      //* Convert Blob to File
      const fileData = new File([blob], file.name, {
        type: file.type,
        lastModified: Date.now(),
      });

      return {
        cropImageData: fileData,
        cropImageBlobUrl: blobUrl,
        initialFileData: file,
      };
    };

    useDebounceEffect(
      async () => {
        if (
          completedCrop?.width &&
          completedCrop?.height &&
          imgRef.current &&
          previewCanvasRef.current
        ) {
          // We use canvasPreview as it's much faster than imgPreview.
          canvasPreview(
            imgRef.current,
            previewCanvasRef.current,
            completedCrop,
            scale,
            rotate,
          );
        }
      },
      100,
      [completedCrop, scale, rotate],
    );

    return (
      <div>
        {!!imgSrc && (
          <ReactCrop
            crop={crop}
            onChange={(_crop, percentCrop) => {
              setCrop(percentCrop);
            }}
            onComplete={(crop, percentCrop) => {
              setCompletedCrop(crop);
              onCrop?.(crop, percentCrop);
            }}
            className="COS-React-Crop"
          >
            <img
              ref={imgRef}
              alt="Crop me"
              src={imgSrc}
              style={{ transform: `scale(${scale}) rotate(${rotate}deg)` }}
              onLoad={onImageLoad}
            />
          </ReactCrop>
        )}

        {/* For show Preview */}
        {!!completedCrop && (
          <div className="absolute left-0 top-0 -z-10 h-0 w-0">
            <div>
              <canvas
                ref={previewCanvasRef}
                style={{
                  border: '1px solid black',
                  objectFit: 'contain',
                  width: completedCrop.width,
                  height: completedCrop.height,
                }}
              />
            </div>
            <button onClick={getCropImage} />
          </div>
        )}
      </div>
    );
  },
);

export default ViewAndCropImg;
