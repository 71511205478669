import { FC, ReactNode, useState } from 'react';
import { InputLabel, InputLabelProps, styled } from '@mui/material';
import CosTooltip from '../../CosTooltip';
import { Question, QuestionDark } from '../../../assets/svgs';
import { cn } from '../../../libs/utils';

export interface CosLabelProps extends InputLabelProps {
  children: ReactNode;
  isRequired?: boolean;
  toolTip?: string;
  tooltipTitle?: string;
  icon?: any;
  hoverIcon?: any;
  warperClassName?: string;
}

const ExtendedInputLabel = styled(InputLabel)(({ theme }) => ({
  fontSize: '14px',
  lineHeight: '19px',
  color: '#001F38',
  textAlign: 'left',
  marginBottom: '0px',
  '& .MuiInputLabel-asterisk': {
    color: '#eb5050',
  },
}));

const CosLabel: FC<CosLabelProps> = ({
  children,
  isRequired = true,
  tooltipTitle,
  icon,
  hoverIcon,
  warperClassName,
  ...rest
}) => {
  return (
    <div className={cn('flex items-center', warperClassName)}>
      <ExtendedInputLabel required={isRequired} {...rest}>
        {children}
      </ExtendedInputLabel>
      {tooltipTitle && (
        <>
          <span className="leading-[0px]">&nbsp;</span>
          <CosTooltip
            title={tooltipTitle}
            placement="right"
            textDecoration="underline"
            version="light"
            onHoverIcon={hoverIcon ? hoverIcon : <Question />}
          >
            {/* <span className="mb-1"> */}
            {icon ? icon : <QuestionDark />}
            {/* </span> */}
          </CosTooltip>
        </>
      )}
    </div>
  );
};

export default CosLabel;
