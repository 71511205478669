import { TableCell, TableCellProps } from '@mui/material';
import React from 'react';

interface Props extends TableCellProps {}

const CosTableCell: React.FC<Props> = props => {
  return <TableCell {...props} />;
};

export default CosTableCell;
