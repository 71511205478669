import { Box, Toolbar, Typography } from '@mui/material';
import AppBar from './ui/AppBar';
import AppBarLeftSideBarHeader from './ui/AppBarLeftSideBarHeader';
import Navigator from './ui/Navigator';
import { getLocalStorageItem } from '../../../libs/utils';
import { localStorageKey } from '../../../libs/constant';
import { RouteObject } from 'react-router';

interface Props {
  open: boolean;
  navigatorList: RouteObject;
}

const Navbar: React.FC<Props> = ({ open, navigatorList }) => {
  const userDetails: any = getLocalStorageItem(localStorageKey.USER_DATA);
  return (
    <AppBar position="fixed" open={open}>
      <Toolbar className="p-0">
        <AppBarLeftSideBarHeader className="bg-white">
          <p className="p-2 text-center text-18/21 text-dark-blue-900">
            {userDetails?.companyName || '%COMPANY NAME%'}
          </p>
        </AppBarLeftSideBarHeader>
        <Navigator className="flex-grow" navigatorList={navigatorList} />
      </Toolbar>
    </AppBar>
  );
};
export default Navbar;
