import { Box } from '@mui/material';
import React from 'react';
import StandardLayout from './StandardLayout';

const StandardHorizontalScrollBar = () => {
  return (
    <StandardLayout>
      <Box className="cos-primary-scrollbar w-[1216px] bg-white-whale-500 p-2">
        <Box className="h-3 w-[1200px] overflow-auto">
          <Box className="w-[2000px]"></Box>
        </Box>
      </Box>
    </StandardLayout>
  );
};

export default StandardHorizontalScrollBar;
