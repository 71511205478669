import { useState } from 'react';
import { Form, Formik } from 'formik';
import {
  FormAutoComplete,
  FormInput,
  FormRadio,
} from '../../../components/formikForms';
import {
  entityTypeItems,
  topFormFormField,
  topFormInitialValues,
  taxIdItems,
  topFormValidationSchema,
} from '../formConfig/profileForm';
import FieldWithLabel, {
  FieldWithLabelProps,
} from '../components/FieldWithLabel';
import { CosButton } from '../../../components';
import { Divider, Typography } from '@mui/material';
import images from '../../../assets/images';
import { moveTabToNextField } from '../../../libs/helper';
import UploadTextExempt from './UploadTextExempt';
import SSTaxId from './SSTaxId';
import EINTaxId from './EINTaxId';
import { camelCaseToTitleCase, cn } from '../../../libs/utils';
import { EditIcon } from '../../../assets/svgs';

interface Props {
  onSubmit: (formValue: any) => void;
}

const actionLabelProps = {
  required: false,
  className: 'text-12/17',
};

const renderFormFiledValue = (value: string) => (
  <Typography className="text-14/15">{camelCaseToTitleCase(value)}</Typography>
);

const taxIDValueReadOnlyProps = {
  withElevation: false,
  readOnly: true,
  inputSx: {
    minHeight: 'auto !important',
    height: 'auto !important',
  },
};

const WithLabel: React.FC<
  Omit<FieldWithLabelProps, 'label' | 'required'> & {
    formFieldKey: string;
    label?: string;
    required?: boolean;
  }
> = ({ formFieldKey, label, required, ...rest }) => (
  <FieldWithLabel
    label={label || topFormFormField[formFieldKey].label}
    required={required ?? topFormFormField[formFieldKey].required}
    {...rest}
  />
);

const ProfileTopForm: React.FC<Props> = ({ onSubmit }) => {
  const [uploadTextExempt, setUploadTextExempt] = useState(false);

  const [isViewMode, setIsViewMode] = useState(false);

  const handleSubmit = (value: any) => {
    setIsViewMode(true);
    onSubmit(value);
  };

  const handleClose = (setFieldValue: any) => {
    setUploadTextExempt(false);
    setTimeout(() => moveTabToNextField());
    setFieldValue('textExempt', 'no'); // Set radio button value to "No"
  };

  return (
    <Formik
      initialValues={topFormInitialValues}
      onSubmit={value => {
        handleSubmit(value);
      }}
      validationSchema={topFormValidationSchema}
    >
      {({ values, setFieldValue, dirty, isValid }) => {
        return (
          <Form>
            <section className="mb-2 flex border-0 border-b-[1px] border-solid border-blue-gray-200/[0.38]">
              <div className="w-1/2">
                <WithLabel formFieldKey="country" className="mb-2">
                  <div className="flex items-center gap-[5px]">
                    <img src={images.usFlag_x2} width={20} />
                    <Typography className="gap-1 text-14/15">
                      {values.country} (Primary)
                    </Typography>
                  </div>
                </WithLabel>
              </div>
              <Divider
                className="opacity-1 mb-1"
                flexItem
                orientation="vertical"
              />
              <div className="flex w-1/2 pl-[15px]">
                <WithLabel formFieldKey="language" className="mb-2">
                  <Typography className="text-14/15">
                    {values.language} (Primary)
                  </Typography>
                </WithLabel>
              </div>
            </section>

            <section className="flex h-[68px] gap-[30px]">
              <div className="w-1/2">
                <div className="flex gap-[30px]">
                  <WithLabel
                    formFieldKey="companyName"
                    labelRightText={'(Active Company}'}
                    required={false}
                  >
                    {isViewMode ? (
                      renderFormFiledValue(values.companyName)
                    ) : (
                      <FormInput
                        name={topFormFormField.companyName.name}
                        placeholder="Enter Company Name"
                        size="small"
                        className="p-0"
                        fullWidth
                        readOnly
                        withTitleCase
                        inputProps={{
                          tabIndex: -1,
                        }}
                      />
                    )}
                  </WithLabel>

                  <WithLabel formFieldKey="fullLegalName">
                    {isViewMode ? (
                      renderFormFiledValue(values.fullLegalName)
                    ) : (
                      <FormInput
                        name={topFormFormField.fullLegalName.name}
                        placeholder="Enter Full Legal Name"
                        size="small"
                        fullWidth
                        autoFocus
                        withTitleCase
                      />
                    )}
                  </WithLabel>
                </div>
              </div>

              <div className="w-1/2">
                <div className="flex gap-[30px]">
                  <WithLabel
                    formFieldKey="dbaName"
                    label="DBA Name"
                    labelRightText="(If Applicable)"
                    className="w-1/2"
                  >
                    {isViewMode ? (
                      renderFormFiledValue(values.dbaName)
                    ) : (
                      <FormInput
                        name={topFormFormField.dbaName.name}
                        placeholder="Enter DBA Name"
                        size="small"
                        fullWidth
                        withTitleCase
                      />
                    )}
                  </WithLabel>

                  <div className="flex w-1/2 justify-between">
                    <WithLabel
                      formFieldKey="organizationType"
                      className="mb-4 w-auto"
                    >
                      <div
                        className="flex h-[28px] flex-row items-center gap-[10px]"
                        role="radiogroup"
                      >
                        {isViewMode ? (
                          renderFormFiledValue(values.organizationType)
                        ) : (
                          <>
                            <FormRadio
                              name={topFormFormField.organizationType.name}
                              value="forProfit"
                              label="For Profit"
                              labelProps={actionLabelProps}
                              tabIndex={0}
                              id="organizationType-forProfit"
                              onClick={() => {
                                setFieldValue('textExempt', 'no');
                              }}
                            />
                            <FormRadio
                              name={topFormFormField.organizationType.name}
                              value="nonProfit"
                              label="Non Profit"
                              labelProps={actionLabelProps}
                              tabIndex={0}
                              id="organizationType-nonProfit"
                              onClick={() => {
                                setFieldValue('textExempt', 'yes');
                                setUploadTextExempt(true);
                              }}
                            />
                          </>
                        )}
                      </div>
                    </WithLabel>

                    <WithLabel
                      formFieldKey="textExempt"
                      className="mb-4 w-auto"
                    >
                      <div
                        className="flex h-[28px] flex-row items-center gap-[10px]"
                        role="radiogroup"
                      >
                        {isViewMode ? (
                          renderFormFiledValue(values.textExempt)
                        ) : (
                          <>
                            <FormRadio
                              name={topFormFormField.textExempt.name}
                              value="yes"
                              label="Yes"
                              labelProps={actionLabelProps}
                              id="textExempt-yes"
                              tabIndex={0}
                              onClick={() => setUploadTextExempt(true)}
                            />
                            <FormRadio
                              name={topFormFormField.textExempt.name}
                              value="no"
                              label="No"
                              labelProps={{
                                required: false,
                                className: 'text-12/17',
                              }}
                              id="textExempt-no"
                              tabIndex={0}
                            />
                          </>
                        )}
                      </div>
                    </WithLabel>
                  </div>
                </div>
              </div>
            </section>

            <section className="flex h-[52px] gap-[30px]">
              <div className="w-1/2">
                <WithLabel formFieldKey="entityType">
                  {isViewMode ? (
                    renderFormFiledValue(values.entityType)
                  ) : (
                    <FormAutoComplete
                      options={entityTypeItems}
                      size="small"
                      name={topFormFormField.entityType.name}
                      placeholder="Select Entity Type"
                      fullWidth
                    />
                  )}
                </WithLabel>
              </div>

              <div className="w-1/2">
                <div className="flex h-full gap-[15px]">
                  <WithLabel
                    formFieldKey="taxId"
                    className={isViewMode ? 'w-auto' : 'w-1/2'}
                  >
                    {isViewMode ? (
                      <div>
                        <Typography className="whitespace-nowrap text-14/15">
                          {values.taxId === 'EIN'
                            ? 'EIN / Employer Identification Number :'
                            : 'SS / Social Security Number :'}
                        </Typography>
                      </div>
                    ) : (
                      <FormAutoComplete
                        name="taxId"
                        options={taxIdItems}
                        size="small"
                        fullWidth
                        placeholder="Select Tax ID Type"
                      />
                    )}
                  </WithLabel>

                  <div
                    className={cn('flex w-1/2 items-end justify-between', {
                      'w-full': isViewMode,
                    })}
                  >
                    <div
                      className={cn({
                        'mt-[19px] self-baseline': isViewMode,
                      })}
                    >
                      {values.taxId === 'EIN' && (
                        <EINTaxId
                          onChange={value => setFieldValue('taxIdValue', value)}
                          inputProps={isViewMode ? taxIDValueReadOnlyProps : {}}
                        />
                      )}
                      {values.taxId === 'SS' && (
                        <SSTaxId
                          onChange={value => setFieldValue('taxIdValue', value)}
                          inputProps={isViewMode ? taxIDValueReadOnlyProps : {}}
                        />
                      )}
                    </div>
                    {isViewMode ? (
                      <CosButton
                        type="button"
                        size="small"
                        variant="outlined"
                        startIcon={<EditIcon />}
                        onClick={() => setIsViewMode(false)}
                      >
                        Edit
                      </CosButton>
                    ) : (
                      <CosButton
                        type="button"
                        size="small"
                        disabled={!(dirty && isValid)}
                        onClick={() => {
                          handleSubmit(values);
                        }}
                      >
                        Save
                      </CosButton>
                    )}
                  </div>
                </div>
              </div>
            </section>

            <UploadTextExempt
              open={uploadTextExempt}
              onClose={() => {
                handleClose(setFieldValue);
                setTimeout(() => moveTabToNextField());
              }}
              onUpload={data => {
                setFieldValue('textExemptData', {
                  status: data.textExemptStatus,
                  document: data.textExemptDocument,
                });
                setUploadTextExempt(false);
                setTimeout(() => moveTabToNextField());
              }}
            />
          </Form>
        );
      }}
    </Formik>
  );
};
export default ProfileTopForm;
