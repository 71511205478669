import {
  Box,
  BoxProps,
  TableCellBaseProps,
  TableContainer,
} from '@mui/material';
import { cn } from '../../libs/utils';
import React, {
  CSSProperties,
  useEffect,
  useLayoutEffect,
  useRef,
  useState,
} from 'react';

interface Props extends TableCellBaseProps {
  wrapperProps?: BoxProps;
  fullHeight?: boolean;
  hight?: CSSProperties['height'];
}

const CosTableContainer: React.FC<Props> = ({
  children,
  wrapperProps,
  hight,
  ...rest
}) => {
  // TODO : Fix type
  const tableContainerRef: any = useRef<any>(null);
  const [mastHeight, setMaskHeight] = useState(40);

  const manageTableHeadUi = () => {
    const tableContainerEle = tableContainerRef.current;
    if (tableContainerEle) {
      const tableEle = tableContainerEle.querySelector('.MuiTable-root');
      const tableHead = tableEle?.querySelector('.MuiTableHead-root');
      setTimeout(() => {
        const tableHeadHeight = tableHead?.getBoundingClientRect().height;
        setMaskHeight(tableHeadHeight);
      });
    }
  };

  useLayoutEffect(() => {
    manageTableHeadUi();
  }, [tableContainerRef]);

  return (
    <Box {...wrapperProps} className={cn('relative', wrapperProps?.className)}>
      <TableContainer
        ref={tableContainerRef}
        sx={{
          maxHeight: hight ?? 'calc(100vh - 117px)',
          overflow: 'auto',

          // * Temp solution for show table scroll from body (solution : create 40px (height) mask and place above scroll)
          '&::before': {
            content: '""',
            fontSize: '10px',
            width: '4px',
            height: mastHeight,
            display: 'inline-block',
            position: 'absolute',
            zIndex: '3',
            top: '0',
            right: '0',
            background: '#EBECED',
          },

          //* table container border-radius
          '&::after': {
            content: '""',
            width: 'calc(100% - 6px)',
            height: '40px',
            display: 'inline-block',
            position: 'absolute',
            zIndex: '3',
            top: '0',
            left: '0',
            background: `radial-gradient(farthest-side at bottom right,#0000 80%,#EBECED) top left,
              radial-gradient(farthest-side at top right,#0000 80%,transparent) bottom left,
              radial-gradient(farthest-side at bottom left ,#0000 80%, #EBECED) top right,
              radial-gradient(farthest-side at top left ,#0000 80%, transparent) bottom right,
              transparent`,
            backgroundSize: '5px 5px',
            backgroundRepeat: 'no-repeat',
          },
        }}
        {...rest}
      >
        {children}
      </TableContainer>
    </Box>
  );
};

export default CosTableContainer;
