import { Box } from '@mui/material';
import React, {
  Dispatch,
  KeyboardEventHandler,
  SetStateAction,
  useEffect,
  useState,
} from 'react';
import { StyleRoot } from './ScheduleInput';
import IncrementDecrementBtn from '../formikForms/ui/numberField/IncrementDecrementBtn';
import styled from '@emotion/styled';
import { KEYBOARD_KEY } from '../../libs/constant';
import { moveTabToPrevField } from '../../libs/helper';

const BaseInput = styled('input')({
  justifyContent: 'center',
  outline: 'none !important',
  border: 'none !important',
  color: '#001F38',
  padding: 0,
  boxSizing: 'border-box',
  maxWidth: '19px',
  height: '26px',
  fontWeight: '600',
  fontSize: '12px',
  backgroundColor: 'transparent',
  textAlign: 'center',
});

export type MeridiemType = 'AM' | 'PM' | '';

interface Props {
  defaultValue?: MeridiemType;
  setMeridiem: Dispatch<SetStateAction<MeridiemType>>;
  meridiem: MeridiemType;
  onFocus?: (event: any) => void;
  onChange?: (meridiem: MeridiemType) => void;
}

const MeridiemInput: React.FC<Props> = ({
  defaultValue,
  meridiem,
  setMeridiem,
  onFocus,
  onChange,
}) => {
  useEffect(() => {
    defaultValue && setMeridiem(defaultValue);
  }, [defaultValue]);

  // const handleMeridiemChange = () => {
  //   if (meridiem === 'PM' || meridiem === '') {
  //     setMeridiem('AM');
  //     onChange?.('AM');
  //     setMeridiem('PM');
  //     onChange?.('PM');
  //     } else {
  // }
  // };

  const onDecrement = () => {
    setMeridiem('AM');
    onChange?.('AM');
  };

  const onIncrement = () => {
    setMeridiem('PM');
    onChange?.('PM');
  };

  const handleKeyDown: KeyboardEventHandler<HTMLDivElement> = event => {
    const key = event.key;
    switch (key) {
      case KEYBOARD_KEY.backspace:
        setMeridiem('');
        setTimeout(() => {
          moveTabToPrevField();
        });
        break;
      case KEYBOARD_KEY.up:
        event.preventDefault();
        onIncrement();
        break;

      case KEYBOARD_KEY.down:
        event.preventDefault();
        onDecrement();
        break;

      default:
        break;
    }
  };

  return (
    <StyleRoot className="ml-[13px] flex w-[44px] items-center justify-center gap-[5px]">
      <BaseInput value={meridiem} onKeyDown={handleKeyDown} onFocus={onFocus} />
      <IncrementDecrementBtn
        onIncrement={onIncrement}
        onDecrement={onDecrement}
        isDisableDecrement={meridiem === 'AM'}
        isDisableIncrement={meridiem === 'PM'}
      />
    </StyleRoot>
  );
};

export default MeridiemInput;
