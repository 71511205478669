import React, { useRef, useState } from 'react';
import { Tab, Tabs, alpha, styled, TabScrollButton } from '@mui/material';
import { handleScrollButtonClick, commonTabsSX } from './tab.util';
import useTabScroll from '../../libs/hooks/useTabScroll';

const BpTab = styled(Tabs)(({}) => ({
  maxWidth: 296,
  backgroundColor: '#001F38',
  ...commonTabsSX,

  '& .snapScrollClass': {
    gap: '5px',
    scrollSnapType: 'x mandatory !important',
  },
  '& .mainSnapScrollClass': {
    display: 'flex',
    flexDirection: 'row',
    scrollSnapType: 'x mandatory',
    scrollBehavior: 'smooth',
    scrollSnapAlign: 'start',
  },
  '& .MuiTabs-scrollButtons': {
    color: '#fff',
  },
  '& .MuiTab-root': {
    scrollSnapAlign: 'start',
    height: '21px',
    minWidth: '81px',
    minHeight: '21px',
    backgroundColor: alpha('#A8B1B8', 0.7),
    border: `1px solid ${alpha('#07B9EC', 0.25)}`,
    borderRadius: '0px 0px 5px 5px',
    textAlign: 'center',
    fontSize: '12px',
    lineHeight: '15px',
    color: '#001F38',
    textTransform: 'capitalize',
    padding: 0,

    '&:hover': {
      border: 0,
      backgroundColor: alpha('#07B9EC', 0.48),
      color: alpha('#07B9EC', 0.7),
    },
  },
  '& .MuiTab-root[aria-selected="true"]': {
    border: 0,
    backgroundColor: '#FFFFFF',
    boxShadow: '0px 3px 6px #00000029',
    lineHeight: '19px',
    color: alpha('#07B9EC', 1),
    fontWeight: 600,
  },
}));

const CosHorizontalTab: React.FC<CosTabs> = ({ tabs, ...rest }) => {
  const [value, setValue] = useState(0);

  const handleChange = (event: any, newValue: number) => {
    setValue(newValue);
  };

  const tabsRef = useRef<HTMLDivElement | null>(null);

  useTabScroll(tabsRef);

  return (
    <BpTab
      value={value}
      onChange={handleChange}
      variant="scrollable"
      selectionFollowsFocus={true}
      {...rest}
      ScrollButtonComponent={({ ...rest }) => {
        return (
          <TabScrollButton
            {...rest}
            onClick={() =>
              handleScrollButtonClick(tabsRef.current, rest.direction)
            }
          />
        );
      }}
      ref={tabsRef}
    >
      {tabs.map((item: CosTab, index: number) => (
        <Tab
          label={item.label}
          key={`${index}-${item.label}`}
          className="snap-start"
          // value={item.value}
        />
      ))}
    </BpTab>
  );
};

export default CosHorizontalTab;
