import React from 'react';
import { CosButton, CosTooltip, FloatingAddButton } from '../components';
import {
  Eye,
  EyeHide,
  FilledQuestion,
  FilledStar,
  HideEye,
  HideEyeHover,
  OutlineQuestion,
  OutlineStar,
  Question,
  QuestionDark,
} from '../assets/svgs';
import StandardLayout from './StandardLayout';

const StandardPageTooltip = () => {
  return (
    <StandardLayout>
      <main className="mt-5 space-y-6">
        <div className="space-x-24">
          <CosTooltip
            title={'Hide'}
            placement="top"
            onHoverIcon={<HideEye className="text-dark-blue-900" />}
          >
            <Eye />
          </CosTooltip>

          <CosTooltip
            title={'Show'}
            placement="top"
            onHoverIcon={<HideEyeHover className="text-dark-blue-900" />}
            yOffset={2}
          >
            <EyeHide />
          </CosTooltip>

          <CosTooltip
            title={'%Text Here%'}
            textDecoration="underline"
            placement="right"
            onHoverIcon={<FilledQuestion className="text-dark-blue-900" />}
            yOffset={-12}
          >
            <OutlineQuestion />
          </CosTooltip>

          <CosTooltip
            title={'%Text Here%'}
            textDecoration="underline"
            placement="right"
            tabIndex={-1}
          >
            <CosButton customSize="extraSmall" tabIndex={1}>
              Save As
            </CosButton>
          </CosTooltip>

          <div className="inline-block h-9 w-32 bg-dark-blue-900 p-2">
            <CosTooltip
              title={'%Text Here%'}
              textDecoration="underline"
              placement="right"
              version="light"
              onHoverIcon={<Question />}
              yOffset={-12}
            >
              <QuestionDark />
            </CosTooltip>
          </div>

          <div className="inline-block h-9 w-8 bg-artist-blue-900 p-2 text-white">
            <CosTooltip
              title={'Add to Favorites'}
              placement="top"
              onHoverIcon={<FilledStar />}
            >
              <OutlineStar />
            </CosTooltip>
          </div>
        </div>

        <section>
          <p>Actions</p>
          <div className="space-x-2 space-y-2 bg-white-whale-500 pl-4">
            <CosTooltip
              title={'View'}
              placement="left"
              onHoverIcon={<HideEye className="text-artist-blue-900" />}
            >
              <Eye />
            </CosTooltip>
          </div>

          <div className="space-x-2 bg-artist-blue-900 pl-4">
            <CosTooltip
              title={'View'}
              placement="left"
              onHoverIcon={<HideEye className="text-dark-blue-900" />}
            >
              <Eye className="text-white" />
            </CosTooltip>
          </div>
        </section>

        <section>
          <FloatingAddButton />
        </section>
      </main>
    </StandardLayout>
  );
};

export default StandardPageTooltip;
