import React, { useState } from 'react';
import { TextareaAutosize } from '@mui/base/TextareaAutosize';
import { Box, TextareaAutosizeProps, useTheme } from '@mui/material';
import { constant } from '../../../libs/constant';
import CosLabel, { CosLabelProps } from './CosLabel';
import { cn } from '../../../libs/utils';
import { ExpandDouble } from '../../../assets/svgs';

export interface CosTextAreaProps
  extends Omit<TextareaAutosizeProps, 'minRows' | 'maxRows'> {
  withElevation?: boolean;
  disableFocusElevation?: boolean;
  fullWidth?: boolean;
  label?: string;
  isRequired?: boolean;
  labelProps?: Omit<CosLabelProps, 'children'>;
  tooltipTitle?: string;
  isPopupInput?: boolean; // for popup input
  isExpandIcon?: boolean;
  size?: 'small' | 'medium';
}

const CosTextArea: React.FC<CosTextAreaProps> = ({
  withElevation = true,
  disableFocusElevation = true,
  fullWidth,
  label,
  isRequired = true,
  labelProps,
  tooltipTitle,
  isPopupInput = false,
  isExpandIcon = false,
  size = 'medium',
  ...rest
}) => {
  const theme = useTheme();
  const [isFocused, setIsFocused] = useState(true); // * Prevent focus on mouse click
  const [isShrink, setIsShrink] = useState(false); // * Prevent focus on mouse click

  const isSmallSize = size === 'small';

  const handleExpand = () => {
    setIsShrink(preValue => !preValue);
  };

  return (
    <Box
      sx={{
        width: fullWidth ? '100%' : 'auto',
        display: 'inline-block',
        position: 'relative',
      }}
    >
      {label && (
        <CosLabel
          isRequired={isRequired}
          {...labelProps}
          className={cn('mb-[5px]', labelProps?.className)}
          tooltipTitle={tooltipTitle}
        >
          {label}
        </CosLabel>
      )}
      <Box
        sx={{
          ...(withElevation
            ? {
                '&': {
                  borderRadius: `3px`,
                  boxShadow: '0 0 3px #A8B1B8',
                  '&:focus-within': {
                    boxShadow:
                      isFocused && !disableFocusElevation
                        ? '0px 0px 3px #07B9EC'
                        : '0 0 3px #A8B1B8',
                  },
                },
              }
            : {}),
          boxSizing: 'border-box',
          minWidth: constant.smallSizeInputMinWidth,
          borderRadius: '3px',
          backgroundColor: '#ffffff',
          padding: '8px 2px 8px 10px',
          width: fullWidth ? '100%' : 'auto',
          minHeight: isSmallSize ? 38 : 45,
          display: 'inline-flex',
          alignItems: 'center',
          transition: theme.transitions.create([
            'border-color',
            'background-color',
            'box-shadow',
          ]),

          '& textarea': {
            overflow: 'auto !important',
            fontSize: '14px',
            padding: 0,
            paddingRight: isExpandIcon ? '20px' : '2px',
            minHeight: '19px',
            // lineHeight: '16px',
            maxHeight: isShrink ? '21px' : '52px',
            fontFamily: 'Open Sans',
            color: '#001F38',
            resize: 'none',
            outline: 'none',
            border: 'none',
            width: '100%',
            fontWeight: isPopupInput ? '600' : '400',
            scrollbarGutter: 'stable',
            borderRadius: '3px',
            '&:focus': {
              '&::placeholder': {
                opacity: 0.75,
              },
            },
            '&:hover': {
              '&::placeholder': {
                opacity: 0.75,
              },
            },
            '&::placeholder': {
              color: '#001f38',
              fontWeight: '400',
              opacity: 1,
              transition: 'opacity 0.3s ease',
            },
          },
        }}
      >
        <TextareaAutosize
          // maxRows={2.7369} // * 2.7369 = 68 max height
          onMouseDownCapture={() => setIsFocused(false)}
          onBlurCapture={() => setIsFocused(true)}
          {...rest}
        />
        {isExpandIcon && (
          <div
            className="absolute right-[9px] top-1/2 inline-flex -translate-y-1/2 cursor-pointer"
            onClick={handleExpand}
          >
            <ExpandDouble
              className={'text-dark-blue-900 hover:text-buddha-love-800'}
            />
          </div>
        )}
      </Box>
    </Box>
  );
};

export default CosTextArea;
