import { useRef, useState } from 'react';
import { moveTabToNextField } from '../../../../libs/helper';
import {
  AutocompleteChangeDetails,
  AutocompleteChangeReason,
  AutocompleteValue,
} from '@mui/material';
import { KEYBOARD_KEY } from '../../../../libs/constant';
import { cloneDeep, findIndex } from 'lodash';

interface Props {
  options: any;
  multiple: boolean;
  // onChange?: (
  //   event: React.SyntheticEvent,
  //   value: AutocompleteValue<any, any, any, any>,
  //   reason: AutocompleteChangeReason,
  //   details?: AutocompleteChangeDetails<any>,
  // ) => void;
  onChange: any; //TODO : manage type
  name?: string;
}

// TODO : manage Generic Options or Values Type
const useCosAutoComplete = (props: Props) => {
  const { onChange, options, name, multiple } = props;

  const initialValue = multiple ? [] : null;

  const [isOpen, setIsOpen] = useState(false);
  const [isSelectAll, setIsSelectAll] = useState<boolean>(false);
  const [isInitialState, setIsInitialState] = useState(true);
  const [value, setValue] = useState<any>(initialValue); //* Value type is same as option
  const [cloneValue, setCloneValue] = useState<any>(initialValue);

  const inputRef = useRef<HTMLInputElement>(null);

  const handleOpen = () => setIsOpen(true);

  const handleClose = (event?: any) => {
    const key = event?.key;
    const elem = inputRef.current;

    //* Manage (Esc) keyboard event
    if (key === KEYBOARD_KEY.esc) {
      event.preventDefault();
      // isInitialState ? (setIsOpen(false), elem?.blur()) : handleCancel();
      isInitialState ? setIsOpen(false) : handleCancel();
    } else {
      setIsInitialState(true);
      setIsOpen(false);
      // elem?.blur();
    }
    // setCloneValue([]);
  };

  const handleCancel = () => {
    setIsInitialState(() => true);
    setCloneValue(() => value);
    setIsSelectAll(options.length === value?.length);
  };

  // * multiple Apply button
  const handleApply = () => {
    onChange?.(cloneValue);
    setValue(() => cloneDeep(cloneValue));
    handleClose();
  };

  const handleToggleSelectAll = () => {
    setIsInitialState(() => false);
    setIsSelectAll(prev => {
      if (!prev) setCloneValue([...options]);
      else setCloneValue([]);
      return !prev;
    });
  };

  const handleMultiSelect = (selectedOption: any) => {
    setIsInitialState(() => false);
    const mock = cloneDeep(cloneValue);
    const index = findIndex(mock, selectedOption);
    if (index !== -1) {
      mock.splice(index, 1);
    } else {
      mock.push(selectedOption);
    }
    setCloneValue(() => mock);

    setIsSelectAll(options.length === mock?.length);
  };

  // TODO :  mange type
  // * when type is multiple than we manage our own onClick and onChange so selectedOption not getting when type is multiple
  const handleChange = (
    event: any,
    selectedOption: any,
    reason: any,
    details: any,
  ) => {
    const element: any = event.target;
    name && element?.setAttribute('name', name);
    element?.setAttribute('value', selectedOption?.value);

    if (multiple) {
      // * manage list in it own list
      // handleMultiSelect(selectedOption[0]);
    } else {
      setValue(() => selectedOption);
      onChange && onChange(event, selectedOption);
    }

    // TODO : pass proper value

    //* FOR move focus to next field
    setTimeout(() => {
      if (event?.key === 'Enter') {
        moveTabToNextField();
      }
    }, 0);
  };

  // TODO : manage type
  const handleFilter = (options: any, state: any) => {
    const { inputValue } = state;
    if (inputValue) {
      return options.filter((option: any) =>
        option.label.toLowerCase().startsWith(inputValue.trim().toLowerCase()),
      );
    } else {
      return options;
    }
  };

  return {
    handleChange,
    handleFilter,
    isOpen,
    setIsOpen,
    handleOpen,
    handleClose,
    handleToggleSelectAll,
    value,
    isSelectAll,
    handleApply,
    isInitialState,
    handleCancel,
    inputRef,
    handleMultiSelect,
    cloneValue,
  };
};

export default useCosAutoComplete;
