import { RouteObject } from 'react-router';
import Users from '../pages/users/Users';

const userRouterHandler: RouteObject = {
  path: '/users',
  element: <Users />,
  children: [
    {
      path: '',
      element: <></>,
    },
  ],
};

export default userRouterHandler;
