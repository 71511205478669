import { RouteObject } from 'react-router';
import ComingSoonPageUsers from '../ComingSoonPageUsers';

export const comingSoonUsersHandler: RouteObject = {
  path: '/coming_soon/users',
  element: <ComingSoonPageUsers />,
  children: [
    {
      path: '',
      element: <p>Users</p>,
    },
    {
      path: 'profile',
      element: <p>Users - profile</p>,
    },
    {
      path: 'setting',
      element: <p>Users - setting</p>,
    },
  ],
};
