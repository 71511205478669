import React, {
  createContext,
  useContext,
  useMemo,
  useCallback,
  useState,
} from 'react';
import HoverMenu from 'material-ui-popup-state/HoverMenu';
import MenuItem, { MenuItemProps } from '@mui/material/MenuItem';
import ChevronRight from '@mui/icons-material/ChevronRight';
import {
  usePopupState,
  bindHover,
  bindFocus,
  bindMenu,
  PopupState,
} from 'material-ui-popup-state/hooks';
import NaVigatorTextItem from './NaVigatorTextItem';
import { MenuProps, alpha } from '@mui/material';
import clsx from 'clsx';
import { constant } from '../../../../libs/constant';
import {
  FilledStar,
  OutlineStar,
  StarOutlineIcon,
} from '../../../../assets/svgs';
import { CosTooltip } from '../../../../components';
import { RouteObject } from 'react-router';
import { convertRouterToTitle } from '../../../../libs/utils';
import { NavLink } from 'react-router-dom';

interface CascadingContextType {
  parentPopupState: any;
  rootPopupState: any;
}

export type CascadingHoverListProps = RouteObject;

interface CascadingHoverMenusProps {
  list: CascadingHoverListProps;
  onClick?: () => void;
  onChildItemClick?: (childItem: CascadingHoverListProps) => void;
}

const CascadingContext = createContext<CascadingContextType>({
  parentPopupState: null,
  rootPopupState: null,
});

const CascadingMenuItem: React.FC<MenuItemProps> = ({ onClick, ...props }) => {
  const { rootPopupState } = useContext(CascadingContext);
  if (!rootPopupState) throw new Error('must be used inside a CascadingMenu');
  const handleClick = useCallback(
    (event: React.MouseEvent<HTMLLIElement, MouseEvent>) => {
      rootPopupState.close(event);
      if (onClick) onClick(event);
    },
    [rootPopupState, onClick],
  );

  return <MenuItem {...props} onClick={handleClick} />;
};

const CascadingSubmenu: React.FC<
  Omit<MenuProps & { popupId: string; title: string }, 'open'>
> = ({ title, popupId, ...props }) => {
  const { parentPopupState } = useContext(CascadingContext);
  const popupState = usePopupState({
    popupId,
    variant: 'popover',
    parentPopupState,
  });
  return (
    <>
      <MenuItem {...bindHover(popupState)} {...bindFocus(popupState)}>
        <span>{title}</span>
        <ChevronRight />
      </MenuItem>
      <CascadingMenu
        {...props}
        classes={{ ...props.classes }}
        anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
        transformOrigin={{ vertical: 'top', horizontal: 'left' }}
        popupState={popupState}
      />
    </>
  );
};

const CascadingMenu: React.FC<
  Omit<MenuProps & { popupState: PopupState }, 'open'>
> = ({ popupState, sx, ...props }) => {
  const { rootPopupState } = useContext(CascadingContext);
  const context = useMemo(
    () => ({
      rootPopupState: rootPopupState || popupState,
      parentPopupState: popupState,
    }),
    [rootPopupState, popupState],
  );

  return (
    <CascadingContext.Provider value={context}>
      <HoverMenu
        className="cos-dark-scrollbar"
        sx={{
          //* paper-Wrapper
          '& .MuiPaper-root': {
            boxShadow: `0 0 12px ${alpha('#07B9EC', 0.5)}`,
            borderRadius: 0,
            minWidth: '130px',
            marginLeft: constant.navBarItemBorderWiderSpace + 'px',
            backgroundColor: '#001F38',
          },

          //* list-Wrapper (ul)
          '& .MuiList-root': {
            gap: '0px',
            display: 'flex',
            padding: 0,
            flexDirection: 'column',
            maxHeight: '232px',
            overflow: 'auto',
          },

          // *list (li)
          '& .MuiMenuItem-root': {
            color: alpha('#ffffff', 0.75),
            fontSize: '12px',
            padding: '5.5px 11px 5.5px 10px',
            justifyContent: 'space-between',
            gap: '6px',
            '&:hover': {
              color: '#07B9EC',
              opacity: 1,
            },
          },
          ...sx,
        }}
        {...props}
        {...bindMenu(popupState)}
        // open={true} // ! TODO : uncomment this if for update UI
      />
    </CascadingContext.Provider>
  );
};

const CascadingHoverMenus: React.FC<CascadingHoverMenusProps> = ({
  list,
  onClick,
  onChildItemClick,
}) => {
  const popupState = usePopupState({
    popupId: 'demoMenu',
    variant: 'popover',
  });

  return (
    <>
      <NavLink to={list.path as string} style={{ all: 'unset' }}>
        {({ isActive }) => (
          <NaVigatorTextItem
            {...(list?.children && bindHover(popupState))}
            {...(list?.children && bindFocus(popupState))}
            className={clsx({
              '!text-artist-blue-900': popupState.isOpen,
            })}
            onClick={onClick}
            active={!popupState?.isOpen && isActive}
            sx={{
              cursor: 'pointer',
              pointerEvents:
                isActive && !list?.children?.length ? 'none' : 'auto',
            }}
          >
            {convertRouterToTitle(list.path as string)}
          </NaVigatorTextItem>
        )}
      </NavLink>

      {!!list?.children?.length && (
        <CascadingMenu
          popupState={popupState}
          anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
          transformOrigin={{ vertical: 'top', horizontal: 'left' }}
          sx={{
            '& .MuiList-padding': {
              marginY: '6px',
              marginRight: !!(list?.children?.length > 8) ? '2px' : '0px',
            },
          }}
        >
          {list.children.map((childrenList, index) => {
            return (
              childrenList.path && (
                <NavLink
                  to={`${list.path}/${childrenList.path}`}
                  style={{ all: 'unset' }}
                  key={`${index}-${childrenList.path}-sublist`}
                >
                  {({ isActive }) => (
                    <CascadingMenuItem
                      onClick={() =>
                        onChildItemClick ? onChildItemClick(childrenList) : {}
                      }
                      sx={[
                        !!isActive && {
                          backgroundColor: '#07B9EC !important',
                          color: '#ffffff !important',
                          paddingY: '0 !important',
                          minHeight: '29px !important',
                          maxHeight: '29px !important',
                          pointerEvents:
                            isActive && !childrenList?.children?.length
                              ? 'none'
                              : 'auto',
                        },
                      ]}
                    >
                      <span>
                        {convertRouterToTitle(childrenList.path as string)}
                      </span>
                      {!!isActive && (
                        <CosTooltip
                          title="Add To Favorites"
                          placement="top"
                          onHoverIcon={<FilledStar />}
                        >
                          <OutlineStar />
                        </CosTooltip>
                      )}
                    </CascadingMenuItem>
                  )}
                </NavLink>
              )
            );
          })}

          {/* // ! MANAGE SUB-CHILDREN WHENEVER REQUIRED */}
          {/* <CascadingSubmenu                                                                                                                                                             
            popupId="moreChoicesCascadingMenu"
            title="More Choices"
          >
            <CascadingMenuItem>Cheesecake</CascadingMenuItem>
            <CascadingMenuItem>Cheesedeath</CascadingMenuItem>
            <CascadingSubmenu
              popupId="evenMoreChoicesCascadingMenu"
              title="Even More Choices"
            >
              <CascadingMenuItem>Cake (the band)</CascadingMenuItem>
              <CascadingMenuItem>Death Metal</CascadingMenuItem>
            </CascadingSubmenu>
            <CascadingSubmenu
              popupId="moreBenignChoices"
              title="More Benign Choices"
            >
              <CascadingMenuItem>Salad</CascadingMenuItem>
              <CascadingMenuItem>Lobotomy</CascadingMenuItem>
            </CascadingSubmenu>
          </CascadingSubmenu> */}
        </CascadingMenu>
      )}
    </>
  );
};

export default CascadingHoverMenus;
