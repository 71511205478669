import React, { useEffect, useState } from 'react';
import { CosBasDialog, CosImportComplete, CosImportProgress } from './index';
import clsx from 'clsx';
import { Box, Divider } from '@mui/material';
import { constant } from '../../libs/constant';
import { CosBaseDialogProps } from './CosBasDialog';
import CosButton from '../CosButton';

interface Props extends CosBaseDialogProps {
  onClose?: () => void;
  button?: string[];
  heading?: string;
  contentBody?: React.ReactNode;
  children?: React.ReactNode;
}

const CosImport: React.FC<Props> = ({
  headingClass,
  bodyClass,
  open,
  onClose,
  button,
  heading,
  children,
  ...rest
}) => {
  const [isImport, setImport] = useState(true);
  const [isProgress, setProgress] = useState(false);
  const [isComplete, setComplete] = useState(false);

  const onConfirm = () => {
    setImport(false);
    setProgress(true);
  };

  const goToConfirmation = () => {
    setProgress(false);
    setComplete(true);
  };

  useEffect(() => {
    if (isComplete) {
      const timer = setTimeout(() => {
        if (onClose) {
          onClose();
        }
        setComplete(false);
      }, 1000);

      return () => {
        clearTimeout(timer);
      };
    }
  }, [isComplete, onClose]);

  return (
    <CosBasDialog
      open={open}
      headingClass={clsx(
        'flex justify-center font-semibold text-artist-blue-900 text-18/22 pt-2.5 pb-0',
        headingClass,
      )}
      height="217px"
      width="425px"
      sx={{
        '.MuiPaper-root': {
          borderRadius: constant.messageModalRadius + 'px',
          background: '#EDEEEF',
          boxShadow: '0px 0px 12px #A8B1B8',
        },
      }}
      className="rounded-messageModal"
      bodyClass={
        isImport
          ? clsx(
              'min-w-[350px] max-w-[425px] flex items-center py-0',
              bodyClass,
            )
          : clsx('min-w-[350px] max-w-[425px]', bodyClass)
      }
      actionClass="flex h-[44px] border-0 border-t-[0.5px] border-solid border-artist-blue-900 p-0 bg-white overflow-x-hidden"
      dialogActions={
        isImport && (
          <>
            <CosButton
              size="medium"
              variant="text"
              onClick={onClose}
              className="min-h-auto w-1/2"
            >
              {button ? button[0] : 'Cancel'}
            </CosButton>
            <Divider
              className="opacity-1 m-0 bg-artist-blue-900"
              flexItem
              orientation="vertical"
            ></Divider>
            <CosButton
              size="medium"
              variant="text"
              onClick={onConfirm}
              className="min-h-auto m-0 w-1/2"
            >
              {button ? button[1] : 'Confirm'}
            </CosButton>
          </>
        )
      }
      heading={isImport && heading}
      {...rest}
    >
      {isImport && <Box className="text-center text-13/18">{children}</Box>}
      {isProgress && (
        <CosImportProgress
          afterConfiguring={() => goToConfirmation()}
          open={isProgress}
        ></CosImportProgress>
      )}
      {isComplete && <CosImportComplete open={isComplete}></CosImportComplete>}
    </CosBasDialog>
  );
};

export default CosImport;
