import { useState } from 'react';
import IncrementDecrementBtn from '../components/formikForms/ui/numberField/IncrementDecrementBtn';
import {
  ChevronArrowBtn,
  ExpandArrowBtn,
  NextPrevArrowBtn,
  NextPrevRoundBtn,
} from '../components/arrow';
import StandardLayout from './StandardLayout';

const StandardPageArrow = () => {
  const [isExpandArrowOpen, setIsExpandArrow] = useState(false);
  const [isChevronArrowOpen, setIsChevronArrow] = useState(false);

  /**
   * @description all arrow component have onCLick props
   */
  return (
    <StandardLayout>
      <h4 className="text-center">Open/Close Arrow</h4>
      <div className="space-y-4">
        <div className="space-x-11">
          <div
            className="inline-flex items-center"
            onClick={() => setIsExpandArrow(preValue => !preValue)}
          >
            <span className="m-0 pr-[10px]">Normal</span>
            <ExpandArrowBtn open={isExpandArrowOpen} />
          </div>

          <div className="inline-flex items-center">
            <span className="m-0 pr-[10px]">Disable</span>
            <ExpandArrowBtn disable />
          </div>
        </div>

        <div className="space-x-11">
          <div
            className="inline-flex items-center"
            onClick={() => setIsChevronArrow(preValue => !preValue)}
          >
            <span className="m-0 pr-[10px]">Normal</span>
            <ChevronArrowBtn open={isChevronArrowOpen} />
          </div>

          <div className="inline-flex items-center">
            <span className="m-0 pr-[10px]">Disable</span>
            <ChevronArrowBtn disable />
          </div>
        </div>
      </div>

      <h4 className="text-center">Navigation Arrow</h4>
      <div className="space-y-4">
        <div className="space-x-11">
          <div className="inline-flex items-center">
            <span className="m-0 pr-[10px]">Next</span>
            <NextPrevArrowBtn />
          </div>

          <div className="inline-flex items-center">
            <span className="m-0 pr-[10px]">Disable</span>
            <NextPrevArrowBtn disable />
          </div>
        </div>

        <div className="space-x-11">
          <div className="inline-flex items-center">
            <span className="m-0 pr-[10px]">Prev</span>
            <NextPrevArrowBtn open={true} />
          </div>

          <div className="inline-flex items-center">
            <span className="m-0 pr-[10px]">Disable</span>
            <NextPrevArrowBtn open={true} disable />
          </div>
        </div>
        <div className="flex gap-10 p-2">
          <NextPrevRoundBtn open={true} />
          <NextPrevRoundBtn />
        </div>

        <div className="inline-flex gap-10 bg-dark-blue-900 p-2">
          <NextPrevRoundBtn open={true} version="dark" />
          <NextPrevRoundBtn version="dark" />
        </div>
      </div>

      <h4 className="text-center">Up/Down Arrow</h4>
      <div className="space-y-4">
        <div className="space-x-11">
          <div className="inline-flex items-center">
            <span className="m-0 pr-[10px]">Next</span>
            <IncrementDecrementBtn
              onDecrement={() => {}}
              onIncrement={() => {}}
            />
          </div>

          <div className="inline-flex items-center">
            <span className="m-0 pr-[10px]">
              Disable (when reach to min value)
            </span>
            <IncrementDecrementBtn
              onDecrement={() => {}}
              onIncrement={() => {}}
              isDisableDecrement={true}
            />
          </div>

          <div className="inline-flex items-center">
            <span className="m-0 pr-[10px]">
              Disable (when reach to max value)
            </span>
            <IncrementDecrementBtn
              onDecrement={() => {}}
              onIncrement={() => {}}
              isDisableIncrement={true}
            />
          </div>
        </div>
      </div>
    </StandardLayout>
  );
};

export default StandardPageArrow;
